<template>
  <div class="mt-4">
    <div class="overflow-x-auto">
      <table class="w-full rounded text-center">
        <thead class="bg-gray-200 word-break">
          <tr class="bg-gray-custom">
            <th class="border-solid border border-gray-300 px-5 py-4 text-center"></th>
            <th class="border-solid border border-gray-300 px-5 py-4 text-center align-middle w-5">
              {{ $t('barcode_type.basic_info') }}
            </th>
            <th
              class="border-solid border border-gray-300 px-5 py-4 text-center align-middle"
              :key="name"
              v-for="name in trackPointsHeaderName"
            >
              {{ name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="bg-white">
            <td class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center whitespace-nowrap">
              {{ $t('permissions.writeable') }}
            </td>
            <td class="px-2 py-3 border-solid border border-gray-300 text-center"></td>
            <td
              class="px-2 py-3 border-solid border border-gray-300 text-center"
              :key="trackPointEdit"
              v-for="trackPointEdit in permission?.trackingPermissions"
            >
              <el-checkbox v-model="trackPointEdit.canEdit" />
            </td>
          </tr>
          <tr class="bg-white">
            <td class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center whitespace-nowrap">
              {{ $t('permissions.readable') }}
            </td>
            <td class="px-2 py-3 border-solid border border-gray-300 text-center">
              <el-checkbox v-model="permission.activationDataPermission.canView" />
            </td>
            <td
              class="px-2 py-3 border-solid border border-gray-300 text-center"
              :key="trackPointView"
              v-for="trackPointView in permission?.trackingPermissions"
            >
              <el-checkbox v-model="trackPointView.canView" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mt-4 flex justify-center">
    <el-button class="rounded-full py-3 px-10 bg-blue-500 text-white" @click="onSave">{{ $t('ok') }}</el-button>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop } from 'vue-property-decorator'
import { IPermission, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { UPDATE_USER_GROUP_PERMISSION } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['close:modal'],
  name: 'UserGroupProjectPermissionForm',
})
export default class UserGroupProjectPermissionForm extends Vue {
  @Prop({ type: String }) readonly projectId!: string
  @Prop({ type: Object }) readonly permissionData!: IPermission
  @Prop({ type: Object }) readonly trackPoints!: Record<string, ITrackpoint>

  permission = {} as IPermission

  get trackPointsHeaderName() {
    const result = [] as string[]
    Object.values(this.trackPoints).forEach((item) => {
      if (item.name) {
        result.push(item.name)
      }
    })
    return result
  }

  created() {
    this.permission = cloneDeep(this.permissionData)
  }

  onSave() {
    const projectPermissions = cloneDeep(this.$store.state.userGroup.userGroup.projectPermissions)
    projectPermissions[this.projectId] = this.permission
    this.$store.commit(UPDATE_USER_GROUP_PERMISSION, projectPermissions)
    this.$emit('close:modal', true)
  }
}
</script>
<style scoped>
.word-break {
  word-break: keep-all;
}
</style>
