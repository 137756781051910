
import BaseInput from '@/components/common/BaseInput.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { BaseInput },
  emits: ['update:name', 'update:isShow', 'cancel'],
  name: 'SimpleNameDialog',
})
export default class SimpleNameDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  @PropSync('name', { type: String }) _name!: string

  displayName = ''
  errorMsg = ''

  onCancel() {
    this.$emit('cancel')
  }

  validate() {
    if (this.displayName !== '') return true
    this.errorMsg = this.$t('mandatory_field')
    return false
  }

  clearError() {
    this.errorMsg = ''
  }

  onSave() {
    if (!isEmpty(this.errorMsg)) return
    this._name = this.displayName
    this.displayName = ''
    this._isShow = false
  }

  @Watch('_name')
  onNameModelChanged() {
    this.displayName = this._name
  }
}
