<template>
  <div class="relative border mt-5 p-5 border-solid border-gray-400 rounded-lg">
    <div class="flex">
      <div class="flex-grow font-bold self-center">{{ $t('barcode_type.tracking_remarks_display') }}</div>
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="addTrackingRemarks">
        {{ $t('barcode_type.add_new_route') }}
      </el-button>
    </div>
    <div v-if="remarkRecords.length !== 0">
      <div v-for="(remarks, idx) in remarkRecords" :key="idx" class="mb-4">
        <div class="flex items-center gap-4 py-3">
          <div class="flex items-center flex-grow">
            <div>
              <el-select
                v-model="remarks.from"
                @change="onFromChanged(remarks)"
                :placeholder="$t('barcode_type.select')"
                :key="remarks.from"
              >
                <el-option
                  v-for="tpData in trackingPointDatasArr"
                  :key="`${tpData.value.name} (${tpData?.key})`"
                  :value="tpData.key"
                  :label="`${tpData.value.name} (${tpData?.key})`"
                  :disabled="isDisableFromOption(tpData.key)"
                >
                  {{ tpData.value.name }} {{ `(${tpData.key})` }}
                </el-option>
              </el-select>
            </div>
            <i class="el-icon-right mx-6"></i>
            <div>
              <el-select v-model="remarks.to" :placeholder="$t('barcode_type.select')" :key="remarks.to">
                <el-option
                  v-for="tpData in loadTP(remarks.from)"
                  :key="`${tpData.value.name} (${tpData?.key})`"
                  :value="tpData.key"
                  :label="`${tpData.value.name} (${tpData?.key})`"
                  :disabled="isDisableToOption(remarks.from, tpData.key)"
                >
                  {{ tpData.value.name }} {{ `(${tpData.key})` }}
                </el-option>
              </el-select>
            </div>
          </div>

          <el-button
            type="default"
            class="bg-red-min text-white focus:text-white"
            @click="deleteTrackingRemarks(idx)"
            size="medium"
          >
            {{ $t('barcode_type.delete') }}
          </el-button>
        </div>
        <RichTextEditor
          v-model="remarks.remarks"
          :isShowCustomFields="true"
          :editorHeight="'S'"
          :disabled="false"
          :showToolbar="false"
          :customFields="customFields"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { ITrackPointKeyVal, IRemarkRoute } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
import RichTextEditor from '../RichTextEditor.vue'

@Options({
  emits: ['update:modelValue'],
  components: { RichTextEditor },
  name: 'TrackingRemarksBlock',
})
export default class TrackingRemarksBlock extends Vue {
  @PropSync('modelValue', { type: Object }) syncedModelValue!: IRemarkRoute[]
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Array }) readonly customFields?: []
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @Prop({ type: String }) readonly bcTypeCode!: string

  remarkRecords = [] as IRemarkRoute[]

  safeTemplate(value: string | undefined) {
    return !isEmpty(value) ? (value as string) : ''
  }

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this.remarkRecords.forEach((val, idx) => {
      const endTPRouteAvailable = this.loadTP(val.from)
      const found = endTPRouteAvailable.find((val) => val.key === this.remarkRecords[idx].to)
      if (!found && val.to) this.remarkRecords.splice(idx, 1)
    })
  }

  addTrackingRemarks() {
    this.remarkRecords.push({ from: '', to: '', remarks: this.safeTemplate('') } as IRemarkRoute)
  }

  deleteTrackingRemarks(idx: number) {
    this.remarkRecords.splice(idx, 1)
  }

  isDisableFromOption(fromIdx: string) {
    const keys = Object.keys(this.trackingPointDatasArr.find((tps) => fromIdx === tps.key)?.value.trackPointForms ?? {})
    const unavailToKeys = this.remarkRecords.filter((rm) => rm.from === fromIdx).map((rm) => rm.to)
    return isEmpty(keys) || !keys.find((k) => !unavailToKeys.includes(k))
  }

  isDisableToOption(fromIdx: string, toIdx: string) {
    const unavailToKeys = this.remarkRecords.filter((rm) => rm.from === fromIdx).map((rm) => rm.to)
    return unavailToKeys.includes(toIdx)
  }

  @Watch('syncedModelValue', { deep: true })
  onTPSChanged() {
    if (isEqual(this.syncedModelValue, this.cleanRecords)) return
    this.remarkRecords = cloneDeep(this.syncedModelValue)
  }

  @Watch('remarkRecords', { deep: true })
  onRemarkRecordsChanged() {
    this.syncedModelValue = cloneDeep(this.cleanRecords)
  }

  get cleanRecords(): IRemarkRoute[] {
    return cloneDeep(this.remarkRecords).filter((rc) => !isEmpty(rc.from) && !isEmpty(rc.to))
  }

  @Watch('trackingPointDatasArr')
  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  onFromChanged(remark: IRemarkRoute) {
    const keys = this.loadTP(remark.from).map((tp) => tp.key)
    const unavailToKeys = this.remarkRecords.filter((rm) => rm.from === remark.from).map((rm) => rm.to)
    const remainKeys = keys.filter((k) => !unavailToKeys.includes(k))
    remark.to = !isEmpty(remainKeys) ? remainKeys[0] : ''
  }
}
</script>
