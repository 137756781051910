<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="grid grid-cols-5 my-5 gap-4">
      <div class="font-bold">
        {{ $t('barcode_type.trace_history_display_when_unauthorize') }}
      </div>
      <div class="text-right">{{ $t('barcode_type.basic_info') }}</div>
      <div class="col-span-3">
        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showOrigin">
            {{ $t('barcode_type.origin') }}
          </el-checkbox>
        </div>
        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showDestination">
            {{ $t('barcode_type.destination') }}
          </el-checkbox>
        </div>

        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showTrackingNumber">
            {{ $t('tracking_number') }}
          </el-checkbox>
        </div>
        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showExternalId">
            {{ $t('barcode_type.external_id') }}
          </el-checkbox>
        </div>
        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showDimension">
            {{ $t('barcode_type.dimension') }}
          </el-checkbox>
        </div>
        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showProduct">
            {{ $t('barcode_type.product_info') }}
          </el-checkbox>
        </div>
        <div class="mb-3">
          <el-checkbox v-model="hasActivationCustomFields">
            {{ $t('record_item_setting') }}
          </el-checkbox>
        </div>
        <ShowCustomFieldsBlock
          v-if="hasActivationCustomFields"
          v-model:customFieldVisibilities="_activationDataVisibility.showCustomFields"
        />

        <div class="mb-3">
          <el-checkbox v-model="_activationDataVisibility.showLinkedBarcodes">
            {{ $t('barcode_type.linked_barcodes') }}
          </el-checkbox>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-5 my-5 gap-4">
      <div class="col-span-2 text-right">{{ $t('barcode_type.view_trace_history') }}</div>
      <div class="col-span-3">
        <div class="mb-3" v-for="kv in trackingPointDatasArr" :key="kv.key">
          <div>
            <el-checkbox
              @change="onTrackPointTracingVisibilitiesChange(kv.key)"
              v-model="tpTracingVisibilities[kv.key]"
            >
              {{ kv.value.name }} {{ `(${kv.key})` }}
            </el-checkbox>
          </div>
          <div v-if="tpTracingVisibilities[kv.key]" class="border-l-2 border-gray-500 border-solid pl-2 ml-12">
            <div class="p-1">
              <el-checkbox v-model="_trackPointTracingVisibilities[kv.key].showLocation">
                {{ $t('barcode_type.location') }}
              </el-checkbox>
            </div>
            <div class="p-1">
              <el-checkbox v-model="_trackPointTracingVisibilities[kv.key].showTrackedPersonName">
                {{ $t('barcode_type.name_of_person_who_added_tracking') }}
              </el-checkbox>
            </div>

            <div class="p-1">
              <el-checkbox
                @change="onTPShowCustomFieldsModelsChanged(kv.key, $event)"
                v-model="TPShowCustomFieldsModels[kv.key]"
              >
                {{ $t('record_item_setting') }}
              </el-checkbox>
            </div>
            <ShowCustomFieldsBlock
              v-if="TPShowCustomFieldsModels[kv.key]"
              v-model:customFieldVisibilities="_trackPointTracingVisibilities[kv.key].showCustomFields"
            />

            <div class="p-1">
              <el-checkbox v-model="_trackPointTracingVisibilities[kv.key].showLinkedBarcodes">
                {{ $t('barcode_type.linked_barcodes') }}
              </el-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { IActivationDataVisibility, ITrackPointKeyVal, ITrackPointTracingVisibilities } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, PropSync, Watch } from 'vue-property-decorator'
import ShowCustomFieldsBlock from './ShowCustomFieldsBlock.vue'

@Options({
  components: { ShowCustomFieldsBlock },
  emits: ['update:activationDataVisibility', 'update:trackPointTracingVisibilities'],
  name: 'NotLoginTrackHistoryBlock',
})
export default class NotLoginTrackHistoryBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('activationDataVisibility', { type: Object }) _activationDataVisibility!: IActivationDataVisibility
  @PropSync('trackPointTracingVisibilities', { type: Object }) _trackPointTracingVisibilities!: Record<
    string,
    ITrackPointTracingVisibilities
  >

  hasActivationCustomFields = false
  TPShowCustomFieldsModels = {} as Record<string, boolean>

  get tpTracingVisibilities(): Record<string, boolean> {
    const model = {} as Record<string, boolean>
    const visibleTP = Object.keys(this.trackPointTracingVisibilities)
    Object.keys(this.trackingPointDatasArr).forEach((k) => {
      const tpKey = this.trackingPointDatasArr[Number(k)].key
      model[tpKey] = visibleTP.includes(tpKey)
    })

    return model
  }

  @Watch('_activationDataVisibility', { immediate: true, deep: true })
  onActivationDataVisibilityChanged() {
    if (!this._activationDataVisibility?.showCustomFields) {
      this._activationDataVisibility.showCustomFields = [] as string[]
    }
    this.hasActivationCustomFields = this._activationDataVisibility?.showCustomFields?.length > 0 ?? false
  }

  @Watch('_trackPointTracingVisibilities', { immediate: true, deep: true })
  onTrackPointTracingVisibilitiesChanged() {
    Object.entries(this._trackPointTracingVisibilities).forEach(([k, v]) => {
      if (!v?.showCustomFields) {
        v.showCustomFields = [] as string[]
      }
      this.TPShowCustomFieldsModels[k] = v.showCustomFields.length > 0 ?? false
    })
  }

  onTrackPointTracingVisibilitiesChange(changeKey: string) {
    if (!this._trackPointTracingVisibilities[changeKey]) {
      this._trackPointTracingVisibilities[changeKey] = {
        showLocation: false,
        showTrackedPersonName: false,
        showCustomFields: [] as string[],
        showLinkedBarcodes: false,
      }
    } else delete this._trackPointTracingVisibilities[changeKey]
  }

  onTPShowCustomFieldsModelsChanged(key: string, newVal: boolean) {
    if (newVal) return
    this._trackPointTracingVisibilities[key].showCustomFields = []
  }
}
</script>
