
import { Options, Vue } from 'vue-class-component'
import LeaderLine, { Options as llOptions, SocketType } from 'vue3-leaderline'
import { ITrackPointKeyVal, ITrackPointForms } from 'smartbarcode-web-core/src/utils/types/index'

export interface IDrawLineSetting {
  diagramDirection: 'horizontal' | 'vertical'
  levelHeight: number
  trackpointEntries: ITrackPointKeyVal[]
  nodeIdPrefix: string
  lineIdPrefix: string
  lineColor: string
  lineIdAtMiddleLabel: boolean
  // leaderLineWrapper: HTMLElement
}
export interface ILineGravity {
  top: number
  bottom: number
  auto: number
  left: number
  right: number
}

export interface ILine {
  id: string
  form: ITrackPointForms
}

export interface IDiagramLine {
  [key: string]: string | number[][]
}

export type TDirection = 'topLines' | 'bottomLines' | 'leftLines' | 'rightLines'
export type TLevel = 'topLevel' | 'bottomLevel' | 'leftLevel' | 'rightLevel'

@Options({
  name: 'TrackPointDiagramMixin',
})
export default class TrackPointDiagramMixin extends Vue {
  lines: ILine[] = []
  lvlHeight = 0
  lineIdPrefix = ''
  drawLinesSetting!: IDrawLineSetting
  llStore = [] as LeaderLine[]
  llWrapper!: HTMLElement

  lineGravity = {
    bottom: 0,
    top: 0,
    auto: 0,
    left: 0,
    right: 0,
  } as ILineGravity

  getDefaultLineGravity() {
    return {
      bottom: 0,
      top: 0,
      auto: 0,
      left: 0,
      right: 0,
    }
  }

  drawLines(setting: IDrawLineSetting) {
    this.drawLinesSetting = setting
    const arrTrackPoints = setting.trackpointEntries

    let isPrev1stPos = false

    const isVertical = setting.diagramDirection === 'vertical'
    let lines = isVertical
      ? {
          rightLines: [] as number[][],
          leftLines: [] as number[][],
          rightLevel: 1,
          leftLevel: 1,
        }
      : {
          topLines: [] as number[][],
          bottomLines: [] as number[][],
          topLevel: 1,
          bottomLevel: 1,
        }
    const positions = isVertical ? ['right', 'left'] : ['top', 'bottom']

    // mark down overlap lines
    const roundTripPaths = [] as number[][]
    arrTrackPoints.forEach((startItem) => {
      const startIdx = Number(startItem.key)
      const endIdxs = Object.keys(startItem.value.trackPointForms ?? {})
      endIdxs
        .filter((endIdx) => !roundTripPaths.find(([s, e]) => s === Number(endIdx) && e === startIdx))
        .filter(
          (endIdx) =>
            !!arrTrackPoints.find(
              (endItem) =>
                endItem.key === endIdx && Object.keys(endItem.value.trackPointForms ?? {}).includes(startItem.key)
            )
        )
        .forEach((endIdx) => roundTripPaths.push([Number(startItem.key), Number(endIdx)]))
    })

    arrTrackPoints.forEach((item, index) => {
      const trackingPointForms = item.value.trackPointForms || {}
      const startIdx = Number(item.key)
      const startNode = document.getElementById(`${setting.nodeIdPrefix}${item.key}`)
      if (!startNode) return

      for (const [nextNodeKey, nextTrackPoint] of Object.entries(trackingPointForms)) {
        const endIdx = Number(nextNodeKey)
        const endNode = document.getElementById(`${setting.nodeIdPrefix}${nextNodeKey}`)
        if (!endNode) continue

        const indexNextNode = arrTrackPoints.findIndex((item) => item.key === nextNodeKey)
        const position = this.getConnectPosition(index, indexNextNode, isPrev1stPos, positions) as SocketType
        let lvl = 0
        if (position !== 'auto') {
          isPrev1stPos = position === positions[0]

          const curMin = Math.min(startIdx, endIdx)
          const keyPos = isPrev1stPos ? positions[0] : positions[1]
          const linesKey = `${keyPos}Lines`
          const levelKey = `${keyPos}Level`

          const linesArr = lines[linesKey as TDirection] as number[][]
          lines = {
            ...lines,
            [levelKey]:
              (lines[levelKey as TLevel] as number) + (linesArr.find((n) => curMin <= Math.max(n[0], n[1])) ? 1 : 0),
          }
          lvl = lines[levelKey as TLevel] as number
          linesArr.push([startIdx, endIdx])
        }

        let startPoint, endPoint
        if (isVertical && position === 'auto') {
          const isGo = roundTripPaths.find(([s, e]) => s === startIdx && e === endIdx)
          const isReturn = roundTripPaths.find(([s, e]) => e === startIdx && s === endIdx)
          if (isGo) {
            startPoint = LeaderLine.pointAnchor(startNode, { x: '40%', y: '100%' })
            endPoint = LeaderLine.pointAnchor(endNode, { x: '40%', y: '0%' })
          } else if (isReturn) {
            startPoint = LeaderLine.pointAnchor(startNode, { x: '60%', y: '0%' })
            endPoint = LeaderLine.pointAnchor(endNode, { x: '60%', y: '100%' })
          }
        }

        const socketGravity = setting.levelHeight * lvl
        const lineIdx = `${item.key}-${nextNodeKey}`
        this.llStore.push(
          new LeaderLine(startPoint ?? startNode, endPoint ?? endNode, {
            lineId: `${setting.lineIdPrefix}${lineIdx}`,
            size: 3,
            path: position !== 'auto' ? 'grid' : 'straight',
            color: setting.lineColor,
            startSocket: position,
            endSocket: position,
            startSocketGravity: socketGravity,
            endSocketGravity: socketGravity,
            ...(setting.lineIdAtMiddleLabel && { middleLabel: lineIdx }),
          } as llOptions)
        )

        this.lines.push({
          id: lineIdx,
          form: nextTrackPoint,
        })
      }
    })

    return new Promise((resolve) => setTimeout(() => resolve(1)))
  }

  getConnectPosition(current: number, next: number, isPrev1stPos: boolean, positions: string[]) {
    const nextHeight = this.drawLinesSetting.levelHeight
    const currents = [current + 1, current - 1]
    let position = 'auto'
    if (!currents.includes(next)) {
      position = isPrev1stPos ? positions[1] : positions[0]
      this.lineGravity[position as keyof ILineGravity] += nextHeight
    }
    return position
  }

  calculateLeftRightRange(current: number, next: number, lineGravityData: ILineGravity, isPrev1stPos: boolean) {
    const nextHeight = 20

    const currents = [current + 1, current - 1]
    let position = 'auto'
    if (!currents.includes(next)) {
      if (isPrev1stPos) {
        position = 'left'
        lineGravityData.right += nextHeight
      } else {
        position = 'right'
        lineGravityData.left += nextHeight
      }
    }

    return {
      position,
      lineGravityData,
    }
  }
}
