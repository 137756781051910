
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import {
  COMPARE_METHOD_VALUE,
  COMPILE_LOGICAL_COMPARE_METHOD_VALUE,
  COMPILE_LOGICAL_CONDITION_METHOD_VALUE,
  CUSTOM_VALIDATION_COMPARISON_DEFAULT,
  CUSTOM_VALIDATION_CONDITION_DEFAULT,
  LOGICAL_OPERATOR_VALUE,
  VALIDATION_EVENT_TYPE_VALUE,
} from '@/utils/constants'
import {
  getCustomFieldOptions,
  getStaticOptions,
  getTrackingCustomFieldOptions,
  reFormatCustomFieldOptions,
} from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldCustomValidation,
  ICustomFieldFormData,
  ICustomValidator,
  ICustomValidatorCondition,
  ICustomValidatorForm,
  IFieldOption,
  ITrackpoint,
  ITrackPointForms,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, Watch } from 'vue-property-decorator'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'

@Options({
  emits: ['dialog:close', 'update:customValidator'],
  components: { SelectAutocomplete },
  name: 'CustomValidatorDialog',
})
export default class CustomValidatorDialog extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: Object, default: {} }) readonly customValidator!: ICustomValidator
  @Prop({ type: Boolean, default: {} }) isAddAction?: boolean
  @Prop({ type: String }) readonly currentBCTypeCode!: string

  excludedFieldType = [FIELD_TYPE.ESIGN, FIELD_TYPE.LOCATION, FIELD_TYPE.FILES]
  customValidatorData = {} as ICustomValidatorForm
  isShowDialog = true
  isSaveDisabled = false
  rules = {
    eventType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    conditionLogicalOperator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    conditions: [
      {
        comparisons: [
          {
            from: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
            to: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
            operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
          },
        ],
      },
    ],
    message: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  } as {
    eventType: Array<unknown>
    trackPointRoute?: {
      from: Array<unknown>
      to: Array<unknown>
    }
    conditions: [
      {
        comparisons: [
          {
            from: Array<unknown>
            to: Array<unknown>
            operator: Array<unknown>
          }
        ]
      }
    ]
    conditionLogicalOperator: Array<unknown>
    message: Array<unknown>
  }

  customFieldFromOptions = [] as IFieldOption[]
  customFieldToOptions = [] as IFieldOption[]

  get trackingPointForm() {
    return this.trackingPointDatasArr.filter((item) => !isEmpty(item.value.trackPointForms))
  }

  get trackingPointTo() {
    const key = this.customValidatorData.trackPointRoute?.from
    const nextTrackpoints = objectToArray<ITrackPointForms>(
      this.trackingPointDatasArr.find((tp) => tp.key === key)?.value.trackPointForms || {}
    )

    const nextTrackPointsMap = nextTrackpoints.map((val) => {
      const trackpointName = this.trackingPointDatasArr.find((tp) => tp.key === val.key)?.value.name
      return {
        key: val.key,
        value: {
          ...val.value,
          name: trackpointName,
        },
      }
    })
    return nextTrackPointsMap
  }

  get restrictionType() {
    return { parent: 0, child: 1 }
  }

  get customEventType() {
    return VALIDATION_EVENT_TYPE_VALUE
  }

  get customValidationLogicalMethod() {
    return COMPILE_LOGICAL_COMPARE_METHOD_VALUE
  }

  get customValidatorConditionalLogicalMethod() {
    return COMPILE_LOGICAL_CONDITION_METHOD_VALUE
  }

  get customValidationCompareMethod() {
    return COMPARE_METHOD_VALUE
  }

  get isEventTypeTracked() {
    return this.customValidatorData.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked
  }

  getCustomFieldFromOptions(): IFieldOption[] {
    const options = getStaticOptions()
    const customFieldOptions = getCustomFieldOptions({
      fields: this.customFields || [],
      excludeFieldType: this.excludedFieldType,
    })
    options.push(...customFieldOptions)
    if (this.isEventTypeTracked) {
      const trackPointCustomFieldOptions = this.getCustomFieldTrackingPointRelationship()
      options.push(...trackPointCustomFieldOptions)
    }
    return reFormatCustomFieldOptions(options)
  }

  getCustomFieldToOptions(): IFieldOption[] {
    const options = getStaticOptions()
    const customFieldOptions = getCustomFieldOptions({
      fields: this.customFields || [],
      excludeFieldType: this.excludedFieldType,
    })
    options.push(...customFieldOptions)
    if (this.isEventTypeTracked) {
      const trackPointCustomFieldOptions = this.getCustomFieldTrackingPointRelationship()
      options.push(...trackPointCustomFieldOptions)
    } else {
      for (const key in this.barcodeTypes) {
        if (key !== this.currentBCTypeCode) {
          const item = this.barcodeTypes[key]
          const customFields = Object.entries(item.activationFields.customFields).map(([k, v]) => ({
            ...v,
            customFieldKey: k,
          }))
          const subCustomFieldOptions = getCustomFieldOptions({
            fields: customFields,
            excludeFieldType: this.excludedFieldType,
            barcodeName: item.name,
          })
          options.push(...subCustomFieldOptions)
        }
      }
    }
    return reFormatCustomFieldOptions(options)
  }

  get hintComparisonFrom() {
    let hintText = ''
    switch (this.customValidatorData.eventType) {
      case VALIDATION_EVENT_TYPE_VALUE.tracked:
        hintText = this.$t('barcode_type.currentBarcode')
        break
      case VALIDATION_EVENT_TYPE_VALUE.paired:
        hintText = this.$t('barcode_type.parentBarcode')
        break
      case VALIDATION_EVENT_TYPE_VALUE.linked:
        hintText = this.$t('barcode_type.barcodeLinkOrigin')
        break
    }
    return hintText
  }

  get hintComparisonTo() {
    let hintText = ''
    switch (this.customValidatorData.eventType) {
      case VALIDATION_EVENT_TYPE_VALUE.tracked:
        hintText = this.$t('barcode_type.currentBarcode')
        break
      case VALIDATION_EVENT_TYPE_VALUE.paired:
        hintText = this.$t('barcode_type.allChildrenBarcodeTarget')
        break
      case VALIDATION_EVENT_TYPE_VALUE.linked:
        hintText = this.$t('barcode_type.allBarcodeLinkTarget')
        break
    }
    return hintText
  }

  getCustomFieldTrackingPointRelationship(): IFieldOption[] {
    let customFields = [] as ICustomFieldCustomValidation[]
    const trackPoints = this.$store.state.project.projectDetail.trackPoints as Record<string, ITrackpoint>
    for (const from in trackPoints) {
      const fromName = trackPoints[from].name || ''
      for (const to in trackPoints[from]?.trackPointForms) {
        const toName = trackPoints[to].name || ''
        const trackPointForm = trackPoints?.[from].trackPointForms?.[to] as ITrackPointForms

        const data = Object.entries(trackPointForm.customFields ?? {}).map(([key, field]) => ({
          ...field,
          fieldTypeKey: field.fieldType,
          customFieldKey: key,
          fromName,
          toName,
        })) as ICustomFieldCustomValidation[]
        customFields = customFields.concat(data)
      }
    }
    return getTrackingCustomFieldOptions(customFields, this.excludedFieldType)
  }

  created() {
    const customValidatorData = cloneDeep(this.customValidator) as ICustomValidatorForm
    customValidatorData.conditions.forEach((val) => {
      val.compileLogicalMethod = `${val.comparisonLogicalOperator.toLocaleLowerCase()}:${
        val.comparisons?.[0]?.operator
      }`
      val.compareMethod = val.comparisons?.[0]?.operator
    })
    this.customValidatorData = customValidatorData
    this.changeEventType()
  }

  removeComparision(condition: ICustomValidatorCondition, comparasionIndex: number, conditionIndex: number) {
    if (condition.comparisons.length > 1) {
      condition.comparisons.splice(comparasionIndex, 1)
      if (this.rules?.conditions?.[conditionIndex]?.comparisons) {
        this.rules.conditions[conditionIndex].comparisons.splice(comparasionIndex, 1)
      }
    }
  }

  addComparision(condition: ICustomValidatorCondition, conditionIndex: number) {
    const newComparison = cloneDeep(CUSTOM_VALIDATION_COMPARISON_DEFAULT)
    newComparison.operator = condition.compareMethod || COMPARE_METHOD_VALUE.equal
    condition.comparisons.push(newComparison)

    if (this.rules?.conditions?.[conditionIndex]?.comparisons) {
      this.rules.conditions[conditionIndex].comparisons.push({
        from: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
        operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      })
    }
  }

  addCondition() {
    this.customValidatorData.conditions.push(cloneDeep(CUSTOM_VALIDATION_CONDITION_DEFAULT))
    this.rules.conditions.push({
      comparisons: [
        {
          from: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
          to: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
          operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        },
      ],
    })
  }

  removeCondition(index: number) {
    if (this.customValidatorData.conditions.length > 1) {
      this.customValidatorData.conditions.splice(index, 1)
    }
    if (this.rules?.conditions) {
      this.rules.conditions.splice(index, 1)
    }
  }

  showOperator(operator: string) {
    return operator === COMPARE_METHOD_VALUE.equal ? '=' : '<>'
  }

  showLogicalOperator(operator: string) {
    return operator === LOGICAL_OPERATOR_VALUE.and ? 'and' : 'or'
  }

  changeRouteFrom() {
    if (this.customValidatorData.trackPointRoute && this.customValidatorData.trackPointRoute.to) {
      this.customValidatorData.trackPointRoute.to = ''
    }
  }

  onCompileLogicalMethodChange(condition: ICustomValidatorCondition) {
    const arrLogicalMethod = condition.compileLogicalMethod?.split(':')
    if (arrLogicalMethod?.[0]) {
      condition.comparisonLogicalOperator = arrLogicalMethod[0]
    }

    if (arrLogicalMethod?.[1]) {
      condition.comparisons.forEach((item) => (item.operator = arrLogicalMethod[1]))
      condition.compareMethod = arrLogicalMethod?.[1]
    }
  }

  onCompareMethodChange(condition: ICustomValidatorCondition) {
    if (condition.compareMethod) {
      const compareMethod = condition.compareMethod || COMPARE_METHOD_VALUE.equal
      condition.comparisons.map((item) => {
        item.operator = compareMethod
      })
      const arrCompileMethod = condition.compileLogicalMethod?.split(':')
      condition.compileLogicalMethod = `${arrCompileMethod?.[0] || LOGICAL_OPERATOR_VALUE.and}:${compareMethod}`
    }
  }

  @Watch('customValidatorData.eventType')
  changeEventType() {
    if (this.customValidatorData.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked) {
      this.rules.trackPointRoute = {
        from: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      }
    } else {
      delete this.rules.trackPointRoute
      this.customValidatorData.trackPointRoute = { from: '', to: '' }
    }

    this.customFieldFromOptions = this.getCustomFieldFromOptions()
    this.customFieldToOptions = this.getCustomFieldToOptions()
  }

  closeDialog() {
    this.$emit('dialog:close')
  }

  onSave() {
    this.isSaveDisabled = true
    this.$refs.customValidationForm.validate(async (valid: string) => {
      if (valid) {
        this.loading = true
        this.$emit('update:customValidator', cloneDeep(this.formatCustomValidator(this.customValidatorData)))
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  formatCustomValidator(data: ICustomValidatorForm): ICustomValidator {
    if (data.eventType !== VALIDATION_EVENT_TYPE_VALUE.tracked) {
      delete data.trackPointRoute
    }
    data.conditions.forEach((data) => {
      delete data.compileLogicalMethod
      delete data.compareMethod
    })

    return data
  }
}
