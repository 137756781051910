<template>
  <div class="grid grid-cols-5 gap-4">
    <div :class="['col-span-2', isLabelRTL ? ['right-to-left'] : 'font-bold']">
      {{ label }}
    </div>
    <div class="col-span-3">
      <el-checkbox-group v-model="modelValue_">
        <div class="mb-2" v-for="item in nodesShown" :key="item.key">
          <el-checkbox :label="item.key"> {{ item.value.name }} {{ ` (${item.key})` }} </el-checkbox>
        </div>
      </el-checkbox-group>
    </div>
  </div>
</template>
<script lang="ts">
import { ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'TrackPointCheckboxesBlock',
})
export default class TrackPointCheckboxesBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('modelValue', { type: Array, default: [] }) modelValue_?: string[]
  @Prop({ type: String }) readonly label?: string
  @Prop({ default: false, type: Boolean }) readonly isShowAll?: boolean
  @Prop({ default: false, type: Boolean }) readonly isLabelRTL?: boolean

  get nodesShown() {
    return this.isShowAll ? this.trackingPointDatasArr : this.trackingPointDataShown
  }
}
</script>
<style lang="scss" scoped>
.right-to-left {
  direction: rtl;
}
</style>
