<template>
  <SvgBase iconName="IconPair" :width="16" :height="16">
    <path
      d="M1.94971 10.7947C2.58424 12.0776 3.61335 13.1233 4.88596 13.7782C6.14944 14.4269 7.58999 14.646 8.98921 14.4022C10.3927 14.1554 11.6786 13.4608 12.6545 12.4222C13.6393 11.3738 14.2664 10.0402 14.4455 8.61296C14.6273 7.18251 14.3533 5.73115 13.6625 4.46546C12.9781 3.2096 11.9086 2.20663 10.6115 1.60421C9.32273 1.00733 7.87444 0.84723 6.48646 1.14821C5.09896 1.44896 3.96121 2.13296 3.02146 3.21446C2.90746 3.33446 2.46946 3.81896 2.10646 4.55021"
      stroke="#2D9CDB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.44825 4.60963L1.66825 5.28988L1 1.44238"
      stroke="#2D9CDB"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '@/components/svg/SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconPair extends Vue {}
</script>
