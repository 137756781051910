
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import { EOCRReaderType, EReaderType, EValidationType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isImageFileField } from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IActivationFields, IFieldOption, ITrackingNumber } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

export const CHARACTER_TYPES_OPTIONS: Record<string, string> = Object.freeze({
  letterDigitOnly: 'letterDigit',
  digitOnly: 'digit',
  letterOnly: 'letter',
  notCheck: '--',
})

export const ADDITIONAL_SYMBOLS_OPTIONS: Record<string, string> = Object.freeze({
  dash: 'Dash',
  underscore: 'Underscore',
  none: '--',
})

@Options({
  components: { InputOnlyNumber },
  emits: ['update:modelValue'],
  name: 'TrackingNumberBlock',
})
export default class TrackingNumberBlock extends Vue {
  @PropSync('modelValue', { type: Object }) modelValue_!: ITrackingNumber
  @Prop({ type: Object, default: {} }) activationFields!: IActivationFields
  @Prop({ type: Boolean, default: false }) isDisabled!: string
  allowUnmatchedTracking = false

  characterType = ''
  separatorType = ''

  ocrReaderType = false
  barcodeReaderType = false

  get imageFileFields(): IFieldOption[] {
    return Object.entries(this.activationFields?.customFields ?? {})
      .filter((item) => isImageFileField(item[1]))
      .map(([k, val]) => ({ value: k, label: val.label }))
  }

  get hasImageFileSetting() {
    return !isEmpty(this.imageFileFields)
  }

  @Watch('activationFields', { deep: true })
  onActivationFieldsChange() {
    this.loadingType()
    if (isEmpty(this.imageFileFields)) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get isDisableAutoSaveOCR() {
    if (!this.ocrReaderType) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
    return !this.ocrReaderType
  }

  onOCRReaderChange(val: boolean) {
    if (!val) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get trackingNumberData(): ITrackingNumber {
    return this.value
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get characterTypes() {
    return CHARACTER_TYPES_OPTIONS
  }

  get separatorTypes() {
    return ADDITIONAL_SYMBOLS_OPTIONS
  }

  @Watch('modelValue', { immediate: true })
  loadingType() {
    const validationType: string = this.modelValue_?.validationType ?? ''

    this.characterType = this.characterTypes[
      Object.keys(this.characterTypes).find((k) => validationType.includes(this.characterTypes[k])) ?? 'notCheck'
    ]

    this.separatorType = this.separatorTypes[
      Object.keys(this.separatorTypes).find((k) => validationType.includes(this.separatorTypes[k])) ?? 'none'
    ]

    this.ocrReaderType = this.modelValue_?.ocrReaderType !== EOCRReaderType.UNKNOWN
    this.barcodeReaderType = this.modelValue_?.barcodeReaderType !== EReaderType.UNKNOWN
  }

  onChangeValidateType() {
    if (this.characterType === CHARACTER_TYPES_OPTIONS.notCheck) this.separatorType = ADDITIONAL_SYMBOLS_OPTIONS.none

    const temp = `${this.characterType}${this.separatorType}`.replaceAll('--', '')
    this.modelValue_ = {
      ...this.modelValue_,
      validationType: `${temp}${isEmpty(temp) ? 'none' : 'Only'}` as EValidationType,
    }
  }

  @Watch('ocrReaderType')
  updateOcrReader() {
    this.modelValue_.ocrReaderType = this.ocrReaderType ? EOCRReaderType.DEFAULT : EOCRReaderType.UNKNOWN
  }

  @Watch('barcodeReaderType')
  updateBarcodeType() {
    this.modelValue_.barcodeReaderType = this.barcodeReaderType ? EReaderType.DEFAULT : EReaderType.UNKNOWN
  }
}
