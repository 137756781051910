<template>
  <SvgBase iconName="IconBarcodeIsParentChildren" :height="10" :width="10">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.833333 1.25C0.833333 1.13949 0.877232 1.03351 0.955372 0.955372C1.03351 0.877232 1.13949 0.833333 1.25 0.833333H7.91667C8.02717 0.833333 8.13315 0.877232 8.2113 0.955372C8.28944 1.03351 8.33333 1.13949 8.33333 1.25V8.75C8.33333 8.86051 8.28944 8.96649 8.2113 9.04463C8.13315 9.12277 8.02717 9.16667 7.91667 9.16667H1.25C1.13949 9.16667 1.03351 9.12277 0.955372 9.04463C0.877232 8.96649 0.833333 8.86051 0.833333 8.75V7.08333C0.833333 6.97283 0.789435 6.86685 0.711294 6.7887C0.633154 6.71057 0.527174 6.66667 0.416667 6.66667C0.30616 6.66667 0.200179 6.71057 0.122039 6.7887C0.0438988 6.86685 0 6.97283 0 7.08333V8.75C0 9.08152 0.131696 9.39946 0.366116 9.63388C0.600537 9.8683 0.918479 10 1.25 10H7.91667C8.24819 10 8.56613 9.8683 8.80055 9.63388C9.03497 9.39946 9.16667 9.08152 9.16667 8.75V1.25C9.16667 0.918479 9.03497 0.600537 8.80055 0.366117C8.56613 0.131696 8.24819 0 7.91667 0H1.25C0.918479 0 0.600537 0.131696 0.366116 0.366117C0.131696 0.600537 0 0.918479 0 1.25V2.91667C0 3.02717 0.0438988 3.13315 0.122039 3.21129C0.200179 3.28943 0.30616 3.33333 0.416667 3.33333C0.527174 3.33333 0.633154 3.28943 0.711294 3.21129C0.789435 3.13315 0.833333 3.02717 0.833333 2.91667V1.25Z"
      fill="black"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLocation extends Vue {}
</script>
