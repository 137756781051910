<template>
  <SvgBase iconName="IconBarcodeIsParentChildren" :height="23" :width="23">
    <path
      d="M14 19C20 18 22 13 22 9M15 15L13 19L17 22M1 8L5 5M5 5L8 9M5 5C3 11 4 16 10 19M21 6C17 0 11 0 7 3.006M21 6V1M21 6H16"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconRecycle extends Vue {}
</script>
