
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import IconVertialThreeDotCircle from '@/components/svg/IconVertialThreeDotCircle.vue'
import { IProjectLocation, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import isEmpty from 'lodash/isEmpty'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound, IconVertialThreeDotCircle },
  directives: { maska },
  emits: ['onEditSettingClicked', 'update:tableLocation', 'onEditLocation', 'onRemoveLocation'],
  name: 'LocationTable',
})
export default class LocationTable extends Vue {
  @PropSync('locations', { type: Object }) _locations?: IProjectLocation[]
  @Prop({ type: String }) readonly projectId!: string
  @Prop({ type: Object }) readonly trackpoints!: Record<string, ITrackpoint>

  get filteredTrackpoints(): Record<string, ITrackpoint> {
    return Object.keys(this.trackpoints)
      .filter((key) => !this.trackpoints[key].isEnd)
      .reduce(
        (acc, tpKey) => ({
          ...acc,
          [tpKey]: this.trackpoints[tpKey],
        }),
        {}
      )
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get isLocationDataAvailable() {
    return !isEmpty(this.locations) && !isEmpty(this.filteredTrackpoints)
  }

  created() {
    this.initData()
  }

  @Watch('_locations')
  initData() {
    const TPKeys = Object.keys(this.filteredTrackpoints)
    ;(this._locations ?? []).forEach((i) => {
      Object.keys(i.trackingPermissions).forEach((k) => {
        if (!TPKeys.includes(k)) {
          delete i.trackingPermissions[k]
        }
      })
    })
  }

  onUpdated() {
    this.$emit('update:tableLocation', this._locations)
  }
}
