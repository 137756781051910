
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { DEFAULT_BARCODE_TYPES } from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IExternalWebAPIIntegration } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import RichTextEditor from '../RichTextEditor.vue'
@Options({
  emits: ['update:modelValue'],
  components: { RichTextEditor },
  name: 'ExternalWebApiBlock',
})
export default class ExternalWebApiBlock extends mixins(ValidateForm) {
  @Prop({ type: Array }) readonly customFields?: []
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IExternalWebAPIIntegration

  isJSonValid = true

  rules = {
    endpointUrl: [{ required: this.isRequiredEndPointURL, validator: this.requiredURLValidate, trigger: 'blur' }],
    buttonLabel: [{ required: this.isRequiredEndPointURL, validator: this.requiredValidate, trigger: 'blur' }],
  }

  get syncedModelValue() {
    if (!this._syncedModelValue) this._syncedModelValue = this.getDefaultExternalWebAPIIntegration()
    return this._syncedModelValue
  }

  set syncedModelValue(val: IExternalWebAPIIntegration) {
    this._syncedModelValue = this._syncedModelValue ? val : this.getDefaultExternalWebAPIIntegration()
  }

  getDefaultExternalWebAPIIntegration() {
    return cloneDeep(DEFAULT_BARCODE_TYPES.default.externalWebAPIIntegration) as IExternalWebAPIIntegration
  }

  get isRequiredEndPointURL() {
    return this.isEnableExternalWebAPI || false
  }

  get endPointURL() {
    return this.syncedModelValue?.endpointUrl || ''
  }

  set endPointURL(val: string) {
    if (this.syncedModelValue) {
      this.syncedModelValue.endpointUrl = val
    } else {
      Object.assign(this.syncedModelValue, { endpointUrl: val })
    }
  }

  get buttonLabel() {
    return this.syncedModelValue?.buttonLabel || ''
  }

  set buttonLabel(val: string) {
    if (this.syncedModelValue) {
      this.syncedModelValue.buttonLabel = val
    } else {
      Object.assign(this.syncedModelValue, { buttonLabel: val })
    }
  }

  isEnableExternalWebAPI = false

  checkValueObjectEmpty(obj: IExternalWebAPIIntegration) {
    return isEmpty(obj) && Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  @Watch('syncedModelValue')
  onChanged() {
    this.isEnableExternalWebAPI = !this.checkValueObjectEmpty(this.syncedModelValue)
  }

  onToggleExternalWebAPI(val: boolean) {
    if (val) return

    // form reset
    if (!this.$refs.ruleFormRef) return
    this.$refs.ruleFormRef.resetFields()

    this.syncedModelValue = this.getDefaultExternalWebAPIIntegration()
  }

  onJsonValidate(isJSonValid: boolean) {
    this.isJSonValid = isJSonValid
  }

  get value() {
    if (!this.syncedModelValue) return ''
    return JSON.stringify(this.syncedModelValue.requestBody, null, '\t')
  }

  set value(val: string) {
    try {
      if (!this.syncedModelValue) return
      this.syncedModelValue.requestBody = JSON.parse(val)
    } catch (error) {}
  }
}
