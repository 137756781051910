
import { fieldRestriction2String, restrictionRule, isImageFileField } from '@/utils/helpers'
import { Vue, Options } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import {
  IActivationCustomField,
  IActivationFields,
  IFieldRestriction,
  IRecycleFields,
  IFieldOption,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import Dialog from '@/components/customFields/Dialog.vue'
import { PROJECT_CUSTOM_FIELDS_DEFAULT, ACTIVATION_CUSTOM_FIELD_EXTRA_DATA, POPULAR_COUNTRIES } from '@/utils/constants'
import Draggable from 'vuedraggable'
import IconDraggable from '@/components/svg/IconDraggable.vue'
import { ECustomFieldExtraType, ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { FETCH_COUNTRIES, SET_PAGE_NOT_FOUND } from '@/store/actions'

@Options({
  components: { Dialog, Draggable, IconDraggable },
  emits: ['update:customFields', 'update:activationFields', 'update:countries'],
  name: 'CustomFieldBlock',
})
export default class CustomFieldBlock extends Vue {
  @PropSync('activationFields', { type: Object }) fields!: IActivationFields
  @PropSync('countries', { type: Array, default: [] }) _countries!: string[]

  editingCustomFieldData = {} as IActivationCustomField
  customFieldAdded = [] as Array<string>
  customFieldSettings = [] as IActivationCustomField[]
  countriesList = {}
  isDraggable = false
  isShowCustomFieldDialog = false
  isCustomFieldError = false
  drag = false

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 100,
      disabled: !this.isDraggable || this.isReadOnlyMode,
      ghostClass: 'ghost',
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get imageFileFields(): IFieldOption[] {
    return this.customFieldSettings
      .filter((f) => isImageFileField(f) && !!f.customFieldKey)
      .map((field) => ({ value: field.customFieldKey ?? '', label: field.label }))
  }

  displayCustomFieldType(field: IActivationCustomField): string {
    return field.fieldType === ECustomFieldType.TEXT && field.multiLine
      ? ECustomFieldExtraType.TEXT_AREA
      : field.fieldType
  }

  @Watch('fields.customFields', { deep: true })
  formatCustomFieldSettings() {
    const customFieldSettings = Object.entries(this.fields.customFields ?? {}).map(([customFieldKey, v]) => ({
      ...v,
      customFieldKey,
    }))

    customFieldSettings.sort((a, b) => (a.order && b.order && a.order > b.order ? 1 : -1))

    this.$emit('update:customFields', customFieldSettings)
    this.customFieldSettings = customFieldSettings
  }

  get activationSteps() {
    return {
      origin: this.$t('barcode_type.shipment_source'),
      destination: this.$t('barcode_type.shipping_address'),
      trackingNumber: this.$t('tracking_number'),
      externalId: this.$t('barcode_type.external_id'),
      dimension: this.$t('barcode.dimension'),
      product: this.$t('barcode.product'),
    }
  }

  get stepStatus() {
    return {
      mandatory: this.$t('barcode_type.mandatory'),
      available: this.$t('barcode_type.available'),
      unavailable: this.$t('barcode_type.unavailable'),
    }
  }

  get stepStatusWithRecycle() {
    return {
      ...this.stepStatus,
      copyOnRecycle: this.$t('projects.reuse'),
    }
  }

  get stepSelected() {
    const activationFields = this.fields
    return {
      origin: {
        restriction: fieldRestriction2String(activationFields.origin),
        copyOnRecycle: activationFields.origin?.copyOnRecycle ?? false,
      },
      destination: {
        restriction: fieldRestriction2String(activationFields.destination),
        copyOnRecycle: activationFields.destination?.copyOnRecycle ?? false,
      },
      trackingNumber: {
        restriction: fieldRestriction2String(activationFields.trackingNumber),
        copyOnRecycle: activationFields.trackingNumber?.copyOnRecycle ?? false,
      },
      externalId: {
        restriction: fieldRestriction2String(activationFields.externalId),
        copyOnRecycle: activationFields.externalId?.copyOnRecycle ?? false,
      },
      dimension: {
        restriction: fieldRestriction2String(activationFields.dimension),
        copyOnRecycle: activationFields.dimension?.copyOnRecycle ?? false,
      },
      product: {
        restriction: 'none',
        copyOnRecycle: activationFields.product?.copyOnRecycle ?? false,
      },
    }
  }

  onCustomFieldCodeChange(newCode: string) {
    const editingCode = this.editingCustomFieldData.customFieldKey
    // skip
    if (editingCode === newCode) {
      this.isCustomFieldError = false
      return
    }

    // validate new code
    const isDuplicated = !!this.customFieldSettings.find((field) => field.customFieldKey === newCode)
    if (isDuplicated) {
      this.isCustomFieldError = true

      openMessage(this.$t('duplicated_custom_field_code'), 'error')
      return
    }

    this.isCustomFieldError = false
  }

  updateOrderCustomFields() {
    this.drag = false
    this.customFieldSettings.forEach((item, index) => {
      if (this.fields) {
        this.fields.customFields[item?.customFieldKey || ''].order = index + 1
      }
    })
  }

  onAddCustomField() {
    this.isShowCustomFieldDialog = true

    this.editingCustomFieldData = {
      ...PROJECT_CUSTOM_FIELDS_DEFAULT.text,
      ...ACTIVATION_CUSTOM_FIELD_EXTRA_DATA,
      customFieldKey: '',
      order: this.customFieldSettings.length + 1,
      isNew: true,
    }
  }

  onEditCustomField(idx: number) {
    const field = this.customFieldSettings[idx]
    field.isNew = this.customFieldAdded.includes(field.customFieldKey || '')
    this.editingCustomFieldData = { ...field }
    this.isShowCustomFieldDialog = true
  }

  onDeleteCustomField(customFieldKey: string) {
    delete this.fields.customFields[customFieldKey ?? '']
    Object.values(this.fields.customFields).forEach((item, idx) => (item.order = idx + 1))
    this.formatCustomFieldSettings()
  }

  onCustomFieldsUpdate(customFieldData: IActivationCustomField) {
    if (!this.fields.customFields) this.fields.customFields = {}

    delete this.fields.customFields[this.editingCustomFieldData.customFieldKey ?? '']
    const { customFieldKey, ...newFields } = customFieldData
    this.fields.customFields[customFieldKey as string] = newFields
    this.formatCustomFieldSettings()
    this.isShowCustomFieldDialog = false
  }

  onChangeActivationSteps(stepKey: string, value: string) {
    ;(this.fields[stepKey as keyof IActivationFields] as IFieldRestriction & IRecycleFields) = {
      ...this.fields[stepKey as keyof IActivationFields],
      ...restrictionRule(value),
      copyOnRecycle: value === 'unavailable' ? false : !!this.fields[stepKey as keyof IActivationFields]?.copyOnRecycle,
    }
  }

  onChangeCopyOnRecycle(stepKey: string, value: boolean) {
    ;(this.fields[stepKey as keyof IActivationFields] as IFieldRestriction & IRecycleFields) = {
      ...(this.fields[stepKey as keyof IActivationFields] as IFieldRestriction),
      copyOnRecycle: value,
    }
  }

  async created() {
    if (!this.$store.state.profile?.isLoadCountry) {
      try {
        await this.$store.dispatch(FETCH_COUNTRIES)
      } catch (err) {
        this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'country' })
      }
    }

    this.countriesList = {
      popularCountries: { ...POPULAR_COUNTRIES },
      allCountries: this.$store.state.profile?.countries,
    } as {
      popularCountries: string[]
      allCountries: string[]
    }
  }
}
