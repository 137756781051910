<template>
  <SvgBase iconName="IconUnpair" :width="16" :height="16">
    <path
      d="M13.5505 10.7947C12.916 12.0776 11.8869 13.1233 10.6143 13.7782C9.35077 14.4269 7.91022 14.646 6.511 14.4022C5.10744 14.1555 3.82154 13.4608 2.84575 12.4222C1.86091 11.3738 1.23386 10.0402 1.05475 8.61296C0.872839 7.18251 1.14684 5.73113 1.83775 4.46546C2.52231 3.20948 3.59207 2.2065 4.8895 1.60421C6.17823 1.00733 7.62652 0.84723 9.0145 1.14821C10.402 1.44896 11.539 2.13296 12.4795 3.21446C12.5928 3.33446 13.0308 3.81896 13.3945 4.55021"
      stroke="#F2994A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.0518 4.60963L13.8318 5.28988L14.5 1.44238"
      stroke="#F2994A"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from '@/components/svg/SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUnpair extends Vue {}
</script>
