<template>
  <div
    :style="`height:${containerHeight}px;`"
    class="preview-col-width box-border flex items-center border-l border-b border-solid border-gray-200 bg-white"
  >
    <div class="flex-grow items-center justify-center flex relative">
      <div
        class="rounded-full bg-white border-solid track-point-node z-20"
        :class="`track-point-code-${line}`"
        :style="`border-color: ${color}; ${getWidthHeight}`"
        :id="`node_${id}`"
      ></div>
      <template v-if="isShowPair || isShowUnpair">
        <div class="absolute node-actions flex w-12">
          <div class="flex w-1/2 justify-start">
            <el-tooltip v-if="isShowPair" :content="$t('preview_page.pairing')" placement="right" effect="dark">
              <div class="node-actions__action"><IconPair /></div>
            </el-tooltip>
          </div>
          <div class="flex w-1/2 justify-end">
            <el-tooltip v-if="isShowUnpair" :content="$t('preview_page.unpairing')" placement="right" effect="dark">
              <div class="node-actions__action"><IconUnpair /></div>
            </el-tooltip>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconUnpair from '@/components/svg/barcodeType/IconUnpair.vue'
import IconPair from '@/components/svg/barcodeType/IconPair.vue'
@Options({
  components: { IconPair, IconUnpair },
  name: 'TrackPointNode',
})
export default class TrackPointNode extends Vue {
  @Prop({ type: String }) color?: string
  @Prop({ type: String }) line?: string
  @Prop({ type: String }) id?: string
  @Prop({ type: Number }) diameter!: number | 0
  @Prop({ type: Number }) containerHeight!: number | 0
  @Prop({ type: Boolean }) isShowPair?: boolean
  @Prop({ type: Boolean }) isShowUnpair?: boolean

  get getWidthHeight() {
    const pxDiameter = this.diameter - 6
    return `width: ${pxDiameter}px; height: ${pxDiameter}px;`
  }
}
</script>

<style lang="scss" scoped>
.track-point-node {
  border-width: 3px;
}
.node-actions {
  top: calc(100% + 1px);
  left: 50%;
  transform: translate(-50%, 0);

  &__action + .node-actions__action {
    margin-left: 15px;
  }
}
</style>
