
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import cloneDeep from 'lodash/cloneDeep'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:customFieldVisibilities'],
  name: 'ShowCustomFieldsBlock',
})
export default class ShowCustomFieldsBlock extends Vue {
  @PropSync('customFieldVisibilities', { type: Object }) _customFieldVisibilities!: Array<string>

  customFieldVisibilitieValue = {} as Record<string, boolean>

  created() {
    this.initCustomFieldVisibilities()
  }

  @Watch('_customFieldVisibilities')
  onCustomFieldVisibilitiesChange() {
    this.initCustomFieldVisibilities()
  }

  initCustomFieldVisibilities() {
    this.customFieldVisibilitieData.forEach((item) => {
      this.customFieldVisibilitieValue[item] = this._customFieldVisibilities?.includes(item) ?? false
    })
  }

  updateCustomFieldVisibilities() {
    const newValue = [] as string[]
    for (const i in this.customFieldVisibilitieValue) {
      if (this.customFieldVisibilitieValue[i]) newValue.push(i)
    }
    this._customFieldVisibilities = cloneDeep(newValue)
  }

  get customFieldVisibilitieData() {
    return [
      ECustomFieldType.TEXT,
      ECustomFieldType.DATE,
      ECustomFieldType.SINGLE_SELECT,
      ECustomFieldType.MULTI_SELECT,
      ECustomFieldType.NUMBER,
      ECustomFieldType.ESIGN,
      ECustomFieldType.FILES,
      ECustomFieldType.PHONE_NUMBER,
      ECustomFieldType.EMAIL,
      ECustomFieldType.REFERENCE,
      ECustomFieldType.GHG_EMISSION,
      ECustomFieldType.CALCULATION,
    ]
  }
}
