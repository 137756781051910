<template>
  <div v-loading.fullscreen="isLoading">
    <ItemNotFound v-if="!shouldShowPreview" :content="$t('preview_page.no_data')" />
    <template v-else>
      <div class="mt-2">
        <div :class="`grid grid-cols-8 ${!isTrackedPointsFlowLoaded ? 'opacity-0' : ''}`">
          <div class="col-span-2 mt-10 bg-white overflow-y-auto" @scroll="handleScrollLeft" id="preview-tab__left">
            <template v-for="(type, code) in barcodeTypes" :key="code">
              <div
                :style="`height:${barcodeTitleHeight}px`"
                class="col-span-2 flex items-center box-border bg-white border-b border-solid border-gray-200"
              >
                <PreviewBarcodeColumnTitle :barcodeType="type" />
              </div>
            </template>
            <div
              class="flex items-center px-3 bg-body"
              v-if="hasTrackingPoints"
              :style="`height: ${heightTrackPoint}px`"
            >
              {{ $t('tracking_point') }}
            </div>
            <div class="pl-4 col-span-2 px-2 flex items-center h-24 bg-white" :style="`height: ${heightUserGroup}px`">
              <IconUserGroup />
              <h4 class="ml-3">
                {{ $t('projects.userGroup') }}
              </h4>
            </div>
          </div>

          <div class="col-span-6 overflow-x-auto overflow-y-hidden" v-if="hasTrackingPoints" style="position: relative">
            <div class="flex items-center">
              <template v-for="(trackPoint, i) in arrTrackPoints" :key="trackPoint.key">
                <div
                  class="preview-col-width box-border text-center px-4 py-2 text-white truncate leading-normal"
                  :class="[i % 2 ? 'bg-primary' : 'bg-gray-400']"
                >
                  {{ trackPoint.value.name }}
                </div>
              </template>
            </div>
            <div
              class="overflow-y-auto overflow-x-hidden"
              id="preview-tab__right"
              :style="`width: ${arrTrackPoints.length * 160}px`"
              @scroll="handleScrollRight"
            >
              <template v-for="(bcType, code) in bcTypeTrackpoints" :key="code">
                <TrackPointNodeList
                  :code="code"
                  :barcodeType="bcType.type"
                  :arrTrackPoints="bcType.routes"
                  :barcodeTitleHeight="barcodeTitleHeight"
                  @update:barcodeTitleHeight="updateBCTypeHeight"
                  @dataFlowBarcodeType:drawDone="barcodeTypeFlowDrawDone"
                />
              </template>
              <!-- Route diagram -->
              <div v-if="hasTrackingPoints">
                <SequenceTrackPointDiagram
                  ref="trackPointDiagram"
                  :arrTrackPoints="arrTrackPoints"
                  v-model:heightTrackPoint="heightTrackPoint"
                  @dataFlow:drawDone="flowDrawDone"
                />
              </div>
              <div v-if="hasTrackingPoints">
                <PreviewGroupUsers
                  :groupUsers="permissionGroup"
                  :arrTrackPoints="arrTrackPoints"
                  @update:UserGroupHeight="updateUserGroupHeight"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PreviewBarcodeColumnTitle from '@/components/project/PreviewBarcodeColumnTitle.vue'
import PreviewGroupUsers from '@/components/project/PreviewGroupUsers.vue'
import SequenceTrackPointDiagram from '@/components/project/SequenceTrackPointDiagram.vue'
import TrackPointNodeList from '@/components/project/trackingPoint/TrackPointNodeList.vue'
import IconUserGroup from '@/components/svg/IconUserGroup.vue'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeDefinitionType, ICreateUserGroup, ITrackpoint, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import { sortTrackingPoints } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

export interface IBCTypeRoute {
  type: IBarcodeDefinitionType
  routes: ITrackPointKeyVal[]
}

export interface IRoute {
  from: string
  to: string
}

@Options({
  emits: ['update:key'],
  components: {
    ItemNotFound,
    PreviewBarcodeColumnTitle,
    PreviewGroupUsers,
    SequenceTrackPointDiagram,
    TrackPointNodeList,
    IconUserGroup,
  },
  name: 'PreviewTab',
})
export default class PreviewTab extends Vue {
  @Prop({ type: Array }) permissionGroup?: ICreateUserGroup[]
  barcodeTitleHeight = 0
  heightTrackPoint = 0
  heightUserGroup = 0

  isLoading = true
  trackedPointsFlowLoaded = false
  barcodeTypeTrackedPointsFlowLoaded = false

  // If leaderline is drawing, dont show it
  bodyDrawingClass = 'drawing-leader-line'

  currentPreviewProjectDetail = null

  beforeCreate() {
    document.body.classList.add(this.bodyDrawingClass)
    this.currentPreviewProjectDetail = this.$store.state.project.projectDetail
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleScrollRight(e: any) {
    const element = document.getElementById('preview-tab__left') as HTMLElement
    if (element) element.scrollTop = e.target.scrollTop
  }

  updateUserGroupHeight(height: number) {
    this.heightUserGroup = height
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleScrollLeft(e: any) {
    const element = document.getElementById('preview-tab__right') as HTMLElement
    if (element) element.scrollTop = e.target.scrollTop
  }

  @Watch('isTrackedPointsFlowLoaded')
  bodyDrawingClassController() {
    if (this.isTrackedPointsFlowLoaded) {
      this.isLoading = false
      document.body.classList.remove(this.bodyDrawingClass)
    } else {
      this.isLoading = true
      document.body.classList.add(this.bodyDrawingClass)
    }
  }

  flowDrawDone() {
    this.trackedPointsFlowLoaded = true
  }

  updateBCTypeHeight(height: number) {
    this.barcodeTitleHeight = Math.max(height, this.barcodeTitleHeight)
  }

  bcTypeTrackpoints = {} as Record<string, IBCTypeRoute>
  mounted() {
    setTimeout(() => {
      this.bcTypeTrackpoints = Object.entries(this.barcodeTypes || {}).reduce(
        (total, [bcTypeKey, bcType]) => ({
          ...total,
          [bcTypeKey]: { type: bcType, routes: this.barcodeRoute(bcType) },
        }),
        {}
      )
    })
  }

  unmounted() {
    document.body.classList.remove(this.bodyDrawingClass)
  }

  barcodeRoute(bcType: IBarcodeDefinitionType) {
    if (!this.arrTrackPoints) return []
    const arrTrackPoints = cloneDeep(this.arrTrackPoints)
    const startTP = arrTrackPoints.find((tp) => tp.value.isStart)?.key
    const S = [...(!isEmpty(bcType.overridableStartTrackPoints) ? bcType.overridableStartTrackPoints : [startTP])]
    const T = [] as string[]
    const availRoutes = [] as IRoute[]

    const restrictedRoutes = bcType.trackPointRouteRestrictedPaths ?? []
    while (!isEmpty(S)) {
      const item = S.pop()
      if (!item || T.includes(item)) continue
      T.push(item)
      const tpItem = arrTrackPoints.find((i) => i.key === item)
      if (!tpItem) continue
      const forms = tpItem.value.trackPointForms ?? {}
      Object.keys(forms).forEach((toKey) => {
        const isRestricted = restrictedRoutes.find((rr) => rr.from === item && rr.to === toKey)
        if (isRestricted) return
        availRoutes.push({
          from: item,
          to: toKey,
        })
        S.push(toKey)
      })
    }

    arrTrackPoints.forEach((tp) => {
      const fromKey = tp.key
      const forms = tp.value.trackPointForms ?? {}
      Object.keys(forms).forEach((toKey) => {
        const availRoute = availRoutes.find((availRoute) => availRoute.from === fromKey && availRoute.to === toKey)
        if (!availRoute) delete forms[toKey]
      })
    })

    return arrTrackPoints
  }

  get isTrackedPointsFlowLoaded() {
    return !this.hasTrackingPoints || (this.trackedPointsFlowLoaded && this.barcodeTypeTrackedPointsFlowLoaded)
  }

  get barcodeTypes(): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project.projectDetail.barcodeTypes || {}
  }

  get trackPoints(): Record<string, ITrackpoint> {
    return this.$store.state.project.projectDetail.trackPoints || {}
  }

  @Watch('$store.state.project.projectDetail')
  refresh() {
    const storePrjDetail = this.$store.state.project.projectDetail
    if (!isEqual(this.currentPreviewProjectDetail, storePrjDetail)) {
      this.currentPreviewProjectDetail = storePrjDetail
      this.$emit('update:key')
    }
  }

  get arrTrackPoints(): ITrackPointKeyVal[] {
    const arrTrackPoints = objectToArray<ITrackpoint>(this.trackPoints) || []
    sortTrackingPoints(arrTrackPoints)

    return arrTrackPoints
  }

  get hasTrackingPoints() {
    return !!Object.keys(this.trackPoints).length
  }

  get hasBarcodeTypes() {
    return !!Object.keys(this.barcodeTypes).length
  }

  get hasPermissionGroup() {
    return !!this.permissionGroup?.length
  }

  get shouldShowPreview() {
    return this.hasTrackingPoints || this.hasBarcodeTypes || this.hasPermissionGroup
  }

  CheckedbarcodeTypeCount = 0
  barcodeTypeFlowDrawDone() {
    this.CheckedbarcodeTypeCount++
    if (this.CheckedbarcodeTypeCount === Object.keys(this.barcodeTypes).length) {
      this.barcodeTypeTrackedPointsFlowLoaded = true
    }
  }
}
</script>
<style lang="scss">
body.drawing-leader-line {
  // Temporary hide custom fields and leaderline if drawing
  .leader-line,
  .custom-fields-wrapper {
    opacity: 0;
  }
  // Prevent scroll if drawing
  .overflow-x-auto {
    overflow: hidden;
  }
}
#preview-tab {
  &__left {
    max-height: calc(100vh - 180px - 3rem);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__right {
    max-height: calc(100vh - 180px - 3rem);
  }
}
</style>
