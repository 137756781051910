<template>
  <el-form :disabled="!allowToPair || isReadOnly" v-for="(label, action) in trackPointActions" :key="action">
    <div class="grid grid-cols-12 border-b gap-4 py-8">
      <div class="col-span-3 p-1">
        <div class="font-bold whitespace-nowrap">
          {{ label }}
        </div>
      </div>
      <div class="col-span-9">
        <!-- Items -->
        <div class="grid grid-cols-5 items-center gap-4 pb-1" v-for="item in trackingPointDataShown" :key="item.key">
          <div class="text-right">{{ item.value.name }} {{ `(${item.key})` }}</div>
          <div class="col-span-4">
            <el-radio-group
              v-model="actionTrackPoints[action][item.key]"
              @change="onChangePairOrUnpairTrackingPoints(action, item.key, $event)"
            >
              <el-radio label="mandatory" :disabled="isDisabledPairUnpairMandatoryOption(item.key, action)">
                {{ $t('barcode_type.mandatory') }}
              </el-radio>
              <el-radio label="available">
                {{ $t('barcode_type.available') }}
              </el-radio>
              <el-radio label="unavailable">
                {{ $t('barcode_type.unavailable') }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>
    <div class="border-b border-gray-400 border-solid"></div>
  </el-form>
</template>
<script lang="ts">
import { fieldRestriction2String, restrictionRule } from '@/utils/helpers'
import { Vue, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'
import { IFieldRestriction, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'

@Options({
  emits: ['update:pairing', 'update:unpairing'],
  name: 'PairedAllowanceBlock',
})
export default class PairedAllowanceBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('pairing', { type: Object }) _pairing?: { [index: string]: IFieldRestriction }
  @PropSync('unpairing', { type: Object }) _unpairing?: { [index: string]: IFieldRestriction }
  @Prop({ type: Boolean, default: false }) readonly allowToPair?: boolean
  @Prop({ type: Boolean, default: false }) readonly isReadOnly?: boolean

  get trackPointActions() {
    return {
      pairing: this.$t('barcode_type.pair_tracking_point'),
      unpairing: this.$t('barcode_type.unpair_tracking_point'),
    }
  }

  get actionTrackPoints(): Record<'pairing' | 'unpairing', Record<string, string>> {
    return {
      pairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String((this._pairing ?? {})[v.key])])
      ),
      unpairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String((this._unpairing ?? {})[v.key])])
      ),
    }
  }

  isDisabledPairUnpairMandatoryOption(key: string, action: string): boolean {
    return (
      (action === 'unpairing' && this.actionTrackPoints.pairing[key] === 'mandatory') ||
      (action === 'pairing' && this.actionTrackPoints.unpairing[key] === 'mandatory')
    )
  }

  onChangePairOrUnpairTrackingPoints(type: 'pairing' | 'unpairing', trackPointKey: string, value: string) {
    this[`_${type}`] = {
      ...(this[`_${type}`] && this[`_${type}`]),
      [trackPointKey]: restrictionRule(value),
    }
  }
}
</script>
