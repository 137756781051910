<template>
  <div class="h-full w-full" v-loading.fullscreen="loading">
    <div class="flex items-center pb-8 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader
          class="pr-6"
          :title="id ? $t('carrier.permission_group_edit') : $t('carrier.permission_group_add')"
          :isHideRefresh="true"
        />
        <el-button class="rounded-full py-3 px-10 bg-blue-500 text-white" @click="onSave" :disabled="isSaveDisabled">{{
          $t('save')
        }}</el-button>
      </div>
      <div class="header-container__right">
        <el-button type="primary" @click="goToPermissionGroupList">{{ $t('carrier.permission_group_list') }}</el-button>
      </div>
    </div>

    <div class="mt-4 form-container">
      <el-tabs @tab-click="changeTab" v-model="activeTabName">
        <el-tab-pane :name="tabs.baseSetting" :label="$t('base_setting')">
          <div class="text-lg">
            <el-form
              status-icon
              :label-position="'right'"
              label-width="220px"
              :rules="rules"
              :model="userGroupModel"
              ref="userGroupForm"
            >
              <div class="flex form-text-left mt-8 mb-4 border-b border-gray-400 border-solid">
                <div class="w-3/12">
                  <label class="el-form-item__label text-left w-full text-lg font-medium">{{
                    $t('barcode_type.basic_info')
                  }}</label>
                </div>
                <div class="w-9/12 font-medium">
                  <el-form-item prop="name" :label="$t('carrier.group_name')" label-width="100px">
                    <el-input
                      class="w-full text-left"
                      v-model="userGroupModel.name"
                      name="name"
                      autocomplete="on"
                      size="medium"
                    />
                  </el-form-item>
                </div>
              </div>
              <div class="flex mt-8">
                <div class="w-3/12">
                  <label for="status" class="el-form-item__label text-left w-full text-lg font-medium">{{
                    $t('status')
                  }}</label>
                </div>
                <div class="w-9/12">
                  <el-form-item prop="isActive" label="" label-width="0">
                    <el-checkbox v-model="userGroupModel.isActive">{{ $t('active') }}</el-checkbox>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
        </el-tab-pane>
        <el-tab-pane :name="tabs.projectPermission" :label="$t('carrier.project_permission')">
          <UserGroupProjectPermission />
        </el-tab-pane>
        <el-tab-pane :name="tabs.userList" :label="$t('Staff_List')" :lazy="true">
          <UserGroupUserList />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { ICarrierUserGroup } from 'smartbarcode-web-core/src/utils/types/index'
import UserGroupProjectPermission from '@/components/carrier/UserGroupProjectPermission.vue'
import UserGroupUserList from '@/components/carrier/UserGroupUserList.vue'
import { FETCH_USER_GROUP, FETCH_USER_GROUPS, FETCH_USER_GROUP_ADD, FETCH_USER_GROUP_PROJECTS } from '@/store/actions'
import { openMessage } from '@/utils/utils'
import { addUserGroup, updateUserGroup } from '@/utils/api'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: { ItemNotFound, PageHeader, UserGroupProjectPermission, UserGroupUserList },
  directives: { maska },
  name: 'UserGroupForm',
})
export default class UserGroupForm extends mixins(ValidateForm) {
  loading = false
  activeTabName = this.tabs.baseSetting
  prevTabIndex = 0
  userGroupModel = {} as ICarrierUserGroup
  isSaveDisabled = false

  rules = {
    name: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  get id() {
    return this.$route.params?.id || ''
  }

  get tabs() {
    return {
      baseSetting: 'baseSetting',
      projectPermission: 'projectPermission',
      userList: 'userList',
    }
  }

  async created() {
    await this.loadData()
  }

  async loadData() {
    this.loading = true
    const promises = []
    if (this.id) {
      promises.push(this.$store.dispatch(FETCH_USER_GROUP, this.id))
    } else {
      promises.push(await this.$store.dispatch(FETCH_USER_GROUP_ADD))
    }
    promises.push(this.$store.dispatch(FETCH_USER_GROUP_PROJECTS))
    await Promise.all(promises)
    this.loading = false
    this.userGroupModel = cloneDeep(this.$store.state.userGroup.userGroup)
  }

  changeTab(tab: Record<string, unknown>) {
    this.prevTabIndex = Number(tab.index)
  }

  onSave() {
    this.$refs.userGroupForm.validate(async (valid: string) => {
      if (valid) {
        await this.saveUserGroup()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        return false
      }
    })
  }

  async saveUserGroup() {
    this.isSaveDisabled = true
    this.loading = true
    const userGroup = cloneDeep(this.$store.state.userGroup.userGroup) as ICarrierUserGroup
    try {
      userGroup.name = this.userGroupModel.name
      userGroup.isActive = this.userGroupModel.isActive

      if (this.id) {
        await updateUserGroup(userGroup)
      } else {
        await addUserGroup(userGroup)
      }

      openMessage(this.$t('save_successful'), 'success')

      await this.$store.dispatch(FETCH_USER_GROUPS)
      this.$router.push({
        name: 'userGroup',
      })
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  goToPermissionGroupList() {
    this.$router.push({
      name: 'userGroup',
    })
  }
}
</script>
<style lang="scss" scoped>
</style>
