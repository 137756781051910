
import { Vue, Options } from 'vue-class-component'
import IconBarcodeIsChildren from '@/components/svg/IconBarcodeIsChildren.vue'
import IconBarcodeIsParent from '@/components/svg/IconBarcodeIsParent.vue'
import IconBarcodeIsParentChildren from '@/components/svg/IconBarcodeIsParentChildren.vue'
import IconVerticalThreeDots from '@/components/svg/IconVerticalThreeDots.vue'
import IconRecycle from '@/components/svg/IconRecycle.vue'
import IconLocation from '@/components/svg/IconLocation.vue'
import IconLocationDistination from '@/components/svg/IconLocationDistination.vue'
import { ClickOutside } from '@/utils/directives'
import { IBarcodeDefinitionType, IProjectCustomField } from 'smartbarcode-web-core/src/utils/types/index'
import { Prop } from 'vue-property-decorator'
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import IconBarcodeIsNotParentNotChild from '@/components/svg/IconBarcodeIsNotParentNotChild.vue'
import { CUSTOM_FIELD_ICON_COMPONENTS } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { ECustomFieldExtraType, ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { iconFileKindKey } from '@/utils/helpers'

@Options({
  directives: {
    clickOutside: ClickOutside,
  },
  components: {
    IconBarcodeIsChildren,
    IconBarcodeIsParent,
    IconBarcodeIsParentChildren,
    IconRecycle,
    IconVerticalThreeDots,
    IconLocation,
    IconLocationDistination,
    BarcodeIcon,
  },
  name: 'PreviewBarcodeColumnTitle',
})
export default class PreviewBarcodeColumnTitle extends Vue {
  @Prop({ type: Object }) barcodeType?: IBarcodeDefinitionType
  isShowAction = false
  isSecondTime = false

  get containerIcon() {
    const isChild = this.barcodeType?.allowToBePaired
    const isParent = this.barcodeType?.allowToPair
    const childLabel = 'child_barcode'
    const parentLabel = 'parent'
    if (isChild && isParent) {
      return { label: [parentLabel, childLabel], icon: IconBarcodeIsParentChildren }
    } else if (isChild) {
      return { label: [childLabel], icon: IconBarcodeIsChildren }
    } else if (isParent) {
      return { label: [parentLabel], icon: IconBarcodeIsParent }
    } else {
      return { label: ['pair_unavailable'], icon: IconBarcodeIsNotParentNotChild }
    }
  }

  get customFields() {
    const customFields = (this.barcodeType?.activationFields.customFields ?? {}) as Record<string, IProjectCustomField>
    const obj = Object.values(customFields ?? {}).map((value) => {
      const fieldType: string =
        value.fieldType === ECustomFieldType.TEXT && value.multiLine ? ECustomFieldExtraType.TEXT_AREA : value.fieldType

      const hints: string[] = []
      hints.push(value.label ?? '')

      const iconComponentKey = fieldType === ECustomFieldType.FILES ? iconFileKindKey(value.fileKind) : fieldType
      return cloneDeep({ ...CUSTOM_FIELD_ICON_COMPONENTS[iconComponentKey], hints: hints })
    })
    return obj
  }

  get activationKeys() {
    return {
      origin: this.$t('preview_page.origin'),
      destination: this.$t('preview_page.destination'),
      trackingNumber: this.$t('tracking_number'),
      dimension: this.$t('barcode_type.dimension'),
      externalId: this.$t('barcode_type.external_id'),
      product: this.$t('barcode_type.selection_product'),
    }
  }

  get origin() {
    return this.barcodeType?.activationFields.origin
  }

  get destination() {
    return this.barcodeType?.activationFields.destination
  }

  get trackingNumber() {
    return this.barcodeType?.activationFields.trackingNumber
  }

  get dimension() {
    return this.barcodeType?.activationFields.dimension
  }

  get externalId() {
    return this.barcodeType?.activationFields.externalId
  }

  get product() {
    return this.barcodeType?.activationFields.product
  }

  get shouldShowDropdown() {
    return (
      this.origin?.isAvailable ||
      this.destination?.isAvailable ||
      this.dimension?.isAvailable ||
      this.trackingNumber?.isAvailable ||
      this.externalId?.isAvailable ||
      this.product?.isAvailable
    )
  }

  stepRecycleIcon(recycle: boolean) {
    return recycle ? IconLocationDistination : IconLocation
  }

  showAction() {
    this.isShowAction = true
    this.isSecondTime = false
  }

  hideActions() {
    if (this.isSecondTime) {
      this.isShowAction = false
      this.isSecondTime = false
    } else {
      this.isSecondTime = true
    }
  }
}
