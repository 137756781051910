
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import { IStaff, ITransferData } from 'smartbarcode-web-core/src/utils/types/index'
import { UPDATE_USER_LIST } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: { ItemNotFound },
  directives: { maska },
  name: 'UserGroupUserList',
})
export default class UserGroupUserList extends Vue {
  allUsers = [] as ITransferData[]
  usersInGroup = [] as string[]

  get userGroupId() {
    return this.$route.params?.id || ''
  }

  async created() {
    const staffs = (this.$store.state.userGroup.staffs || []) as IStaff[]
    staffs.forEach((item) => {
      if (item.id) {
        this.allUsers.push({
          key: item.id || '',
          label: item.fullName || '',
        })
      }
    })

    this.usersInGroup = cloneDeep(this.$store.state.userGroup?.userGroup?.userIds) || ([] as string[])
  }

  handleChange(value: string[]) {
    this.$store.commit(UPDATE_USER_LIST, value)
  }
}
