
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeReportTemplate,
  ICountryCode,
  ICustomFieldFormData,
  INotificationRoute,
  ISMSDestination,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

export interface IRule {
  field: string
  fullField: string
  required: boolean
  type: string
  countryCode?: string
  validator: Function
}

@Options({
  components: { SelectAutocomplete },
  emits: ['update:modelValue'],
  name: 'TrackingNotificationBlock',
})
export default class TrackingNotificationBlock extends mixins(ValidateForm) {
  @PropSync('modelValue', { type: Object }) syncedModelValue!: INotificationRoute[]
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @Prop({ type: Object }) readonly barcodeReportTemplates!: IBarcodeReportTemplate[]

  get phoneNoCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.PHONE_NUMBER)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get isPhoneAvail() {
    return !isEmpty(this.currentNotificationRoute.smsDestinations.filter((v) => v.number !== ''))
  }

  get isPhoneAliasAvail() {
    return !isEmpty(this.currentNotificationRoute.smsDestinationAliases.filter((v) => v !== ''))
  }

  get isEmailAvail() {
    return !isEmpty(this.currentNotificationRoute.emailDestinations.filter((v) => v !== ''))
  }

  get isEmailAliasAvail() {
    return !isEmpty(this.currentNotificationRoute.emailDestinationAliases.filter((v) => v !== ''))
  }

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this.notificationRecords.forEach((val, idx) => {
      const endTPRouteAvailable = this.loadTP(val.from)

      const found = endTPRouteAvailable.find((val) => {
        return val.key === this.notificationRecords?.[idx]?.to
      })
      if (!found && val.to) {
        this.notificationRecords.splice(idx, 1)
      }
    })
  }

  isShowNotifSettingDialog = false
  notificationRecords = [] as INotificationRoute[]
  selectedNotifSettingIdx = -1
  hasSMS = true
  hasEmail = true

  currentNotificationRoute = {} as INotificationRoute

  addNewPhoneNo() {
    this.currentNotificationRoute.smsDestinations.push({ number: '', countryCode: 'JP' })
  }

  deletePhoneNo(idx: number) {
    if (this.currentNotificationRoute.smsDestinations.length === 1 && idx === 0) return
    this.currentNotificationRoute.smsDestinations.splice(idx, 1)
  }

  addNewPhoneNoCF() {
    this.currentNotificationRoute.smsDestinationAliases.push('')
  }

  deletePhoneNoCF(idx: number) {
    if (this.currentNotificationRoute.smsDestinationAliases.length === 1 && idx === 0) return
    this.currentNotificationRoute.smsDestinationAliases.splice(idx, 1)
  }

  addNewEmail() {
    this.currentNotificationRoute.emailDestinations.push('')
  }

  deleteEmail(idx: number) {
    if (this.currentNotificationRoute.emailDestinations.length === 1 && idx === 0) return
    this.currentNotificationRoute.emailDestinations.splice(idx, 1)
  }

  addNewEmailCF() {
    this.currentNotificationRoute.emailDestinationAliases.push('')
  }

  deleteEmailCF(idx: number) {
    if (this.currentNotificationRoute.emailDestinationAliases.length === 1 && idx === 0) return
    this.currentNotificationRoute.emailDestinationAliases.splice(idx, 1)
  }

  smsRules = {
    smsBody: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  emailRules = {
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  async onSave() {
    let isSmsValid = false
    let isEmailValid = false

    if (this.hasSMS) this.$refs.smsForm.validate((valid: string) => (isSmsValid = !!valid))
    else isSmsValid = true
    if (this.hasEmail) this.$refs.emailForm.validate((valid: string) => (isEmailValid = !!valid))
    else isEmailValid = true
    setTimeout(() => {
      if (!(isSmsValid && isEmailValid)) return

      const newRecord = cloneDeep(this.currentNotificationRoute)
      if (!this.hasSMS) {
        newRecord.smsDestinations = []
        newRecord.smsDestinationAliases = []
        newRecord.smsBody = ''
      }
      if (!this.hasEmail) {
        newRecord.emailDestinations = []
        newRecord.emailDestinationAliases = []
        newRecord.emailSubject = ''
        newRecord.emailBody = ''
        newRecord.requestReportButtonLabelGenerates = []
      }

      // remove duplicate records
      newRecord.smsDestinations = uniqBy(
        newRecord.smsDestinations.filter((v) => v.number !== ''),
        (item) => item.countryCode && item.number
      )
      newRecord.emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
      newRecord.smsDestinationAliases = [...new Set(newRecord.smsDestinationAliases.filter((v) => v !== ''))]
      newRecord.emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]

      this.notificationRecords[this.selectedNotifSettingIdx] = newRecord

      this.closeNotifSettingDialog()
    }, 100)
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST as Record<string, ICountryCode>,
      allCountries: OTHER_COUNTRY_LIST as Record<string, ICountryCode>,
    }
  }

  onPhoneChanged(idx: number) {
    const sms = this.currentNotificationRoute.smsDestinations[idx] as ISMSDestination
    const pn = parsePhoneNumber(sms.number ?? '', sms.countryCode as CountryCode)
    if (!pn) return
    sms.number = new AsYouType(sms.countryCode as CountryCode).input(sms.number ?? '').replaceAll(' ', '-')
  }

  formatPhoneValidate = (rule: IRule, value: string, callback: Function) => {
    if (isEmpty(value)) return callback(new Error(this.messages.required))
    const phoneNumber = parsePhoneNumber(value, rule.countryCode as CountryCode)
    if (phoneNumber && phoneNumber.isValid()) callback()
    else callback(new Error(this.messages.number))
  }

  onSelectedCountryChanged(idx: number) {
    this.currentNotificationRoute.smsDestinations[idx].number = ''
  }

  @Watch('currentNotificationRoute')
  onNotifRouteChanged() {
    this.hasSMS = !(
      isEmpty(this.currentNotificationRoute.smsDestinations) && isEmpty(this.currentNotificationRoute.smsBody)
    )

    this.hasEmail = !(
      isEmpty(this.currentNotificationRoute.emailDestinations) &&
      isEmpty(this.currentNotificationRoute.emailSubject) &&
      isEmpty(this.currentNotificationRoute.emailBody)
    )
  }

  @Watch('currentNotificationRoute.emailDestinations')
  onEmailDestListChanged() {
    if (!isEmpty(this.currentNotificationRoute.emailDestinations)) return
    this.currentNotificationRoute.emailDestinations.push('')
  }

  @Watch('currentNotificationRoute.emailDestinationAliases')
  onEmailDestAlListChanged() {
    if (!isEmpty(this.currentNotificationRoute.emailDestinationAliases)) return
    this.currentNotificationRoute.emailDestinationAliases.push('')
  }

  @Watch('currentNotificationRoute.smsDestinations')
  onSMSDestListChanged() {
    if (!isEmpty(this.currentNotificationRoute.smsDestinations)) return
    this.currentNotificationRoute.smsDestinations.push({ number: '', countryCode: 'JP' })
  }

  @Watch('currentNotificationRoute.smsDestinationAliases')
  onSMSDestAlListChanged() {
    if (!isEmpty(this.currentNotificationRoute.smsDestinationAliases)) return
    this.currentNotificationRoute.smsDestinationAliases.push('')
  }

  @Watch('syncedModelValue', { deep: true })
  onModelValueChanged() {
    if (isEqual(this.syncedModelValue, this.cleanRecords)) return
    this.notificationRecords = cloneDeep(this.syncedModelValue)
  }

  @Watch('notificationRecords', { deep: true })
  onNotificationRecordsChanged() {
    this.syncedModelValue = cloneDeep(this.cleanRecords)
  }

  get cleanRecords() {
    const newRecords = cloneDeep(this.notificationRecords).filter(
      (rc) => (!isEmpty(rc.from) && !isEmpty(rc.to)) || rc.isNew
    )
    newRecords.forEach((rc) => {
      rc.smsDestinations.map((sms) => (sms.number = sms.number.replaceAll('-', '')))
    })

    return newRecords
  }

  openNotifSettingDialog(idx: number) {
    this.isShowNotifSettingDialog = true
    this.selectedNotifSettingIdx = idx
    this.currentNotificationRoute = cloneDeep(this.notificationRecords[idx])
    this.currentNotificationRoute.smsDestinationAliases = this.currentNotificationRoute?.smsDestinationAliases ?? []
    this.currentNotificationRoute.emailDestinationAliases = this.currentNotificationRoute?.emailDestinationAliases ?? []
  }

  closeNotifSettingDialog() {
    this.isShowNotifSettingDialog = false
    this.notifSettingIdx = -1
  }

  addTrackingNotification() {
    this.notificationRecords.push({
      isNew: true,
      from: '',
      to: '',
      smsDestinations: [] as ISMSDestination[],
      emailDestinations: [] as string[],
      smsDestinationAliases: [] as string[],
      emailDestinationAliases: [] as string[],
      smsBody: '',
      emailSubject: '',
      emailBody: '',
      requestReportButtonLabelGenerates: [] as string[],
    } as INotificationRoute)
  }

  deleteTrackingNotification(idx: number) {
    this.notificationRecords.splice(idx, 1)
  }

  isDisableFromOption(fromIdx: string) {
    const keys = Object.keys(this.trackingPointDatasArr.find((tps) => fromIdx === tps.key)?.value.trackPointForms ?? {})
    const unavailToKeys = this.notificationRecords.filter((no) => no.from === fromIdx).map((no) => no.to)
    return isEmpty(keys) || !keys.find((k) => !unavailToKeys.includes(k))
  }

  isDisableToOption(fromIdx: string, toIdx: string) {
    const unavailToKeys = this.notificationRecords.filter((no) => no.from === fromIdx).map((no) => no.to)
    return unavailToKeys.includes(toIdx)
  }

  @Watch('trackingPointDatasArr')
  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  onFromChanged(nr: INotificationRoute) {
    const keys = this.loadTP(nr.from).map((tp) => tp.key)
    const unavailToKeys = this.notificationRecords.filter((no) => no.from === nr.from).map((no) => no.to)
    const remainKeys = keys.filter((k) => !unavailToKeys.includes(k))
    nr.to = !isEmpty(remainKeys) ? remainKeys[0] : ''
  }
}
