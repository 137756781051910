<template>
  <SvgBase iconName="IconBarcodeIsParent" :height="24" :width="24">
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="5.5"
        y="5.5"
        width="13"
        height="13"
        rx="1.1"
        stroke="black"
        stroke-linejoin="round"
        stroke-dasharray="1.2 1.2"
      />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="2.5"
        stroke="black"
        stroke-linejoin="round"
        stroke-dasharray="1 1"
      />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBarcodeIsNotParentNotChild extends Vue {}
</script>
