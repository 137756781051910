
import { Options } from 'vue-class-component'
import { maska } from 'maska'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import { Mixins, Watch } from 'vue-property-decorator'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { DELETE_USER_GROUP_PERMISSION } from '@/store/actions'
import {
  ICommonSearch,
  ITrackingPermissions,
  IUserGroupPermission,
  ICarrierProject,
  IUserGroupProjectVersion,
  IUserGroupProject,
  IPermission,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import UserGroupProjectPermissionForm from '@/components/carrier/UserGroupProjectPermissionForm.vue'
import { fetchProjectById } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: { ItemNotFound, PageHeader, UserGroupProjectPermissionForm },
  directives: { maska },
  name: 'UserGroupProjectPermission',
})
export default class UserGroupProjectPermission extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  selectedProjectCode = ''
  selectedProject = {} as IUserGroupProjectVersion
  selectedProjectId = ''
  isShowOnlyActive = true
  loading = false
  isShowTrackingPermissionDialog = false
  isAddTrackingPermission = true
  selectedPermision = {} as IPermission
  selectedTrackingPoints = {} as Record<string, ITrackpoint>

  get getItems(): IUserGroupPermission[] {
    const projects = this.$store.state.userGroup.projects as ICarrierProject[]
    const projectPermissionsStore: ITrackingPermissions = this.$store.state.userGroup.userGroup.projectPermissions
    const result = [] as IUserGroupPermission[]
    for (const key in projectPermissionsStore) {
      const filterProjects = projects.filter((item) => {
        return item.id === key
      })
      if (filterProjects && filterProjects.length === 1) {
        const trackingPermissions = projectPermissionsStore[key].trackingPermissions
        result.push({
          id: key,
          name: filterProjects[0].name,
          code: filterProjects[0].code,
          version: filterProjects[0].version,
          numberTrackPoint: Object.keys(trackingPermissions).length,
        } as IUserGroupPermission)
      }
    }
    return result
  }

  get getPermissionProjectIds(): string[] {
    return Object.keys(this.$store.state.userGroup.userGroup.projectPermissions)
  }

  get displayUserGroupProjects() {
    const projects = this.$store.state.userGroup.projects as ICarrierProject[]
    const userGroupProjects = {} as Record<string, IUserGroupProject>
    if (projects) {
      projects.forEach((item) => {
        if (!this.getPermissionProjectIds.includes(item.id)) {
          if (userGroupProjects?.[item.code]) {
            if (userGroupProjects[item.code].version < Number(item.version)) {
              userGroupProjects[item.code] = {
                name: item.name,
                code: item.code,
                version: Number(item.version),
              }
            }
          } else {
            userGroupProjects[item.code] = {
              name: item.name,
              code: item.code,
              version: Number(item.version),
            }
          }
        }
      })
    }
    const result = Object.values(userGroupProjects)
    result.sort((a: IUserGroupProject, b: IUserGroupProject) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    return result
  }

  get displayUserGroupProjectVersions() {
    const result = [] as IUserGroupProjectVersion[]
    this.selectedProjectId = ''

    if (this.selectedProjectCode) {
      const projects = this.$store.state.userGroup.projects as ICarrierProject[]
      if (projects) {
        projects.forEach((item) => {
          if (!this.getPermissionProjectIds.includes(item.id)) {
            if (item.code === this.selectedProjectCode) {
              result.push({
                id: item.id,
                code: item.code,
                name: item.name,
                version: Number(item.version),
              })
            }
          }
        })
      }
    }
    result.sort((a: IUserGroupProjectVersion, b: IUserGroupProjectVersion) =>
      a.version > b.version ? 1 : b.version > a.version ? -1 : 0
    )
    return result
  }

  get staffName() {
    return this.$store.state.userGroup?.userGroup?.name || ''
  }

  @Watch('getItems')
  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    const displayItems =
      this.getItems?.filter(
        (permission: IUserGroupPermission) =>
          this.cleanString(permission.name || '').includes(cleanSearchText) ||
          this.cleanString(permission.code || '').includes(cleanSearchText)
      ) || []

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  // async created() {
  //   this.loading = true
  //   await this.$store.dispatch(FETCH_USER_GROUP_PROJECTS).finally(() => {
  //     this.loading = false
  //   })
  //   this.filterItems()
  // }

  onEditPermission(item: IUserGroupPermission) {
    this.isAddTrackingPermission = false
    this.selectedProject = {
      id: item.id,
      name: item.name,
      code: item.code,
      version: Number(item.version),
    } as IUserGroupProjectVersion

    this.formatPermissionFormData()
  }

  closeTrackingPermissionDialog() {
    this.isShowTrackingPermissionDialog = false
    this.selectedTrackingPermisions = {}
  }

  deleteTrackingPermission() {
    this.$store.commit(DELETE_USER_GROUP_PERMISSION, this.selectedProject.id)
    this.closeTrackingPermissionDialog()
    this.filterItems()
  }

  closeModalAndClearSelectedProject(isRefreshList = false) {
    if (this.isAddTrackingPermission) {
      this.selectedProjectId = ''
      this.selectedProjectCode = ''
    }
    this.isShowTrackingPermissionDialog = false
    this.selectedProject = {} as IUserGroupProjectVersion
    this.selectedPermision = {} as IPermission
    this.selectedTrackingPoints = {} as Record<string, ITrackpoint>
    this.isAddTrackingPermission = true
    if (isRefreshList) {
      this.filterItems()
    }
  }

  async addNewProjectVersion() {
    this.isAddTrackingPermission = true
    const projectId = this.selectedProjectId
    this.displayUserGroupProjectVersions.forEach((item) => {
      if (item.id === projectId) {
        this.selectedProject = item
      }
    })

    this.formatPermissionFormData()
  }

  async formatPermissionFormData() {
    try {
      this.loading = true
      const responseData = await fetchProjectById(this.selectedProject.id)
      if (responseData) {
        this.selectedTrackingPoints = responseData?.project?.trackPoints || ({} as Record<string, ITrackpoint>)

        const projectPermissionsStore: ITrackingPermissions = this.$store.state.userGroup.userGroup.projectPermissions
        this.selectedPermision = projectPermissionsStore?.[this.selectedProject.id] ?? this.initPermissionData()

        this.isShowTrackingPermissionDialog = true
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  initPermissionData() {
    const trackingPermissions = {} as Record<string, ITrackpoint>
    for (const key in this.selectedTrackingPoints) {
      trackingPermissions[key] = {
        canEdit: true,
        canView: true,
        canDelete: true,
      } as ITrackpoint
    }

    return {
      trackingPermissions,
      activationDataPermission: {
        canEdit: true,
        canView: true,
      },
      generateBarcodePermission: {
        canEdit: true,
      },
    } as IPermission
  }
}
