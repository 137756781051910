
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import ParserSettingDialog from '@/components/project/dialogs/ParserSettingDialog.vue'
import { isImageFileField } from '@/utils/helpers'
import isEmpty from 'lodash/isEmpty'
import { EOCRReaderType, EReaderType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IActivationFields, IFieldOption, ITrackingNumber } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  components: { InputOnlyNumber, ParserSettingDialog },
  emits: ['update:modelValue'],
  name: 'ExternalIdBlock',
})
export default class ExternalIdBlock extends Vue {
  @PropSync('modelValue', { type: Object }) modelValue_!: ITrackingNumber
  @Prop({ type: Object, default: {} }) activationFields!: IActivationFields
  @Prop({ type: Boolean, default: false }) isDisabled!: boolean
  ocrReaderType = false
  barcodeReaderType = false

  isShowParserSettingDialog = false

  openParserSettingDialog() {
    this.isShowParserSettingDialog = true
  }

  get imageFileFields(): IFieldOption[] {
    return Object.entries(this.activationFields?.customFields ?? {})
      .filter((item) => isImageFileField(item[1]))
      .map(([k, val]) => ({ value: k, label: val.label }))
  }

  get hasImageFileSetting() {
    return !isEmpty(this.imageFileFields)
  }

  @Watch('activationFields', { deep: true })
  onActivationFieldsChange() {
    this.loadData()
    if (isEmpty(this.imageFileFields)) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get isDisableAutoSaveOCR() {
    if (!this.ocrReaderType) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
    return !this.ocrReaderType
  }

  onOCRReaderChange(val: boolean) {
    if (!val) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  @Watch('ocrReaderType')
  updateOcrReader() {
    this.modelValue_.ocrReaderType = this.ocrReaderType ? EOCRReaderType.DEFAULT : EOCRReaderType.UNKNOWN
  }

  @Watch('barcodeReaderType')
  updateBarcodeType() {
    this.modelValue_.barcodeReaderType = this.barcodeReaderType ? EReaderType.DEFAULT : EReaderType.UNKNOWN
  }

  @Watch('modelValue', { immediate: true })
  loadData() {
    this.ocrReaderType = this.modelValue_?.ocrReaderType !== EOCRReaderType.UNKNOWN
    this.barcodeReaderType = this.modelValue_?.barcodeReaderType !== EReaderType.UNKNOWN
  }
}
