<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="font-bold py-4">
      <h4>{{ $t('projects.trackingNumber') }}</h4>
    </div>
    <el-form :disabled="isReadOnlyMode || isDisabled">
      <div class="py-6">
        <div class="grid grid-cols-4 gap-x-4">
          <!-- displayName -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.displayName') }}
          </div>
          <div class="col-span-3">
            <el-input v-model="modelValue_.label" name="label" class="w-80 pb-6" />
          </div>

          <!-- defaultValue -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.defaultValue') }}
          </div>
          <div class="col-span-3">
            <el-input v-model="modelValue_.default" name="default" class="w-80 pb-6" />
          </div>

          <!-- allowableCharacterType -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.allowableCharacterType') }}
          </div>
          <div class="col-span-3">
            <div class="el-radio-group mt-2 pb-6">
              <el-radio
                v-for="(value, key) in characterTypes"
                :key="key"
                :id="key"
                @change="onChangeValidateType()"
                v-model="characterType"
                :label="value"
                >{{ $t('projects.' + key) }}</el-radio
              >
            </div>
          </div>

          <!-- additionalSymbols -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.additionalSymbols') }}
          </div>
          <div class="col-span-3">
            <div class="el-radio-group mt-2 pb-6">
              <el-form :disabled="characterType === characterTypes.notCheck || isReadOnlyMode || isDisabled">
                <el-radio
                  v-for="(value, key) in separatorTypes"
                  :key="key"
                  :id="key"
                  @change="onChangeValidateType()"
                  v-model="separatorType"
                  :label="value"
                  >{{ $t('projects.' + key) }}</el-radio
                >
              </el-form>
            </div>
          </div>

          <!-- trackingNumberData -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.characterLimit') }}
          </div>
          <div class="col-span-3 flex flex-wrap gap-3 items-center">
            <div class="mt-2 pb-6 flex items-center">
              <div class="w-44">
                <InputOnlyNumber
                  :min="0"
                  :max="modelValue_.maxLength"
                  v-model="modelValue_.minLength"
                  name="minLength"
                  :controls="true"
                  :precision="0"
                />
              </div>
              <span class="px-2"> 〜 </span>
              <div class="w-44">
                <InputOnlyNumber
                  :min="modelValue_.minLength + 1"
                  v-model="modelValue_.maxLength"
                  name="maxLength"
                  :controls="true"
                  :precision="0"
                />
              </div>
              <span class="px-2"> {{ $t('projects.letter') }} </span>
            </div>
          </div>

          <!-- Is unique -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.isUnique') }}
          </div>
          <div class="col-span-3 flex flex-wrap gap-3 items-center">
            <div class="mt-2 pb-6 flex items-center">
              <el-checkbox v-model="modelValue_.isUnique" name="isUnique">
                {{ $t('constrain') }}
              </el-checkbox>
            </div>
          </div>

          <!-- Input Support -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3">
            {{ $t('projects.input_support') }}
          </div>
          <div class="col-span-3">
            <div class="mt-2 pb-6">
              <el-checkbox @change="onOCRReaderChange" name="ocrReaderType" v-model="ocrReaderType">
                {{ $t('projects.ocrReaderType') }}
              </el-checkbox>
              <el-checkbox name="barcodeReaderType" v-model="barcodeReaderType" class="ml-4">
                {{ $t('projects.barcodeReaderType') }}
              </el-checkbox>
            </div>
          </div>
          <!-- Input Support -->
          <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3"></div>
          <div class="col-span-3">
            <div>
              {{ $t('projects.save_image_as_activation_after_ocr_manual') }}
            </div>
            <div class="mt-2 pb-6">
              <el-select
                :disabled="isDisableAutoSaveOCR || !hasImageFileSetting"
                v-model="modelValue_.ocrImageAutoSaveField"
              >
                <el-option :label="$t('projects.none')" :value="''">
                  {{ $t('projects.none') }}
                </el-option>
                <el-option v-for="item in imageFileFields" :key="item.value" :label="item.label" :value="item.value">
                  {{ item.label }}
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script lang="ts">
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import { EOCRReaderType, EReaderType, EValidationType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isImageFileField } from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IActivationFields, IFieldOption, ITrackingNumber } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'

export const CHARACTER_TYPES_OPTIONS: Record<string, string> = Object.freeze({
  letterDigitOnly: 'letterDigit',
  digitOnly: 'digit',
  letterOnly: 'letter',
  notCheck: '--',
})

export const ADDITIONAL_SYMBOLS_OPTIONS: Record<string, string> = Object.freeze({
  dash: 'Dash',
  underscore: 'Underscore',
  none: '--',
})

@Options({
  components: { InputOnlyNumber },
  emits: ['update:modelValue'],
  name: 'TrackingNumberBlock',
})
export default class TrackingNumberBlock extends Vue {
  @PropSync('modelValue', { type: Object }) modelValue_!: ITrackingNumber
  @Prop({ type: Object, default: {} }) activationFields!: IActivationFields
  @Prop({ type: Boolean, default: false }) isDisabled!: string
  allowUnmatchedTracking = false

  characterType = ''
  separatorType = ''

  ocrReaderType = false
  barcodeReaderType = false

  get imageFileFields(): IFieldOption[] {
    return Object.entries(this.activationFields?.customFields ?? {})
      .filter((item) => isImageFileField(item[1]))
      .map(([k, val]) => ({ value: k, label: val.label }))
  }

  get hasImageFileSetting() {
    return !isEmpty(this.imageFileFields)
  }

  @Watch('activationFields', { deep: true })
  onActivationFieldsChange() {
    this.loadingType()
    if (isEmpty(this.imageFileFields)) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get isDisableAutoSaveOCR() {
    if (!this.ocrReaderType) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
    return !this.ocrReaderType
  }

  onOCRReaderChange(val: boolean) {
    if (!val) {
      this.modelValue_.ocrImageAutoSaveField = ''
    }
  }

  get trackingNumberData(): ITrackingNumber {
    return this.value
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get characterTypes() {
    return CHARACTER_TYPES_OPTIONS
  }

  get separatorTypes() {
    return ADDITIONAL_SYMBOLS_OPTIONS
  }

  @Watch('modelValue', { immediate: true })
  loadingType() {
    const validationType: string = this.modelValue_?.validationType ?? ''

    this.characterType = this.characterTypes[
      Object.keys(this.characterTypes).find((k) => validationType.includes(this.characterTypes[k])) ?? 'notCheck'
    ]

    this.separatorType = this.separatorTypes[
      Object.keys(this.separatorTypes).find((k) => validationType.includes(this.separatorTypes[k])) ?? 'none'
    ]

    this.ocrReaderType = this.modelValue_?.ocrReaderType !== EOCRReaderType.UNKNOWN
    this.barcodeReaderType = this.modelValue_?.barcodeReaderType !== EReaderType.UNKNOWN
  }

  onChangeValidateType() {
    if (this.characterType === CHARACTER_TYPES_OPTIONS.notCheck) this.separatorType = ADDITIONAL_SYMBOLS_OPTIONS.none

    const temp = `${this.characterType}${this.separatorType}`.replaceAll('--', '')
    this.modelValue_ = {
      ...this.modelValue_,
      validationType: `${temp}${isEmpty(temp) ? 'none' : 'Only'}` as EValidationType,
    }
  }

  @Watch('ocrReaderType')
  updateOcrReader() {
    this.modelValue_.ocrReaderType = this.ocrReaderType ? EOCRReaderType.DEFAULT : EOCRReaderType.UNKNOWN
  }

  @Watch('barcodeReaderType')
  updateBarcodeType() {
    this.modelValue_.barcodeReaderType = this.barcodeReaderType ? EReaderType.DEFAULT : EReaderType.UNKNOWN
  }
}
</script>
