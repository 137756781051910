<template>
  <el-dialog
    v-model="modelValue_"
    custom-class="el-dialog--custom"
    width="650px"
    top="0"
    :title="$t('barcode_type.parser_test')"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    :show-close="true"
    @close="modelValue_ = false"
  >
    <div class="my-4">
      <div>
        <div class="grid grid-cols-12 items-center gap-6 mb-5">
          <div class="text-right col-span-3 whitespace-nowrap">
            {{ $t('barcode_type.parse_type') }}
          </div>
          <div class="col-span-9 flex flex-row">
            <el-input v-model="externalId" class="mr-2" />
            <el-button class="btn-light-blue rounded-md" @click="test">{{ $t('barcode_type.test') }}</el-button>
          </div>

          <template v-for="cf in parsableSettingFields" :key="cf.customFieldKey">
            <div class="text-right col-span-3 whitespace-nowrap">{{ cf.label }}</div>
            <template v-if="cf.type === ECustomFieldType.TEXT">
              <div class="col-span-9 flex flex-row">
                <el-input v-model="cfsModels[cf.key ?? '']" />
              </div>
            </template>
            <template v-if="cf.type === ECustomFieldType.DATE">
              <div class="col-span-9 flex flex-row">
                <el-input v-model="cfsModels[cf.key ?? '']" />
              </div>
            </template>
            <template v-if="cf.type === ECustomFieldType.EMAIL">
              <div class="col-span-9 flex flex-row">
                <el-input v-model="cfsModels[cf.key ?? '']" />
              </div>
            </template>
            <template v-if="cf.type === ECustomFieldType.PHONE_NUMBER">
              <div class="col-span-9 flex flex-row">
                <el-input v-model="cfsModels[cf.key ?? '']" @input="formatPhoneField($event, cf)">
                  <template #prepend>
                    <span class="mx-1"> {{ getCountryPhoneInfo(cf.countryCode).dialCode }}</span>
                  </template>
                </el-input>
              </div>
            </template>
            <template v-if="cf.type === ECustomFieldType.NUMBER">
              <div class="col-span-9 flex flex-row">
                <el-input v-model="cfsModels[cf.key ?? '']" />
              </div>
            </template>
            <template v-if="cf.type === ECustomFieldType.SINGLE_SELECT">
              <div class="col-span-9 flex flex-row">
                <el-radio-group v-model="cfsModels[cf.key ?? '']">
                  <el-radio v-for="(selection, idx) in cf.selections ?? {}" :key="idx" :value="idx" :label="idx">
                    {{ selection.label }}
                  </el-radio>
                </el-radio-group>
              </div>
            </template>
            <template v-if="cf.type === ECustomFieldType.MULTI_SELECT">
              <div class="col-span-9 flex flex-row">
                <el-checkbox-group v-model="cfsModels[cf.key ?? '']" size="small">
                  <el-checkbox v-for="(selection, idx) in cf.selections ?? {}" :key="idx" :value="idx" :label="idx">
                    {{ selection.label }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script lang="ts">
import isEmpty from 'lodash/isEmpty'
import { parseExternalId, TParsableField, TPhoneProps } from 'smartbarcode-web-core/src/bo/externalId'
import { maskPhoneNum } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums'
import { ICountryPhoneInfo, ICustomFieldFormData, TParserSettingItem } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

type TPhoneField = TParsableField & TPhoneProps

@Options({
  components: {},
  emits: ['update:modelValue', 'update:parserSetting'],
  name: 'TestParserDialog',
  methods: { isEmpty, maskPhoneNum },
})
export default class TestParserDialog extends Vue {
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @PropSync('modelValue', { type: Boolean }) modelValue_!: boolean
  @Prop({ type: Array, default: [] }) readonly settings: TParserSettingItem[] = []
  @Prop({ type: Array, default: [] }) readonly parsableFields: TParsableField[] = []

  ECustomFieldType = ECustomFieldType
  externalId = ''
  models = {} as Record<string, string | string[]>

  test() {
    const parsablePhoneFields = this.parsableSettingFields.filter(
      (f): f is TPhoneField => f.type === ECustomFieldType.PHONE_NUMBER
    )
    Object.entries(parseExternalId(this.externalId, this.parsableSettingFields, this.settings)).forEach(([k, v]) => {
      const phoneField = parsablePhoneFields.find((f) => f.key === k)
      if (phoneField) {
        this.formatPhoneField(v as string, phoneField)
      } else {
        this.models[k] = v
      }
    })
  }

  get parsableSettingFields(): TParsableField[] {
    const fieldPaths = this.settings.map((s) => s.targetFieldPath)
    return this.parsableFields.filter((f) => fieldPaths.includes(f.key))
  }

  get cfsModels() {
    this.parsableSettingFields.forEach(
      (f) => (this.models[f.key!] = f.type === ECustomFieldType.MULTI_SELECT ? [] : '')
    )
    return this.models
  }

  getCountryPhoneInfo(countryCode: string): ICountryPhoneInfo {
    return OTHER_COUNTRY_LIST[countryCode as keyof typeof OTHER_COUNTRY_LIST]
  }

  formatPhoneField(val: string, cf: TPhoneField) {
    this.cfsModels[cf.key] = maskPhoneNum(val, cf.countryCode)
  }

  created() {
    this.externalId = ''
    this.models = {} as Record<string, string | string[]>
  }
}
</script>
<style lang="scss" scoped>
@import './src/assets/css/mixins.scss';

:deep {
  .el-overlay .el-dialog {
    max-width: 650px;
  }
}
</style>
