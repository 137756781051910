<template>
  <div class="my-2 rounded-lg custom-field-bg-color">
    <div class="flex items-center gap-4 py-6 my-3 px-4">
      <div class="flex-1 font-medium">
        {{ $t('customFields.' + displayCustomFieldType) }}
      </div>
      <div class="flex-1">
        <div class="px-4 py-2 rounded-md border border-solid custom-field-texbox-border-color font-normal">
          {{ customField.label }}
        </div>
      </div>
      <div>
        <el-button type="primary" size="medium" @click="loadField">
          {{ $t('edit') }}
        </el-button>
      </div>
      <div>
        <el-button type="default" class="bg-red-min text-white focus:text-white" size="medium" @click="deleteField">
          {{ $t('delete') }}
        </el-button>
      </div>
      <div>
        <div class="flex items-center">
          <IconDraggable class="p-2" @mouseenter="_isCFDraggable = true" @mouseleave="_isCFDraggable = false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ICustomFieldFormData } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop, PropSync } from 'vue-property-decorator'
import TextboxUpdateDirectly from '@/components/validateFields/TextboxUpdateDirectly.vue'
import IconDraggable from '@/components/svg/IconDraggable.vue'
import { ECustomFieldExtraType, ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'

@Options({
  components: { TextboxUpdateDirectly, IconDraggable },
  emits: ['field:delete', 'field:load', 'update:isCFDraggable'],
  name: 'TrackingPointRelationshipField',
})
export default class TrackingPointRelationshipField extends Vue {
  @PropSync('isCFDraggable', { type: Boolean }) _isCFDraggable!: boolean
  @Prop({ type: Object }) readonly customField!: ICustomFieldFormData
  @Prop({ type: Number }) readonly index!: number

  get displayCustomFieldType(): string {
    if (!this.customField) return ''
    return this.customField.fieldType === ECustomFieldType.TEXT && this.customField.multiLine
      ? ECustomFieldExtraType.TEXT_AREA
      : this.customField.fieldType
  }

  @Emit('field:load')
  loadField() {
    return { ...this.customField, indexCustomField: this.index }
  }

  deleteField() {
    this.$emit('field:delete', this.customField.customFieldKey)
  }
}
</script>
