
import { IFieldOption } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'SelectAutoComplete',
})
export default class SelectAutocomplete extends Vue {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  @Prop({ type: Array }) readonly options!: IFieldOption[]
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: Boolean, default: false }) readonly isMedium!: boolean
  isFocus = false
  @Prop({ type: String, default: '' }) readonly placeholder!: string

  queryVariable(queryString: string, callback: Function) {
    this.isFocus = this.options.length > 0
    callback(this.options)
  }

  focusInput() {
    this.$refs.autocomplete.focus()
  }

  handleSelect() {
    this.isFocus = false
  }
}
