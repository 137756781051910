<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="grid grid-cols-5 items-center gap-4">
      <div class="col-span-2 font-bold">
        {{ $t('barcode_type.tracking') }}
      </div>
      <div class="col-span-3">
        <el-checkbox v-model="_modelValue">
          {{ $t('barcode_type.allow_delete_tracking') }}
        </el-checkbox>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],
  name: 'DeleteTrackingBlock',
})
export default class DeleteTrackingBlock extends Vue {
  @PropSync('modelValue', { type: Boolean, default: false }) _modelValue!: boolean
}
</script>
