<template>
  <SvgBase iconName="IconBarcodeIsParent" :height="24" :width="24">
    <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="2.25" stroke="black" stroke-width="1.5" />
    <rect
      x="5.5"
      y="5.5"
      width="13"
      height="13"
      rx="1.1"
      stroke="black"
      stroke-linejoin="round"
      stroke-dasharray="1.2 1.2"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBarcodeIsParent extends Vue {}
</script>
