<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <el-form :disabled="false">
      <div class="grid grid-cols-12 border-b">
        <div class="col-span-2 p-1">
          <div class="font-bold whitespace-nowrap">
            {{ $t('barcode_type.ui_config') }}
          </div>
        </div>
        <div class="col-span-10">
          <!-- Items -->
          <div class="grid grid-cols-7 items-center gap-2 pb-1 mb-8" v-for="(val, idx) in basicUIConfig" :key="idx">
            <div class="text-right col-span-2">{{ $t(`barcode_type.${val}`) }}</div>
            <div class="col-span-2">
              <el-radio-group v-model="_uiConfig[val]">
                <el-radio label="visible">
                  {{ $t('barcode_type.visible') }}
                </el-radio>
                <el-radio label="hidden">
                  {{ $t('barcode_type.hidden') }}
                </el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-5 gap-4">
        <div class="col-span-2 self-center text-right">
          {{ $t(`barcode.display_item_of_barcode_list`) }}
        </div>
        <!-- custom tools -->
        <ActivationDataSyntaxSelectBox
          :disabled="false"
          v-model="_uiConfig.listViewTitleLabelField"
          :excludedFieldType="excludedFieldType"
        />
      </div>
    </el-form>
  </div>
</template>
<script lang="ts">
import ActivationDataSyntaxSelectBox from '@/components/project/ActivationDataSyntaxSelectBox.vue'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { EDisplaySetting } from 'smartbarcode-web-core/src/utils/enums/index'
import { IUiConfig } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { ActivationDataSyntaxSelectBox },
  name: 'BarcodeUIConfig',
})
export default class BarcodeUIConfig extends Vue {
  @PropSync('uiConfig', { type: Object, default: {} }) _uiConfig!: IUiConfig

  basicUIConfig = [
    'qrCodeVisibility',
    'trackpointFlowchartVisibility',
    'tracingVisibility',
    'parentBarcodeVisibility',
    'childrenBarcodeVisibility',
    'previewAddTrackingVisiblity',
  ]

  defaultOptions: string[] = ['visible', 'visibleWhenLoggedIn', 'hidden']
  excludedFieldType = [FIELD_TYPE.ESIGN, FIELD_TYPE.LOCATION, FIELD_TYPE.FILES]

  @Watch('_uiConfig')
  formatData() {
    this._uiConfig.qrCodeVisibility =
      this._uiConfig.qrCodeVisibility === EDisplaySetting.UNKNOWN
        ? EDisplaySetting.VISIBLE
        : this._uiConfig.qrCodeVisibility
  }
}
</script>
