<template>
  <div class="el-form-item mb-0" :class="{ error: !!fieldValidate.errorMessage }">
    <div class="el-form-item__content">
      <el-input
        v-if="validateInput"
        :name="name"
        :id="name"
        :type="type"
        size="medium"
        v-model="fieldValidate.inputValue"
        @blur="handleInput"
        @input="validateInput"
      />
      <el-input
        v-else
        :name="name"
        :id="name"
        :type="type"
        size="medium"
        v-model="fieldValidate.inputValue"
        @blur="handleInput"
      />
      <p class="el-form-item__error" v-show="isShowError && fieldValidate.errorMessage">
        {{ fieldValidate.errorMessage }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'

import TextboxMixin from '@/components/mixins/TextboxMixin.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'TextboxUpdateDirectly',
})
export default class TextboxUpdateDirectly extends mixins(TextboxMixin) {
  @Prop({ type: Function }) readonly validateInput?: Function
}
</script>
