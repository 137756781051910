<template>
  <div class="relative mt-5 p-5 border-b border-gray-400 border-solid py-8">
    <div class="flex" style="place-content: space-between">
      <div class="font-bold">
        {{ $t('connect_external_web_api') }}
      </div>
      <el-checkbox class="font-normal" @change="onToggleExternalWebAPI" v-model="isEnableExternalWebAPI">
        {{ $t('exportable_from_web_api') }}
      </el-checkbox>
    </div>
    <div class="mt-5">
      <el-form
        ref="ruleFormRef"
        :model="syncedModelValue"
        :rules="rules"
        :disabled="isReadOnly || !isEnableExternalWebAPI"
      >
        <div>
          <div class="grid grid-cols-4 gap-x-4">
            <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3 font-normal">
              {{ $t('connect_url_address') }}
            </div>
            <div class="col-span-3 flex flex-wrap gap-3 items-center">
              <el-form-item class="w-full" prop="endpointUrl">
                <el-input placeholder="https://..." v-model="endPointURL" name="label" class="w-full" />
              </el-form-item>
            </div>
            <div
              class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3 font-normal"
              style="margin-bottom: 20px"
            >
              {{ $t('button_label') }}
            </div>
            <div class="col-span-3 flex flex-wrap gap-3 items-center">
              <el-form-item prop="buttonLabel">
                <el-input v-model="buttonLabel" name="label" class="w-80" />
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
      <div>
        <span> {{ $t('request_json') }}</span>
      </div>
      <div class="mt-2">
        <RichTextEditor
          v-model="value"
          :isShowCustomFields="true"
          :editorHeight="'S'"
          :disabled="!isEnableExternalWebAPI"
          :showToolbar="false"
          :isJsonContentMode="true"
          :customFields="customFields"
          @update:isJsonValid="onJsonValidate"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { DEFAULT_BARCODE_TYPES } from '@/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IExternalWebAPIIntegration } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
import RichTextEditor from '../RichTextEditor.vue'
@Options({
  emits: ['update:modelValue'],
  components: { RichTextEditor },
  name: 'ExternalWebApiBlock',
})
export default class ExternalWebApiBlock extends mixins(ValidateForm) {
  @Prop({ type: Array }) readonly customFields?: []
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @PropSync('modelValue', {
    type: Object,
  })
  _syncedModelValue?: IExternalWebAPIIntegration

  isJSonValid = true

  rules = {
    endpointUrl: [{ required: this.isRequiredEndPointURL, validator: this.requiredURLValidate, trigger: 'blur' }],
    buttonLabel: [{ required: this.isRequiredEndPointURL, validator: this.requiredValidate, trigger: 'blur' }],
  }

  get syncedModelValue() {
    if (!this._syncedModelValue) this._syncedModelValue = this.getDefaultExternalWebAPIIntegration()
    return this._syncedModelValue
  }

  set syncedModelValue(val: IExternalWebAPIIntegration) {
    this._syncedModelValue = this._syncedModelValue ? val : this.getDefaultExternalWebAPIIntegration()
  }

  getDefaultExternalWebAPIIntegration() {
    return cloneDeep(DEFAULT_BARCODE_TYPES.default.externalWebAPIIntegration) as IExternalWebAPIIntegration
  }

  get isRequiredEndPointURL() {
    return this.isEnableExternalWebAPI || false
  }

  get endPointURL() {
    return this.syncedModelValue?.endpointUrl || ''
  }

  set endPointURL(val: string) {
    if (this.syncedModelValue) {
      this.syncedModelValue.endpointUrl = val
    } else {
      Object.assign(this.syncedModelValue, { endpointUrl: val })
    }
  }

  get buttonLabel() {
    return this.syncedModelValue?.buttonLabel || ''
  }

  set buttonLabel(val: string) {
    if (this.syncedModelValue) {
      this.syncedModelValue.buttonLabel = val
    } else {
      Object.assign(this.syncedModelValue, { buttonLabel: val })
    }
  }

  isEnableExternalWebAPI = false

  checkValueObjectEmpty(obj: IExternalWebAPIIntegration) {
    return isEmpty(obj) && Object.entries(obj).every((val) => isEmpty(val[1]))
  }

  @Watch('syncedModelValue')
  onChanged() {
    this.isEnableExternalWebAPI = !this.checkValueObjectEmpty(this.syncedModelValue)
  }

  onToggleExternalWebAPI(val: boolean) {
    if (val) return

    // form reset
    if (!this.$refs.ruleFormRef) return
    this.$refs.ruleFormRef.resetFields()

    this.syncedModelValue = this.getDefaultExternalWebAPIIntegration()
  }

  onJsonValidate(isJSonValid: boolean) {
    this.isJSonValid = isJSonValid
  }

  get value() {
    if (!this.syncedModelValue) return ''
    return JSON.stringify(this.syncedModelValue.requestBody, null, '\t')
  }

  set value(val: string) {
    try {
      if (!this.syncedModelValue) return
      this.syncedModelValue.requestBody = JSON.parse(val)
    } catch (error) {}
  }
}
</script>
