<template>
  <SvgBase iconName="IconUserGroup" :width="32" :height="24">
    <path
      d="M14 24C14 24 12 24 12 22C12 20 14 14 22 14C30 14 32 20 32 22C32 24 30 24 30 24H14ZM22 12C23.5913 12 25.1174 11.3679 26.2426 10.2426C27.3679 9.11742 28 7.5913 28 6C28 4.4087 27.3679 2.88258 26.2426 1.75736C25.1174 0.632141 23.5913 0 22 0C20.4087 0 18.8826 0.632141 17.7574 1.75736C16.6321 2.88258 16 4.4087 16 6C16 7.5913 16.6321 9.11742 17.7574 10.2426C18.8826 11.3679 20.4087 12 22 12Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.432 24.0009C10.1355 23.3765 9.98765 22.6919 10 22.0009C10 19.2909 11.36 16.5009 13.872 14.5609C12.6182 14.1745 11.3119 13.9856 10 14.0009C2 14.0009 0 20.0009 0 22.0009C0 24.0009 2 24.0009 2 24.0009H10.432Z"
      fill="black"
    />
    <path
      d="M9 12C10.3261 12 11.5979 11.4732 12.5355 10.5355C13.4732 9.59785 14 8.32608 14 7C14 5.67392 13.4732 4.40215 12.5355 3.46447C11.5979 2.52678 10.3261 2 9 2C7.67392 2 6.40215 2.52678 5.46447 3.46447C4.52678 4.40215 4 5.67392 4 7C4 8.32608 4.52678 9.59785 5.46447 10.5355C6.40215 11.4732 7.67392 12 9 12Z"
      fill="black"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconUserGroup extends Vue {}
</script>
