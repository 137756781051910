<template>
  <div class="border p-5 border-solid border-gray-400 rounded-lg">
    <!-- Custom fields list -->
    <div class="flex items-center gap-4">
      <div class="flex-grow font-bold">
        {{ $t('barcode_type.trackpoint_restrict_route') }}
      </div>
      <div>
        <el-button type="primary" icon="el-icon-plus" size="medium" @click="onAddRestrictRule">
          {{ $t('barcode_type.add_new_route') }}
        </el-button>
      </div>
    </div>
    <div v-for="(restrictRoute, idx) in _restrictedTrackPointRoutePath" :key="idx">
      <div class="flex items-center my-4 gap-4 justify-center">
        <div class="flex items-center w-9/12 justify-evenly">
          <el-select
            :placeholder="$t('barcode_type.select')"
            class="mx-2"
            @change="onTrackpointRestrictChange(restrictRoute)"
            v-model="restrictRoute.from"
            :key="restrictRoute.from"
          >
            <el-option
              v-for="trackPoint in trackingPointDatasRestrictable(restrictRoute, idx)"
              :key="`${trackPoint?.value?.name} (${trackPoint?.key})`"
              :label="`${trackPoint?.value?.name} (${trackPoint?.key})`"
              :value="trackPoint?.key"
              :disabled="isDisableStartRestrictRoute(trackPoint)"
            />
          </el-select>
          <i class="el-icon-right mx-2"></i>
          <el-select
            :placeholder="$t('barcode_type.select')"
            class="mx-2"
            v-model="restrictRoute.to"
            :key="restrictRoute.to"
          >
            <el-option
              v-for="item in getNextTrackpointsForm(restrictRoute.from)"
              :disabled="isDisableEndRestrictRoute(item, restrictRoute.from)"
              :key="`${item?.value?.name} (${item?.key})`"
              :label="`${item?.value?.name} (${item?.key})`"
              :value="item?.key"
            />
          </el-select>
          <div>
            <el-checkbox class="mx-2" v-model="restrictRoute.allowChildrenToBypassRestriction">
              {{ $t('barcode_type.not_restrict_children_barcode') }}
            </el-checkbox>
          </div>
          <div>
            <el-button class="mx-6" type="danger" @click="deleteRoutePath(idx)" size="medium">
              {{ $t('barcode_type.delete') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { IRestrictedTrackPointRoutePath, ITrackPointForms, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import isEmpty from 'lodash/isEmpty'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  name: 'RestrictionRouteBlock',
})
export default class RestrictionRouteBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @PropSync('trackPointRouteRestrictedPaths', { type: Array, default: [] })
  _restrictedTrackPointRoutePath!: IRestrictedTrackPointRoutePath[]

  @Prop({ type: Array }) readonly overridableStartTrackPoints?: string[]

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this._restrictedTrackPointRoutePath.forEach((val, idx) => {
      const found = this.getNextTrackpointsForm(val.from).find((endTPRoute) => endTPRoute.key === val.to)
      if (!found && val.to) this.deleteRoutePath(idx)
    })
  }

  deleteRoutePath(idx: number) {
    this._restrictedTrackPointRoutePath.splice(idx, 1)
  }

  isDisableEndRestrictRoute(tp: ITrackPointKeyVal, startRestrictedRoute: string) {
    return !!this._restrictedTrackPointRoutePath.find((v) => v.from === startRestrictedRoute && v.to === tp.key)
  }

  isDisableStartRestrictRoute(trackpoint: ITrackPointKeyVal) {
    return (
      (this.overridableStartTrackPoints?.includes(trackpoint.key) && !this.isMultipleTrackPoint(trackpoint.key)) ||
      isEmpty(this.getEndRestrictRouteAvailable(trackpoint.key))
    )
  }

  getEndRestrictRouteAvailable(startRestrictedKey: string) {
    const found = this._restrictedTrackPointRoutePath.filter((v) => v.from === startRestrictedKey)
    return this.getNextTrackpointsForm(startRestrictedKey).filter((o) => !found.find((v) => v.to === o.key))
  }

  isMultipleTrackPoint(key: string) {
    return Object.keys(this.trackingPointDatasArr.find((o) => o.key === key)?.value.trackPointForms || {}).length > 1
  }

  onTrackpointRestrictChange(restrictRoute: IRestrictedTrackPointRoutePath) {
    restrictRoute.to = this.getEndRestrictRouteAvailable(restrictRoute.from)?.[0]?.key || ''
  }

  @Watch('trackingPointDatasArr')
  trackingPointDatasRestrictable(restrictRoute: IRestrictedTrackPointRoutePath, index: number) {
    let isFound = false
    const restrictableArray = this.trackingPointDatasArr.filter((val) => {
      if (val.key === restrictRoute.from) isFound = true
      return !val.value.isEnd
    })
    // if (!isFound && this._restrictedTrackPointRoutePath[index]) this._restrictedTrackPointRoutePath[index].from = ''

    return restrictableArray
  }

  @Watch('trackingPointDatasArr')
  getNextTrackpointsForm(key: string) {
    return objectToArray<ITrackPointForms>(
      this.trackingPointDatasArr.find((tp) => tp.key === key)?.value.trackPointForms || {}
    ).map((val) => ({
      key: val.key,
      value: {
        ...val.value,
        name: this.trackingPointDatasArr.find((tp) => tp.key === val.key)?.value.name,
      },
    }))
  }

  onAddRestrictRule() {
    this._restrictedTrackPointRoutePath = this._restrictedTrackPointRoutePath ?? []
    this._restrictedTrackPointRoutePath.push({
      from: '',
      to: '',
      allowChildrenToBypassRestriction: true,
    })
  }
}
</script>
