<template>
  <div class="h-full w-full" v-loading.fullscreen="loading">
    <div class="">
      <div class="mt-2 flex justify-end items-center">
        <div class="w-fit mr-3">
          <label>{{ $t('Projects') }}</label>
        </div>
        <div class="w-52 mr-3">
          <el-select v-model="selectedProjectCode" :placeholder="$t('selectOne')" class="w-full" filterable>
            <el-option value="" :label="$t('selectOne')">
              {{ $t('selectOne') }}
            </el-option>
            <el-option
              v-for="(value, key) in displayUserGroupProjects"
              :key="key"
              :label="value.name"
              :value="value.code"
            >
              {{ `${value.name} (${value.code})` }}
            </el-option>
          </el-select>
        </div>
        <div class="mr-3">
          <el-select v-model="selectedProjectId" :placeholder="$t('selectOne')" class="w-full" filterable>
            <el-option value="" :label="$t('selectOne')">
              {{ $t('selectOne') }}
            </el-option>
            <el-option
              v-for="(value, key) in displayUserGroupProjectVersions"
              :key="key"
              :label="value.version"
              :value="value.id"
            >
              {{ value.version }}
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          :disabled="selectedProjectCode === '' || selectedProjectId === ''"
          @click="addNewProjectVersion"
          class="mr-8"
          >{{ $t('add') }}</el-button
        >
      </div>
      <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
        <div class="mt-6 mb-16 flex-grow">
          <el-table
            :data="displayItems"
            class="rounded-xl w-full"
            header-cell-class-name="custom-table__header-cell"
            cell-class-name="custom-table__cell cursor-pointer"
            border
            :empty-text="$t('empty')"
            highlight-current-row
            @row-click="onEditPermission"
          >
            <el-table-column prop="code" :label="$t('projects.projectCode')" :min-width="30" />
            <el-table-column prop="name" :label="$t('projects.projectName')" :min-width="30" />
            <el-table-column prop="numberTrackPoint" :label="$t('carrier.number_track_points')" :min-width="20" />
            <el-table-column prop="version" :label="$t('projects.version')" :min-width="20" />
          </el-table>
        </div>
        <div class="justify-center flex flex-row mb-16">
          <el-pagination
            layout="prev, pager, next"
            :total="totalPaging"
            :page-size="itemsPerPage"
            :current-page="currentPageNum"
            @current-change="currentPageChange($event, false)"
            background
            class="custom-pagination"
          />
        </div>
      </div>
      <template v-else>
        <ItemNotFound class="mt-56" :content="$t('empty')" />
      </template>
    </div>
  </div>
  <el-dialog
    v-model="isShowTrackingPermissionDialog"
    custom-class="dialog-user-group-project-permission"
    width="70%"
    :destroy-on-close="true"
    @close="closeTrackingPermissionDialog"
  >
    <template #title>
      <div class="flex items-center justify-between pr-12">
        <h4 class="w-fit">
          {{ $t('permissions.setting') }} | {{ staffName }} | {{ selectedProject?.name }} ver
          {{ selectedProject?.version }}
        </h4>
        <el-button
          type="default"
          class="bg-red-min text-white focus:text-white"
          size="medium"
          @click="deleteTrackingPermission"
          v-if="!isAddTrackingPermission"
        >
          {{ $t('delete') }}
        </el-button>
      </div>
    </template>
    <UserGroupProjectPermissionForm
      :permissionData="selectedPermision"
      :trackPoints="selectedTrackingPoints"
      :projectId="selectedProject.id"
      @close:modal="closeModalAndClearSelectedProject"
    />
  </el-dialog>
</template>

<script lang="ts">
import { Options } from 'vue-class-component'
import { maska } from 'maska'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import { Mixins, Watch } from 'vue-property-decorator'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { DELETE_USER_GROUP_PERMISSION } from '@/store/actions'
import {
  ICommonSearch,
  ITrackingPermissions,
  IUserGroupPermission,
  ICarrierProject,
  IUserGroupProjectVersion,
  IUserGroupProject,
  IPermission,
  ITrackpoint,
} from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import UserGroupProjectPermissionForm from '@/components/carrier/UserGroupProjectPermissionForm.vue'
import { fetchProjectById } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'

@Options({
  components: { ItemNotFound, PageHeader, UserGroupProjectPermissionForm },
  directives: { maska },
  name: 'UserGroupProjectPermission',
})
export default class UserGroupProjectPermission extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  selectedProjectCode = ''
  selectedProject = {} as IUserGroupProjectVersion
  selectedProjectId = ''
  isShowOnlyActive = true
  loading = false
  isShowTrackingPermissionDialog = false
  isAddTrackingPermission = true
  selectedPermision = {} as IPermission
  selectedTrackingPoints = {} as Record<string, ITrackpoint>

  get getItems(): IUserGroupPermission[] {
    const projects = this.$store.state.userGroup.projects as ICarrierProject[]
    const projectPermissionsStore: ITrackingPermissions = this.$store.state.userGroup.userGroup.projectPermissions
    const result = [] as IUserGroupPermission[]
    for (const key in projectPermissionsStore) {
      const filterProjects = projects.filter((item) => {
        return item.id === key
      })
      if (filterProjects && filterProjects.length === 1) {
        const trackingPermissions = projectPermissionsStore[key].trackingPermissions
        result.push({
          id: key,
          name: filterProjects[0].name,
          code: filterProjects[0].code,
          version: filterProjects[0].version,
          numberTrackPoint: Object.keys(trackingPermissions).length,
        } as IUserGroupPermission)
      }
    }
    return result
  }

  get getPermissionProjectIds(): string[] {
    return Object.keys(this.$store.state.userGroup.userGroup.projectPermissions)
  }

  get displayUserGroupProjects() {
    const projects = this.$store.state.userGroup.projects as ICarrierProject[]
    const userGroupProjects = {} as Record<string, IUserGroupProject>
    if (projects) {
      projects.forEach((item) => {
        if (!this.getPermissionProjectIds.includes(item.id)) {
          if (userGroupProjects?.[item.code]) {
            if (userGroupProjects[item.code].version < Number(item.version)) {
              userGroupProjects[item.code] = {
                name: item.name,
                code: item.code,
                version: Number(item.version),
              }
            }
          } else {
            userGroupProjects[item.code] = {
              name: item.name,
              code: item.code,
              version: Number(item.version),
            }
          }
        }
      })
    }
    const result = Object.values(userGroupProjects)
    result.sort((a: IUserGroupProject, b: IUserGroupProject) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
    return result
  }

  get displayUserGroupProjectVersions() {
    const result = [] as IUserGroupProjectVersion[]
    this.selectedProjectId = ''

    if (this.selectedProjectCode) {
      const projects = this.$store.state.userGroup.projects as ICarrierProject[]
      if (projects) {
        projects.forEach((item) => {
          if (!this.getPermissionProjectIds.includes(item.id)) {
            if (item.code === this.selectedProjectCode) {
              result.push({
                id: item.id,
                code: item.code,
                name: item.name,
                version: Number(item.version),
              })
            }
          }
        })
      }
    }
    result.sort((a: IUserGroupProjectVersion, b: IUserGroupProjectVersion) =>
      a.version > b.version ? 1 : b.version > a.version ? -1 : 0
    )
    return result
  }

  get staffName() {
    return this.$store.state.userGroup?.userGroup?.name || ''
  }

  @Watch('getItems')
  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    const displayItems =
      this.getItems?.filter(
        (permission: IUserGroupPermission) =>
          this.cleanString(permission.name || '').includes(cleanSearchText) ||
          this.cleanString(permission.code || '').includes(cleanSearchText)
      ) || []

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  // async created() {
  //   this.loading = true
  //   await this.$store.dispatch(FETCH_USER_GROUP_PROJECTS).finally(() => {
  //     this.loading = false
  //   })
  //   this.filterItems()
  // }

  onEditPermission(item: IUserGroupPermission) {
    this.isAddTrackingPermission = false
    this.selectedProject = {
      id: item.id,
      name: item.name,
      code: item.code,
      version: Number(item.version),
    } as IUserGroupProjectVersion

    this.formatPermissionFormData()
  }

  closeTrackingPermissionDialog() {
    this.isShowTrackingPermissionDialog = false
    this.selectedTrackingPermisions = {}
  }

  deleteTrackingPermission() {
    this.$store.commit(DELETE_USER_GROUP_PERMISSION, this.selectedProject.id)
    this.closeTrackingPermissionDialog()
    this.filterItems()
  }

  closeModalAndClearSelectedProject(isRefreshList = false) {
    if (this.isAddTrackingPermission) {
      this.selectedProjectId = ''
      this.selectedProjectCode = ''
    }
    this.isShowTrackingPermissionDialog = false
    this.selectedProject = {} as IUserGroupProjectVersion
    this.selectedPermision = {} as IPermission
    this.selectedTrackingPoints = {} as Record<string, ITrackpoint>
    this.isAddTrackingPermission = true
    if (isRefreshList) {
      this.filterItems()
    }
  }

  async addNewProjectVersion() {
    this.isAddTrackingPermission = true
    const projectId = this.selectedProjectId
    this.displayUserGroupProjectVersions.forEach((item) => {
      if (item.id === projectId) {
        this.selectedProject = item
      }
    })

    this.formatPermissionFormData()
  }

  async formatPermissionFormData() {
    try {
      this.loading = true
      const responseData = await fetchProjectById(this.selectedProject.id)
      if (responseData) {
        this.selectedTrackingPoints = responseData?.project?.trackPoints || ({} as Record<string, ITrackpoint>)

        const projectPermissionsStore: ITrackingPermissions = this.$store.state.userGroup.userGroup.projectPermissions
        this.selectedPermision = projectPermissionsStore?.[this.selectedProject.id] ?? this.initPermissionData()

        this.isShowTrackingPermissionDialog = true
      }
    } catch (error) {
      errorHandler(error)
    } finally {
      this.loading = false
    }
  }

  initPermissionData() {
    const trackingPermissions = {} as Record<string, ITrackpoint>
    for (const key in this.selectedTrackingPoints) {
      trackingPermissions[key] = {
        canEdit: true,
        canView: true,
        canDelete: true,
      } as ITrackpoint
    }

    return {
      trackingPermissions,
      activationDataPermission: {
        canEdit: true,
        canView: true,
      },
      generateBarcodePermission: {
        canEdit: true,
      },
    } as IPermission
  }
}
</script>
<style lang="scss" scoped></style>
