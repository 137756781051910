<template>
  <el-autocomplete
    v-model="_modelValue"
    :fetch-suggestions="queryVariable"
    class="w-full el-select"
    :placeholder="placeholder || $t('barcode_type.select')"
    icon="edit"
    @blur="isFocus = false"
    @select="handleSelect"
    :size="isMedium ? 'medium' : ''"
    ref="autocomplete"
  >
    <template #suffix>
      <i
        :class="`el-select__caret el-input__icon el-icon-arrow-up ${isFocus ? 'is-reverse' : ''}`"
        @click="focusInput"
      />
    </template>
    <template #default="{ item }">
      <div>{{ item.label }}</div>
    </template>
  </el-autocomplete>
</template>

<script lang="ts">
import { IFieldOption } from 'smartbarcode-web-core/src/utils/types/index'
import { Vue, Options } from 'vue-class-component'
import { Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'SelectAutoComplete',
})
export default class SelectAutocomplete extends Vue {
  @PropSync('modelValue', { type: String }) _modelValue?: string
  @Prop({ type: Array }) readonly options!: IFieldOption[]
  @Prop({ type: String }) readonly name!: string
  @Prop({ type: Boolean, default: false }) readonly isMedium!: boolean
  isFocus = false
  @Prop({ type: String, default: '' }) readonly placeholder!: string

  queryVariable(queryString: string, callback: Function) {
    this.isFocus = this.options.length > 0
    callback(this.options)
  }

  focusInput() {
    this.$refs.autocomplete.focus()
  }

  handleSelect() {
    this.isFocus = false
  }
}
</script>
