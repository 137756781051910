<template>
  <SvgBase iconName="Draggable" :width="15" :height="10">
    <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line y1="1" x2="15" y2="1" :stroke="color" stroke-width="2" />
      <line y1="5" x2="15" y2="5" :stroke="color" stroke-width="2" />
      <line y1="9" x2="15" y2="9" :stroke="color" stroke-width="2" />
    </svg>
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconDraggable extends Vue {}
</script>
