
import { Vue, Options } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],
  name: 'DeleteTrackingBlock',
})
export default class DeleteTrackingBlock extends Vue {
  @PropSync('modelValue', { type: Boolean, default: false }) _modelValue!: boolean
}
