<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="font-bold py-4">
      {{ $t('barcode_type.predefined_contacts') }}
    </div>
    <div class="py-4">
      <div class="grid grid-cols-4 gap-x-4">
        <!-- =================================== EMAILS ==========================================-->
        <div class="border-r border-solid border-gray-600 flex justify-end pt-3 pr-3 pb-6">
          {{ $t('email_address') }}
        </div>
        <div class="col-span-3 pb-6">
          <div v-for="(email, idx) in predefinedContacts.emails" :key="idx">
            <el-input v-model="predefinedContacts.emails[idx]" class="w-80" />
            <span class="mx-2">
              <el-button
                v-if="predefinedContacts.emails.length > 1"
                @click="removeEmail(idx)"
                type="info"
                icon="el-icon-minus"
                size="small"
                circle
                class="mx-2"
              />
              <el-button
                v-if="idx === predefinedContacts.emails.length - 1"
                @click="addEmail"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                class="mx-2"
              />
            </span>
          </div>
        </div>
        <!-- =================================== PHONE NUMBERS ==========================================-->
        <div class="border-r border-solid border-gray-600 flex justify-end pt-6 pr-3">
          {{ $t('barcode_type.phone_number') }}
        </div>
        <div class="col-span-3 pt-4 pb-2">
          <div v-for="(phoneNo, idx) in predefinedContacts.numberContacts" :key="idx" class="flex items-center mb-3">
            <div>
              <div>
                <!-- <el-input class="w-20" v-model="predefinedContacts.numberContacts[idx].countryCode" /> -->
                <el-select
                  :placeholder="$t('barcode_type.select')"
                  v-model="predefinedContacts.numberContacts[idx].countryCode"
                  filterable
                  style="width: 122px;"
                  @change="onSelectedCountryChanged(idx)"
                >
                  <el-option-group
                    v-for="(group, groupKey) in countryList"
                    :key="groupKey"
                    :label="$t(`countryGroup.${groupKey}`)"
                  >
                    <el-option v-for="(country, key) in group" :key="key" :value="key" :label="getCountryName(country)">
                      {{ getCountryName(country) }}
                    </el-option>
                  </el-option-group>
                </el-select>
                <el-input style="width: 198px" v-model="predefinedContacts.numberContacts[idx].number" />
              </div>
              <div>
                <el-input class="w-80" v-model="predefinedContacts.numberContacts[idx].name" />
              </div>
            </div>
            <div class="mx-2">
              <el-button
                v-if="predefinedContacts.numberContacts.length > 1"
                @click="removePhoneNo(idx)"
                type="info"
                icon="el-icon-minus"
                size="small"
                circle
                class="mx-2"
              />
              <el-button
                v-if="idx === predefinedContacts.numberContacts.length - 1"
                @click="addPhoneNo"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                class="mx-2"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { ICountryCode, IPredefinedContact } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],
  name: 'PredefinedContactsBlock',
})
export default class PredefinedContactsBlock extends Vue {
  @PropSync('modelValue', { type: Object }) modelValue_!: IPredefinedContact

  predefinedContacts = {} as IPredefinedContact

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST as Record<string, ICountryCode>,
      allCountries: OTHER_COUNTRY_LIST as Record<string, ICountryCode>,
    }
  }

  getCountryName(country: ICountryCode) {
    return `${this.$t(`countryList.${country.code}`)}${country.dialCode ? ` (${country.dialCode})` : ''}`
  }

  onSelectedCountryChanged(idx: number) {
    this.predefinedContacts.numberContacts[idx].number = ''
  }

  @Watch('modelValue_')
  modelValueChanged() {
    const cleanModelValue = this.cleanPredefinedContacts(this.modelValue_)
    const cleanPredefinedContacts = this.cleanPredefinedContacts(this.predefinedContacts)

    if (!isEqual(cleanModelValue, cleanPredefinedContacts)) this.predefinedContacts = cloneDeep(this.modelValue_)

    if (isEmpty(this.predefinedContacts.emails)) this.predefinedContacts.emails = ['']
    if (isEmpty(this.predefinedContacts.numberContacts)) {
      this.predefinedContacts.numberContacts = [{ name: '', number: '', countryCode: 'JP' }]
    }
  }

  cleanPredefinedContacts(predefinedContacts: IPredefinedContact) {
    return {
      emails: (predefinedContacts.emails ?? []).filter((i) => !isEmpty(i.replaceAll(' ', ''))),
      numberContacts: (predefinedContacts.numberContacts ?? []).filter(
        (i) => !isEmpty(i.name.replaceAll(' ', '')) && !isEmpty(i.number.replaceAll(' ', ''))
      ),
    }
  }

  @Watch('predefinedContacts', { deep: true })
  predefinedContactsChanged() {
    this.debouncedWatch()
  }

  addEmail() {
    this.predefinedContacts.emails.push('')
  }

  removeEmail(idx: number) {
    this.predefinedContacts.emails.splice(idx, 1)
  }

  addPhoneNo() {
    this.predefinedContacts.numberContacts.push({ name: '', number: '', countryCode: 'JP' })
  }

  removePhoneNo(idx: number) {
    this.predefinedContacts.numberContacts.splice(idx, 1)
  }

  created() {
    this.debouncedWatch = debounce(() => {
      this.modelValue_ = cloneDeep(this.cleanPredefinedContacts(this.predefinedContacts))
    }, 1000)
  }
}
</script>
