<template>
  <div class="py-8 relative border-b border-gray-400 border-solid">
    <div class="absolute font-bold">
      {{ $t('base_setting') }}
    </div>

    <!-- name -->
    <div class="grid grid-cols-5 gap-4 items-center mb-5">
      <div class="col-span-2 text-right">
        {{ $t('barcode_type.barcode_name') }}
      </div>
      <div class="col-span-3">
        <el-input
          :maxlength="30"
          class="lg:w-1/2"
          :placeholder="$t('barcode_type.barcode_name')"
          v-model="_selectedBarcodeType.name"
          ref="barcodeTypeName"
        />
      </div>
    </div>

    <!-- code -->
    <div class="grid grid-cols-5 gap-4 items-center mb-5">
      <div class="col-span-2 text-right"><span class="text-red-500">* </span>{{ $t('barcode_type.code') }}</div>
      <div class="col-span-3">
        <div class="lg:w-1/2">
          <BaseInput
            :placeholder="$t('barcode_type.code')"
            v-model="_barcodeTypeCode"
            :isShowLimit="true"
            :minLength="3"
            :maxLength="64"
            @input="validate"
            :errorBorder="!isCodeValidated"
            :error="
              !isCodeValidated ? $t('errors.1010', { field: $t('projects.projectCode'), val1: '3', val2: '64' }) : ''
            "
          />
        </div>
      </div>
    </div>

    <!-- icon -->
    <div class="grid grid-cols-5 gap-4 items-center mb-5">
      <div class="col-span-2 text-right">
        {{ $t('barcode_type.icon') }}
      </div>
      <div class="col-span-3 flex items-center">
        <BarcodeIcon
          :class="`ml-1 ${isReadOnly ? 'opacity-25' : ''}`"
          :barcodeType="_selectedBarcodeType"
          v-on="!isReadOnly ? { click: () => onShowIconPicker() } : {}"
        />
        <el-button class="ml-5" type="primary" v-on="!isReadOnly ? { click: () => onShowIconPicker() } : {}">
          {{ $t('barcode_type.choose') }}
        </el-button>
        <el-checkbox class="ml-5" v-model="_selectedBarcodeType.invertIconColor">
          {{ $t('barcode_type.reverse_icon_color') }}
        </el-checkbox>
      </div>
    </div>

    <!-- color -->
    <div class="grid grid-cols-5 gap-4 items-center mb-5">
      <div class="col-span-2 text-right">
        {{ $t('barcode_type.background_color') }}
      </div>
      <div class="col-span-3 flex items-center">
        <ColorPicker
          :placement="'bottom-start'"
          v-model:selectedVal="_selectedBarcodeType.backgroundColor"
          :colorArray="colorPallet"
        />
      </div>
    </div>

    <!-- User type -->
    <div class="grid grid-cols-5 gap-4 items-center mb-5">
      <div class="col-span-2 text-right">
        {{ $t('barcode_type.user_type_can_create_this_barcode') }}
      </div>
      <div class="col-span-3">
        <el-checkbox-group v-model="_selectedBarcodeType.userTypes">
          <el-checkbox :label="userTypeKeys.CARRIER">
            {{ $t('Staff') }}
          </el-checkbox>
          <el-checkbox :label="userTypeKeys.CLIENT">
            {{ $t('barcode_type.client') }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>

  <IconPicker :barcodeType="_selectedBarcodeType" :isShow="isShowIconPicker" @close="isShowIconPicker = false" />
</template>
<script lang="ts">
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import BaseInput from '@/components/common/BaseInput.vue'
import ColorPicker from '@/components/composables/ColorPicker.vue'
import IconPicker from '@/components/IconPicker.vue'
import {
  BARCODE_TYPE_COLOR_PALLET,
  BARCODE_TYPE_WHITE_TEXT_PALLET,
  COLOR_BLACK,
  COLOR_WHITE,
  DEFAULT_BARCODE_TYPES,
} from '@/utils/constants'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Ref, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:selectedBarcodeType', 'update:barcodeTypeCode'],
  components: {
    BarcodeIcon,
    IconPicker,
    BaseInput,
    ColorPicker,
  },
  name: 'BasicInfoBlock',
})
export default class BasicInfoBlock extends Vue {
  @PropSync('selectedBarcodeType', { type: Object }) readonly _selectedBarcodeType!: IBarcodeDefinitionType
  @PropSync('barcodeTypeCode', { type: String }) _barcodeTypeCode!: string
  @Prop({ type: Boolean }) readonly isReadOnly!: boolean
  @Prop({ type: String }) readonly oldBarcodeTypeCode!: string
  @Ref() barcodeTypeName!: HTMLInputElement

  isShowIconPicker = false
  codeEmptyErrMsg = ''
  isCodeValidated = true

  validate(value: string) {
    this.clearError()
    this.isCodeValidated = !!value?.match('^[a-zA-Z0-9_-]{3,64}$')

    return this.isCodeValidated
  }

  clearError() {
    this.isCodeValidated = true
  }

  created() {
    this.codeEmptyErrMsg = this.$t('code_empty_error')
  }

  get isEditProject() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  @Watch('selectedBarcodeType.backgroundColor', { immediate: true })
  onBackgroundColorChange() {
    this.selectedBarcodeType.backgroundColor =
      this.selectedBarcodeType.backgroundColor ?? DEFAULT_BARCODE_TYPES.default.backgroundColor
  }

  @Watch('selectedBarcodeType.foregroundColor', { immediate: true })
  onForegroundColorChange() {
    this.selectedBarcodeType.foregroundColor =
      this.selectedBarcodeType.foregroundColor ?? DEFAULT_BARCODE_TYPES.default.foregroundColor
  }

  @Watch('selectedBarcodeType', { immediate: true })
  onBCChanged() {
    this.$nextTick(() => this.barcodeTypeName.focus())
  }

  onShowIconPicker() {
    this.isShowIconPicker = true
  }

  get whiteTextColorPallet(): readonly string[] {
    return BARCODE_TYPE_WHITE_TEXT_PALLET
  }

  get colorPallet() {
    return BARCODE_TYPE_COLOR_PALLET
  }

  get userTypeKeys() {
    return EUserType
  }

  @Watch('_selectedBarcodeType.backgroundColor')
  isBackgroundColorChanged(color: string) {
    if (!this._selectedBarcodeType) return
    const whiteTextColor = this.whiteTextColorPallet.find((val) => val === color)
    this._selectedBarcodeType.foregroundColor = whiteTextColor ? COLOR_WHITE : COLOR_BLACK
  }
}
</script>
<style lang="scss" scoped>
:deep() {
  .el-color-picker {
    &__mask {
      width: 62px;
      height: 42px;
    }

    &__trigger {
      width: 65px;
      height: 45px;
      border: 0px;
    }

    &__color {
      border-radius: 4px;
      overflow: hidden;
    }

    &__icon {
      display: none;
    }
  }
}
</style>
