
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import { Prop } from 'vue-property-decorator'
import { IPermission, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { UPDATE_USER_GROUP_PERMISSION } from '@/store/actions'

@Options({
  components: {},
  directives: { maska },
  emits: ['close:modal'],
  name: 'UserGroupProjectPermissionForm',
})
export default class UserGroupProjectPermissionForm extends Vue {
  @Prop({ type: String }) readonly projectId!: string
  @Prop({ type: Object }) readonly permissionData!: IPermission
  @Prop({ type: Object }) readonly trackPoints!: Record<string, ITrackpoint>

  permission = {} as IPermission

  get trackPointsHeaderName() {
    const result = [] as string[]
    Object.values(this.trackPoints).forEach((item) => {
      if (item.name) {
        result.push(item.name)
      }
    })
    return result
  }

  created() {
    this.permission = cloneDeep(this.permissionData)
  }

  onSave() {
    const projectPermissions = cloneDeep(this.$store.state.userGroup.userGroup.projectPermissions)
    projectPermissions[this.projectId] = this.permission
    this.$store.commit(UPDATE_USER_GROUP_PERMISSION, projectPermissions)
    this.$emit('close:modal', true)
  }
}
