
import { fieldRestriction2String, restrictionRule } from '@/utils/helpers'
import { IFieldRestriction, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:linking', 'update:allowToBeLinked', 'update:modelValue'],
  name: 'BarcodeLinksAvailableTPBlock',
})
export default class BarcodeLinksAvailTPBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]

  @Prop({ type: Boolean, default: false }) readonly isReadOnly!: boolean
  @Prop({ type: String, default: '' }) readonly bcTypeCode!: string

  @PropSync('linking', { type: Object })
  _linking!: { [index: string]: IFieldRestriction }

  @PropSync('allowToBeLinked', { type: Boolean, default: false })
  _allowToBeLinked!: boolean

  @PropSync('modelValue', { type: String, default: 'latest' })
  modelValue_!: string

  linkTPs: Record<string, string> = {} as Record<string, string>

  @Watch('linking', { deep: true })
  onLinkingChanged() {
    this.linkTPs = Object.fromEntries(
      this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String(this._linking[v.key])])
    )
  }

  onLinkTPsChanged(trackPointKey: string, value: string) {
    this._linking = { ...(this._linking && this._linking), [trackPointKey]: restrictionRule(value) }
  }
}
