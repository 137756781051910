<template>
  <SvgBase iconName="IconBarcodeIsChildren" :height="24" :width="24">
    <rect
      x="5.6"
      y="5.6"
      width="12.8"
      height="12.8"
      rx="1.4"
      stroke="black"
      stroke-width="1.2"
    />
    <rect
      x="0.5"
      y="0.5"
      width="23"
      height="23"
      rx="2.5"
      stroke="black"
      stroke-linejoin="round"
      stroke-dasharray="1 1"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBarcodeIsChildren extends Vue {}
</script>
