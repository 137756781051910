
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_USER_GROUPS, IS_ACTIVE_USER_GROUP } from '@/store/actions'
import { mergeUserGroups } from '@/utils/api'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import { ICarrierUserGroup, ICommonSearch, IUserGroupMergeData } from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import type { ElTable } from 'element-plus'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { maska } from 'maska'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader, BaseInput },
  directives: { maska },
  name: 'UserGroup',
})
export default class UserGroup extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  isShowOnlyActive = true
  loading = false
  isMergeMode = false
  isShowMergeDialog = false
  selectedUserGroupIds = [] as string[]
  selectedUserGroupIdsInPage = [] as string[]
  refUserGroupTable = {} as typeof ElTable
  mergeData = {
    name: '',
    errorMessage: '',
  } as {
    name: string
    errorMessage: string
  }

  get getItems() {
    const userGroupsStore: ICarrierUserGroup[] = this.$store.state.userGroup.userGroups
    return userGroupsStore || []
  }

  get mergeUserGroupNames() {
    const listItems = this.getItems
    const userGroupNames = [] as string[]
    this.selectedUserGroupIds.forEach((userGroupId) => {
      const items = listItems.filter((item) => item.id === userGroupId)
      if (items && items?.[0]) {
        userGroupNames.push(items[0].name)
      }
    })

    return userGroupNames.join(', ')
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_USER_GROUP, this.isShowOnlyActive)
    this.filterItems()
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter((userGroup: ICarrierUserGroup) =>
      this.cleanString(userGroup.name || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: ICarrierUserGroup) => item.isActive === true)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  async created() {
    this.routeName = 'userGroup'
    this.getQueryData()
    this.isShowOnlyActive = this.$store.state.userGroup.isShowOnlyActive
    if (!this.$store.state.userGroup.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }

  async fetchList() {
    this.loading = true
    await this.$store.dispatch(FETCH_USER_GROUPS).finally(() => {
      this.loading = false
    })
    this.filterItems()
  }

  goToMerge() {
    this.isMergeMode = true
  }

  cancelMerge() {
    this.selectedUserGroupIds = [] as string[]
    this.isMergeMode = false
  }

  onEditUserGroup(item: ICarrierUserGroup) {
    if (!this.isMergeMode) {
      this.$router.push({
        name: 'userGroupEdit',
        params: {
          id: item.id,
        },
      })
    }
  }

  onCreateUserGroup() {
    this.$router.push({
      name: 'userGroupCreate',
    })
  }

  goToStaffList() {
    this.$router.push({
      name: 'carriers',
    })
  }

  mergeUserGroup() {
    this.isShowMergeDialog = true
  }

  closeMergeDialog() {
    this.isShowMergeDialog = false
    this.mergeData.name = ''
    this.mergeData.errorMessage = ''
  }

  clearError() {
    this.mergeData.errorMessage = ''
  }

  validate() {
    if (this.mergeData.name === '') {
      this.mergeData.errorMessage = this.$t('mandatory_field')
      return false
    }
    return true
  }

  async onMergeUserGroup() {
    if (!this.validate()) return
    this.loading = true
    try {
      await mergeUserGroups({
        userGroupIds: this.selectedUserGroupIds,
        name: this.mergeData.name,
      } as IUserGroupMergeData)

      openMessage(this.$t('save_successful'), 'success')
      await this.fetchList()
      this.selectedUserGroupIds = [] as string[]
      this.closeMergeDialog()
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.loading = false
    }
  }

  handleSelectionChange = (val: ICarrierUserGroup[]) => {
    const selectedInPage = [] as string[]
    val.forEach((item) => {
      if (item.id) {
        if (!this.selectedUserGroupIds.includes(item.id)) {
          this.selectedUserGroupIds.push(item.id)
        }
        if (!this.selectedUserGroupIdsInPage.includes(item.id)) {
          this.selectedUserGroupIdsInPage.push(item.id)
        }
        if (!selectedInPage.includes(item.id)) {
          selectedInPage.push(item.id)
        }
      }
    })

    this.selectedUserGroupIdsInPage.forEach((item, index) => {
      if (!selectedInPage.includes(item)) {
        const indexRemove = this.selectedUserGroupIds.indexOf(item)
        if (indexRemove > -1) {
          this.selectedUserGroupIds.splice(indexRemove, 1)
        }

        this.selectedUserGroupIdsInPage.splice(index, 1)
      }
    })
  }

  selecteRow = (val: ICarrierUserGroup) => {
    if (val.id) {
      const index = this.selectedUserGroupIds.indexOf(val.id)
      if (index > -1) {
        this.selectedUserGroupIds.splice(index, 1)
      } else {
        this.selectedUserGroupIds.push(val.id)
      }

      const indexCurrentPage = this.selectedUserGroupIdsInPage.indexOf(val.id)
      if (index > -1) {
        this.selectedUserGroupIdsInPage.splice(indexCurrentPage, 1)
      } else {
        this.selectedUserGroupIdsInPage.push(val.id)
      }
    }

    this.refUserGroupTable.toggleRowSelection(val, undefined)
  }

  updated() {
    this.refUserGroupTable = this.$refs.userGroupTable
  }

  selectOldTableData() {
    this.selectedUserGroupIdsInPage = []
    if (this.$refs.userGroupTable) {
      this.selectedUserGroupIds.forEach((id) => {
        const existItems = this.displayItems.filter((item: ICarrierUserGroup) => item.id === id)
        if (existItems && existItems?.[0]) {
          this.$refs.userGroupTable.toggleRowSelection(existItems[0], undefined)
          this.selectedUserGroupIdsInPage.push(id)
        }
      })
    }
  }
}
