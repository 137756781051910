
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import IconUnpair from '@/components/svg/barcodeType/IconUnpair.vue'
import IconPair from '@/components/svg/barcodeType/IconPair.vue'
@Options({
  components: { IconPair, IconUnpair },
  name: 'TrackPointNode',
})
export default class TrackPointNode extends Vue {
  @Prop({ type: String }) color?: string
  @Prop({ type: String }) line?: string
  @Prop({ type: String }) id?: string
  @Prop({ type: Number }) diameter!: number | 0
  @Prop({ type: Number }) containerHeight!: number | 0
  @Prop({ type: Boolean }) isShowPair?: boolean
  @Prop({ type: Boolean }) isShowUnpair?: boolean

  get getWidthHeight() {
    const pxDiameter = this.diameter - 6
    return `width: ${pxDiameter}px; height: ${pxDiameter}px;`
  }
}
