
import { Vue, Options } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:allowToPair', 'update:allowToBePaired'],
  name: 'PairedAllowanceBlock',
})
export default class PairedAllowanceBlock extends Vue {
  @PropSync('allowToBePaired', { type: Boolean }) _allowToBePaired!: boolean
  @PropSync('allowToPair', { type: Boolean }) _allowToPair!: boolean
}
