
import { Options, mixins } from 'vue-class-component'
import BarcodeTypesMixin from './mixins/BarcodeTypesMixin.vue'
import { CUSTOM_FIELD_ICONS, CUSTOM_FIELD_ICON_COMPONENTS } from '@/utils/constants'
import { ITrackPointForms } from 'smartbarcode-web-core/src/utils/types/index'
import { Prop } from 'vue-property-decorator'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { iconFileKindKey } from '@/utils/helpers'
import { ECustomFieldType, ECustomFieldExtraType } from 'smartbarcode-web-core/src/utils/enums/index'

export interface IIconRecord {
  label: string[]
  icon: unknown
}

@Options({
  components: {
    name: 'CustomFieldIconList',
  },
})
export default class CustomFieldIconList extends mixins(BarcodeTypesMixin) {
  @Prop({ type: String }) readonly formId: string = ''
  @Prop({ type: Object }) readonly trackPointForm!: ITrackPointForms

  get isIconAvailable() {
    return !isEmpty(this.icons)
  }

  isCustomField(key: string) {
    return !(key === 'location')
  }

  get customFieldComponents() {
    return CUSTOM_FIELD_ICON_COMPONENTS
  }

  get icons(): Record<string, IIconRecord> {
    let coms = {} as Record<string, IIconRecord>
    if (!isEmpty(this.trackPointForm.location)) {
      coms = {
        location: {
          label: [CUSTOM_FIELD_ICONS.LOCATION],
          icon: CUSTOM_FIELD_ICON_COMPONENTS[CUSTOM_FIELD_ICONS.LOCATION].icon,
        },
      }
    }

    const customFields = Object.values(this.trackPointForm.customFields ?? {})
    if (!isEmpty(customFields)) {
      customFields.forEach((i) => {
        const fieldType: string =
          i.fieldType === ECustomFieldType.TEXT && i.multiLine
            ? ECustomFieldExtraType.TEXT_AREA
            : i.fieldType === ECustomFieldType.FILES
            ? iconFileKindKey(i.fileKind)
            : i.fieldType
        const compFieldType = coms[fieldType]

        if (compFieldType) {
          compFieldType.label.push(i.label)
        } else {
          coms = {
            ...coms,
            [fieldType]: {
              label: [i.label],
              icon: CUSTOM_FIELD_ICON_COMPONENTS[fieldType].icon,
            },
          }
        }
      })
    }

    return coms
  }
}
