<template>
  <div class="custom-dialog">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowDialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="closeDialog"
    >
      <template #title> {{ $t('barcode_type.custom_validation_setting') }} </template>
      <div>
        <el-form
          class="frm-custom-validator"
          :label-position="'right'"
          label-width="160px"
          :rules="rules"
          :model="customValidatorData"
          ref="customValidationForm"
        >
          <div class="flex items-center mb-5">
            <div>
              <el-form-item prop="eventType" :label="$t('barcode_type.timing')">
                <el-select
                  class="w-44"
                  v-model="customValidatorData.eventType"
                  :placeholder="$t('barcode_type.timing')"
                >
                  <el-option
                    v-for="index in customEventType"
                    :key="index"
                    :label="$t(`barcode_type.${index}`)"
                    :value="index"
                  >
                    {{ $t(`barcode_type.${index}`) }}
                  </el-option>
                </el-select>
              </el-form-item>
            </div>
            <div v-if="isEventTypeTracked">
              <div class="flex items-center">
                <div class="frm-custom-validator__label whitespace-nowrap">
                  {{ $t('barcode_type.route') }}
                </div>
                <div class="flex-grow">
                  <div class="flex items-center">
                    <div>
                      <el-form-item prop="trackPointRoute.from" :label-width="'0px'">
                        <el-select
                          class="w-44"
                          v-model="customValidatorData.trackPointRoute.from"
                          @change="changeRouteFrom"
                          :placeholder="$t('barcode_type.select')"
                        >
                          <el-option
                            v-for="tpData in trackingPointForm"
                            :key="tpData.key"
                            :value="tpData.key"
                            :label="`${tpData?.value?.name} (${tpData.key})`"
                          >
                            {{ tpData.value.name }} {{ `(${tpData.key})` }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <i class="el-icon-right mx-5"></i>
                    <div>
                      <el-form-item prop="trackPointRoute.to" :label-width="'0px'">
                        <el-select
                          class="w-44"
                          v-model="customValidatorData.trackPointRoute.to"
                          :placeholder="$t('barcode_type.select')"
                        >
                          <el-option
                            v-for="tpData in trackingPointTo"
                            :key="tpData.key"
                            :value="tpData.key"
                            :label="`${tpData.value.name} (${tpData.key})`"
                          >
                            {{ tpData.value.name }} {{ `(${tpData.key})` }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="border border-gray-400 border-solid rounded-lg p-2 my-5 relative"
            v-for="(condition, conditionIndex) in customValidatorData.conditions"
            :key="conditionIndex"
          >
            <div class="absolute condition-control-button">
              <el-button
                type="default"
                v-if="customValidatorData.conditions.length > 1"
                icon="el-icon-minus"
                class="bg-gray-400 text-white"
                size="small"
                circle
                @click="removeCondition(conditionIndex)"
              >
              </el-button>
              <el-button
                v-if="conditionIndex === customValidatorData.conditions.length - 1"
                type="primary"
                icon="el-icon-plus"
                size="small"
                circle
                @click="addCondition()"
              >
              </el-button>
            </div>
            <div class="flex items-start mb-1">
              <div class="frm-custom-validator__label whitespace-nowrap mt-10">
                {{ $t('barcode_type.comparisonConditions') }}
              </div>
              <div class="flex-grow relative">
                <div class="absolute logical-operator__container" v-if="condition.comparisons.length > 1">
                  <div class="relative h-full w-full">
                    <div class="absolute logical-operator__text">
                      {{ showLogicalOperator(condition.comparisonLogicalOperator) }}
                    </div>
                  </div>
                </div>
                <div class="flex items-center mb-1 comparison-description">
                  <div class="w-72">
                    {{ $t('barcode_type.comparisonSourceVariableReplacementTarget') }}
                    {{ hintComparisonFrom }}
                  </div>
                  <div class="w-12"></div>
                  <div class="w-72">
                    {{ $t('barcode_type.comparisonDestinationVariableReplacementTarget') }}
                    {{ hintComparisonTo }}
                  </div>
                  <div class="flex-grow"></div>
                </div>
                <div
                  class="flex items-center mb-4"
                  v-for="(item, comparasionIndex) in condition.comparisons"
                  :key="comparasionIndex"
                >
                  <div class="w-72">
                    <el-form-item
                      :prop="`conditions.${conditionIndex}.comparisons.${comparasionIndex}.from`"
                      :label-width="'0px'"
                    >
                      <SelectAutocomplete v-model="item.from" :options="customFieldFromOptions" name="from" />
                    </el-form-item>
                  </div>
                  <div class="w-12 flex justify-center">{{ showOperator(item.operator) }}</div>
                  <div class="w-72">
                    <el-form-item
                      :prop="`conditions.${conditionIndex}.comparisons.${comparasionIndex}.to`"
                      :label-width="'0px'"
                    >
                      <SelectAutocomplete v-model="item.to" :options="customFieldToOptions" name="to" />
                    </el-form-item>
                  </div>
                  <div class="flex-grow"></div>
                  <div class="w-20">
                    <el-button
                      :disabled="condition.comparisons.length <= 1"
                      type="default"
                      icon="el-icon-minus"
                      class="bg-gray-400 text-white"
                      size="small"
                      circle
                      @click="removeComparision(condition, comparasionIndex, conditionIndex)"
                    >
                    </el-button>
                    <el-button
                      type="primary"
                      v-if="comparasionIndex === condition.comparisons.length - 1"
                      icon="el-icon-plus"
                      size="small"
                      circle
                      @click="addComparision(condition, conditionIndex)"
                    >
                    </el-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex items-center mb-5">
              <el-form-item
                prop="compileLogicalMethod"
                :label="$t('barcode_type.compare_method')"
                v-if="condition.comparisons.length > 1"
              >
                <el-radio-group
                  @change="onCompileLogicalMethodChange(condition)"
                  v-model="condition.compileLogicalMethod"
                  class="flex flex-wrap items-center"
                >
                  <div class="flex w-1/2 mb-1" v-for="(item, key) in customValidationLogicalMethod" :key="key">
                    <el-radio :label="item">
                      {{ $t(`barcode_type.${key}`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
              <el-form-item prop="compareMethod" :label="$t('barcode_type.compare_method')" v-else>
                <el-radio-group
                  @change="onCompareMethodChange(condition)"
                  v-model="condition.compareMethod"
                  class="flex flex-wrap items-center mt-2"
                >
                  <div class="flex mb-1 mr-8" v-for="(item, key) in customValidationCompareMethod" :key="key">
                    <el-radio :label="item">
                      {{ $t(`barcode_type.comparisonMethod.${key}`) }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div v-if="customValidatorData.conditions.length > 1">
            <div class="flex items-center mb-5">
              <el-form-item
                class="pre-line"
                prop="conditionLogicalOperator"
                :label="$t('barcode_type.comparisonConditionsMethod')"
              >
                <el-radio-group
                  v-model="customValidatorData.conditionLogicalOperator"
                  class="flex flex-wrap items-center"
                >
                  <div class="flex mb-1 mr-8" v-for="(item, key) in customValidatorConditionalLogicalMethod" :key="key">
                    <el-radio :label="item">
                      {{
                        $t(`barcode_type.${key}`)
                          .toString()
                          .toUpperCase()
                      }}
                    </el-radio>
                  </div>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
          <div class="flex items-center mb-5">
            <el-form-item prop="message" :label="$t('barcode_type.display_message')">
              <textarea class="h-28 smartbarcode-textarea" v-model="customValidatorData.message"></textarea>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="closeDialog">
              {{ $t('barcode_type.cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="onSave" :disabled="isSaveDisabled">
              {{ isAddAction ? $t('barcode_type.add') : $t('save') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import {
  COMPARE_METHOD_VALUE,
  COMPILE_LOGICAL_COMPARE_METHOD_VALUE,
  COMPILE_LOGICAL_CONDITION_METHOD_VALUE,
  CUSTOM_VALIDATION_COMPARISON_DEFAULT,
  CUSTOM_VALIDATION_CONDITION_DEFAULT,
  LOGICAL_OPERATOR_VALUE,
  VALIDATION_EVENT_TYPE_VALUE,
} from '@/utils/constants'
import {
  getCustomFieldOptions,
  getStaticOptions,
  getTrackingCustomFieldOptions,
  reFormatCustomFieldOptions,
} from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldCustomValidation,
  ICustomFieldFormData,
  ICustomValidator,
  ICustomValidatorCondition,
  ICustomValidatorForm,
  IFieldOption,
  ITrackpoint,
  ITrackPointForms,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, Watch } from 'vue-property-decorator'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'

@Options({
  emits: ['dialog:close', 'update:customValidator'],
  components: { SelectAutocomplete },
  name: 'CustomValidatorDialog',
})
export default class CustomValidatorDialog extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: Object, default: {} }) readonly customValidator!: ICustomValidator
  @Prop({ type: Boolean, default: {} }) isAddAction?: boolean
  @Prop({ type: String }) readonly currentBCTypeCode!: string

  excludedFieldType = [FIELD_TYPE.ESIGN, FIELD_TYPE.LOCATION, FIELD_TYPE.FILES]
  customValidatorData = {} as ICustomValidatorForm
  isShowDialog = true
  isSaveDisabled = false
  rules = {
    eventType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    conditionLogicalOperator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    conditions: [
      {
        comparisons: [
          {
            from: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
            to: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
            operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
          },
        ],
      },
    ],
    message: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  } as {
    eventType: Array<unknown>
    trackPointRoute?: {
      from: Array<unknown>
      to: Array<unknown>
    }
    conditions: [
      {
        comparisons: [
          {
            from: Array<unknown>
            to: Array<unknown>
            operator: Array<unknown>
          }
        ]
      }
    ]
    conditionLogicalOperator: Array<unknown>
    message: Array<unknown>
  }

  customFieldFromOptions = [] as IFieldOption[]
  customFieldToOptions = [] as IFieldOption[]

  get trackingPointForm() {
    return this.trackingPointDatasArr.filter((item) => !isEmpty(item.value.trackPointForms))
  }

  get trackingPointTo() {
    const key = this.customValidatorData.trackPointRoute?.from
    const nextTrackpoints = objectToArray<ITrackPointForms>(
      this.trackingPointDatasArr.find((tp) => tp.key === key)?.value.trackPointForms || {}
    )

    const nextTrackPointsMap = nextTrackpoints.map((val) => {
      const trackpointName = this.trackingPointDatasArr.find((tp) => tp.key === val.key)?.value.name
      return {
        key: val.key,
        value: {
          ...val.value,
          name: trackpointName,
        },
      }
    })
    return nextTrackPointsMap
  }

  get restrictionType() {
    return { parent: 0, child: 1 }
  }

  get customEventType() {
    return VALIDATION_EVENT_TYPE_VALUE
  }

  get customValidationLogicalMethod() {
    return COMPILE_LOGICAL_COMPARE_METHOD_VALUE
  }

  get customValidatorConditionalLogicalMethod() {
    return COMPILE_LOGICAL_CONDITION_METHOD_VALUE
  }

  get customValidationCompareMethod() {
    return COMPARE_METHOD_VALUE
  }

  get isEventTypeTracked() {
    return this.customValidatorData.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked
  }

  getCustomFieldFromOptions(): IFieldOption[] {
    const options = getStaticOptions()
    const customFieldOptions = getCustomFieldOptions({
      fields: this.customFields || [],
      excludeFieldType: this.excludedFieldType,
    })
    options.push(...customFieldOptions)
    if (this.isEventTypeTracked) {
      const trackPointCustomFieldOptions = this.getCustomFieldTrackingPointRelationship()
      options.push(...trackPointCustomFieldOptions)
    }
    return reFormatCustomFieldOptions(options)
  }

  getCustomFieldToOptions(): IFieldOption[] {
    const options = getStaticOptions()
    const customFieldOptions = getCustomFieldOptions({
      fields: this.customFields || [],
      excludeFieldType: this.excludedFieldType,
    })
    options.push(...customFieldOptions)
    if (this.isEventTypeTracked) {
      const trackPointCustomFieldOptions = this.getCustomFieldTrackingPointRelationship()
      options.push(...trackPointCustomFieldOptions)
    } else {
      for (const key in this.barcodeTypes) {
        if (key !== this.currentBCTypeCode) {
          const item = this.barcodeTypes[key]
          const customFields = Object.entries(item.activationFields.customFields).map(([k, v]) => ({
            ...v,
            customFieldKey: k,
          }))
          const subCustomFieldOptions = getCustomFieldOptions({
            fields: customFields,
            excludeFieldType: this.excludedFieldType,
            barcodeName: item.name,
          })
          options.push(...subCustomFieldOptions)
        }
      }
    }
    return reFormatCustomFieldOptions(options)
  }

  get hintComparisonFrom() {
    let hintText = ''
    switch (this.customValidatorData.eventType) {
      case VALIDATION_EVENT_TYPE_VALUE.tracked:
        hintText = this.$t('barcode_type.currentBarcode')
        break
      case VALIDATION_EVENT_TYPE_VALUE.paired:
        hintText = this.$t('barcode_type.parentBarcode')
        break
      case VALIDATION_EVENT_TYPE_VALUE.linked:
        hintText = this.$t('barcode_type.barcodeLinkOrigin')
        break
    }
    return hintText
  }

  get hintComparisonTo() {
    let hintText = ''
    switch (this.customValidatorData.eventType) {
      case VALIDATION_EVENT_TYPE_VALUE.tracked:
        hintText = this.$t('barcode_type.currentBarcode')
        break
      case VALIDATION_EVENT_TYPE_VALUE.paired:
        hintText = this.$t('barcode_type.allChildrenBarcodeTarget')
        break
      case VALIDATION_EVENT_TYPE_VALUE.linked:
        hintText = this.$t('barcode_type.allBarcodeLinkTarget')
        break
    }
    return hintText
  }

  getCustomFieldTrackingPointRelationship(): IFieldOption[] {
    let customFields = [] as ICustomFieldCustomValidation[]
    const trackPoints = this.$store.state.project.projectDetail.trackPoints as Record<string, ITrackpoint>
    for (const from in trackPoints) {
      const fromName = trackPoints[from].name || ''
      for (const to in trackPoints[from]?.trackPointForms) {
        const toName = trackPoints[to].name || ''
        const trackPointForm = trackPoints?.[from].trackPointForms?.[to] as ITrackPointForms

        const data = Object.entries(trackPointForm.customFields ?? {}).map(([key, field]) => ({
          ...field,
          fieldTypeKey: field.fieldType,
          customFieldKey: key,
          fromName,
          toName,
        })) as ICustomFieldCustomValidation[]
        customFields = customFields.concat(data)
      }
    }
    return getTrackingCustomFieldOptions(customFields, this.excludedFieldType)
  }

  created() {
    const customValidatorData = cloneDeep(this.customValidator) as ICustomValidatorForm
    customValidatorData.conditions.forEach((val) => {
      val.compileLogicalMethod = `${val.comparisonLogicalOperator.toLocaleLowerCase()}:${
        val.comparisons?.[0]?.operator
      }`
      val.compareMethod = val.comparisons?.[0]?.operator
    })
    this.customValidatorData = customValidatorData
    this.changeEventType()
  }

  removeComparision(condition: ICustomValidatorCondition, comparasionIndex: number, conditionIndex: number) {
    if (condition.comparisons.length > 1) {
      condition.comparisons.splice(comparasionIndex, 1)
      if (this.rules?.conditions?.[conditionIndex]?.comparisons) {
        this.rules.conditions[conditionIndex].comparisons.splice(comparasionIndex, 1)
      }
    }
  }

  addComparision(condition: ICustomValidatorCondition, conditionIndex: number) {
    const newComparison = cloneDeep(CUSTOM_VALIDATION_COMPARISON_DEFAULT)
    newComparison.operator = condition.compareMethod || COMPARE_METHOD_VALUE.equal
    condition.comparisons.push(newComparison)

    if (this.rules?.conditions?.[conditionIndex]?.comparisons) {
      this.rules.conditions[conditionIndex].comparisons.push({
        from: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
        operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      })
    }
  }

  addCondition() {
    this.customValidatorData.conditions.push(cloneDeep(CUSTOM_VALIDATION_CONDITION_DEFAULT))
    this.rules.conditions.push({
      comparisons: [
        {
          from: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
          to: [{ required: true, validator: this.requiredVariableValidate, trigger: 'change' }],
          operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        },
      ],
    })
  }

  removeCondition(index: number) {
    if (this.customValidatorData.conditions.length > 1) {
      this.customValidatorData.conditions.splice(index, 1)
    }
    if (this.rules?.conditions) {
      this.rules.conditions.splice(index, 1)
    }
  }

  showOperator(operator: string) {
    return operator === COMPARE_METHOD_VALUE.equal ? '=' : '<>'
  }

  showLogicalOperator(operator: string) {
    return operator === LOGICAL_OPERATOR_VALUE.and ? 'and' : 'or'
  }

  changeRouteFrom() {
    if (this.customValidatorData.trackPointRoute && this.customValidatorData.trackPointRoute.to) {
      this.customValidatorData.trackPointRoute.to = ''
    }
  }

  onCompileLogicalMethodChange(condition: ICustomValidatorCondition) {
    const arrLogicalMethod = condition.compileLogicalMethod?.split(':')
    if (arrLogicalMethod?.[0]) {
      condition.comparisonLogicalOperator = arrLogicalMethod[0]
    }

    if (arrLogicalMethod?.[1]) {
      condition.comparisons.forEach((item) => (item.operator = arrLogicalMethod[1]))
      condition.compareMethod = arrLogicalMethod?.[1]
    }
  }

  onCompareMethodChange(condition: ICustomValidatorCondition) {
    if (condition.compareMethod) {
      const compareMethod = condition.compareMethod || COMPARE_METHOD_VALUE.equal
      condition.comparisons.map((item) => {
        item.operator = compareMethod
      })
      const arrCompileMethod = condition.compileLogicalMethod?.split(':')
      condition.compileLogicalMethod = `${arrCompileMethod?.[0] || LOGICAL_OPERATOR_VALUE.and}:${compareMethod}`
    }
  }

  @Watch('customValidatorData.eventType')
  changeEventType() {
    if (this.customValidatorData.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked) {
      this.rules.trackPointRoute = {
        from: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      }
    } else {
      delete this.rules.trackPointRoute
      this.customValidatorData.trackPointRoute = { from: '', to: '' }
    }

    this.customFieldFromOptions = this.getCustomFieldFromOptions()
    this.customFieldToOptions = this.getCustomFieldToOptions()
  }

  closeDialog() {
    this.$emit('dialog:close')
  }

  onSave() {
    this.isSaveDisabled = true
    this.$refs.customValidationForm.validate(async (valid: string) => {
      if (valid) {
        this.loading = true
        this.$emit('update:customValidator', cloneDeep(this.formatCustomValidator(this.customValidatorData)))
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  formatCustomValidator(data: ICustomValidatorForm): ICustomValidator {
    if (data.eventType !== VALIDATION_EVENT_TYPE_VALUE.tracked) {
      delete data.trackPointRoute
    }
    data.conditions.forEach((data) => {
      delete data.compileLogicalMethod
      delete data.compareMethod
    })

    return data
  }
}
</script>

<style lang="scss" scoped>
.custom-dialog:deep() {
  .pre-line {
    & .el-form-item__label {
      line-height: 20px !important;
    }
  }
  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        width: 1000px !important;
        max-width: 1000px !important;
        margin-top: 0px !important;
      }
    }
  }
}
.logical-operator {
  &__container {
    width: 15px;
    height: calc(100% - 32px);
    top: 20px;
    right: 100px;
    border: 1px solid #999;
    border-left: none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  &__text {
    width: fit-content;
    top: 50%;
    right: 0;
    transform: translate(50%, -50%);
    border: 1px solid #999;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 8px;
    padding: 2px 3px;
    background-color: #f2f2f2;
  }
}
.comparison-description {
  font-size: 10px;
  height: 20px;
}

.condition-control-button {
  left: 50%;
  bottom: 0%;
  transform: translate(-50%, 50%);
}
</style>
