<template>
  <div class="custom-dialog">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowDialog"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="closeDialog"
    >
      <template #title> {{ $t('barcode_type.notification_settings') }} </template>
      <div>
        <el-form
          class="frm-custom-validator"
          :label-position="'right'"
          label-width="170px"
          :rules="rules"
          :model="customEventNotificationData"
          ref="customEventNotificationForm"
        >
          <div class="flex items-center mb-5">
            <div>
              <el-form-item prop="eventType" :label="$t('barcode_type.timing')">
                <el-select
                  class="w-44"
                  v-model="customEventNotificationData.eventType"
                  :placeholder="$t('barcode_type.timing')"
                >
                  <el-option
                    :disabled="isEventTypeDisabled(key)"
                    v-for="(val, key) in customEventType"
                    :key="key"
                    :label="val"
                    :value="key"
                  />
                </el-select>
              </el-form-item>
            </div>
            <div v-if="isEventTypeTracked">
              <div class="flex items-center">
                <div class="frm-custom-validator__label whitespace-nowrap">
                  {{ $t('barcode_type.route') }}
                </div>
                <div class="flex-grow">
                  <div class="flex items-center">
                    <div>
                      <el-form-item prop="trackPointRoute.from" :label-width="'0px'">
                        <el-select
                          class="w-44"
                          v-model="customEventNotificationData.trackPointRoute.from"
                          @change="changeRouteFrom"
                          :placeholder="$t('barcode_type.select')"
                        >
                          <el-option
                            v-for="tpData in trackingPointForm"
                            :key="tpData.key"
                            :value="tpData.key"
                            :label="`${tpData?.value?.name} (${tpData.key})`"
                          >
                            {{ tpData.value.name }} {{ `(${tpData.key})` }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <i class="el-icon-right mx-5"></i>
                    <div>
                      <el-form-item prop="trackPointRoute.to" :label-width="'0px'">
                        <el-select
                          class="w-44"
                          v-model="customEventNotificationData.trackPointRoute.to"
                          :placeholder="$t('barcode_type.select')"
                        >
                          <el-option
                            v-for="tpData in trackingPointTo"
                            :key="tpData.key"
                            :value="tpData.key"
                            :label="`${tpData.value.name} (${tpData.key})`"
                          >
                            {{ tpData.value.name }} {{ `(${tpData.key})` }}
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center mb-5 w-full">
            <el-form-item
              class="items-center pre-line"
              prop="triggerDateField"
              :label="$t('barcode_type.specific_calendar_field')"
            >
              <SelectAutocomplete v-model="customEventNotificationData.triggerDateField" :options="options" />
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="timingType" :label="$t('time_difference')">
              <div class="flex flex-row">
                <el-select
                  class="w-24 mr-2"
                  v-model="customEventNotificationData.timingType"
                  :placeholder="$t('time_difference')"
                >
                  <el-option v-for="(val, key) in timingType" :key="key" :label="val" :value="key" />
                </el-select>
                <div class="w-44">
                  <InputOnlyNumber
                    :placeholder="$t('hours')"
                    :min="1"
                    v-model="hours"
                    name="delayMinutes"
                    :controls="false"
                    :precision="0"
                  />
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="emailDestinations" :label="$t('barcode_type.email_address')">
              <div
                v-for="(email, idx) in customEventNotificationData.emailDestinations"
                :key="idx"
                class="flex flex-row"
              >
                <div class="w-3/4">
                  <el-form-item
                    :prop="`emailDestinations[${idx}]`"
                    :rules="[
                      {
                        required: isEmailAvail || !isEmailAliasAvail,
                        validator: isEmailAvail || !isEmailAliasAvail ? requiredEmailValidate : () => true,
                      },
                    ]"
                  >
                    <el-input size="medium" v-model="customEventNotificationData.emailDestinations[idx]"></el-input>
                  </el-form-item>
                </div>
                <div class="w-20 pt-1 pl-3 grid justify-items-end">
                  <div>
                    <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmail(idx)">
                    </el-button>
                    <el-button
                      v-if="idx === customEventNotificationData.emailDestinations.length - 1"
                      type="primary"
                      icon="el-icon-plus"
                      size="small"
                      circle
                      @click="addNewEmail"
                    >
                    </el-button>
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full ">
            <el-form-item
              class="w-full items-center pre-line"
              prop="emailDestinationAliases"
              :label="$t('barcode_type.email_custom_field')"
            >
              <div
                v-for="(alias, idx) in customEventNotificationData.emailDestinationAliases"
                :key="idx"
                :class="`flex flex-row ${idx !== 0 ? 'mt-4' : ''}`"
              >
                <div class="w-3/4">
                  <SelectAutocomplete
                    class=""
                    v-model="customEventNotificationData.emailDestinationAliases[idx]"
                    :options="emailCFs"
                  />
                </div>
                <div class="w-20 pt-1 pl-3 grid justify-items-end">
                  <div>
                    <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmailCF(idx)" />
                    <el-button type="primary" icon="el-icon-plus" size="small" circle @click="addNewEmailCF" />
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="emailSubject" :label="$t('barcode_type.subject')">
              <el-input v-model="customEventNotificationData.emailSubject" size="medium" />
            </el-form-item>
          </div>
          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="emailBody" :label="$t('barcode_type.display_message')">
              <el-input v-model="customEventNotificationData.emailBody" type="textarea" :rows="4" />
            </el-form-item>
          </div>

          <div class="flex items-center mb-5 w-full">
            <el-form-item prop="isDisplayOnMobile">
              <el-checkbox v-model="customEventNotificationData.isDisplayOnMobile" size="large">{{
                $t('barcode_type.is_display_on_mobile')
              }}</el-checkbox>
            </el-form-item>
          </div>
        </el-form>
      </div>

      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="closeDialog">
              {{ $t('barcode_type.cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="onSave" :disabled="isSaveDisabled">
              {{ isAddAction ? $t('barcode_type.add') : $t('save') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import {
  COMPARE_METHOD_VALUE,
  COMPILE_LOGICAL_COMPARE_METHOD_VALUE,
  LOGICAL_OPERATOR_VALUE,
} from '@/utils/constants'
import { ECustomFieldType, EEventType, ENotificationtiming } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  getCustomFieldOptions,
  getTrackingCustomFieldOptionsWithoutIndex,
  reFormatCustomFieldOptions,
} from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldCustomValidation,
  ICustomFieldFormData,
  ICustomNotificationEvent,
  IFieldOption,
  ITrackpoint,
  ITrackPointForms,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, Watch } from 'vue-property-decorator'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'

@Options({
  emits: ['dialog:close', 'update:customEventNotification'],
  components: { SelectAutocomplete, InputOnlyNumber },
  name: 'CustomEventNotificationDialog',
})
export default class CustomEventNotificationDialog extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: Object, default: {} }) readonly customEventNotification!: ICustomNotificationEvent
  @Prop({ type: Boolean, default: {} }) isAddAction?: boolean
  @Prop({ type: Boolean, default: {} }) isDisabledActivateEvent?: boolean
  @Prop({ type: String }) readonly currentBCTypeCode!: string

  excludedFieldType = [
    FIELD_TYPE.ESIGN,
    FIELD_TYPE.LOCATION,
    FIELD_TYPE.FILES,
    FIELD_TYPE.TEXT,
    FIELD_TYPE.NUMBER,
    FIELD_TYPE.SINGLE_SELECT,
    FIELD_TYPE.MULTI_SELECT,
    FIELD_TYPE.EMAIL,
    FIELD_TYPE.REFERENCE,
    FIELD_TYPE.PHONE_NUMBER,
  ]

  customEventNotificationData = {} as ICustomNotificationEvent
  isShowDialog = true
  isSaveDisabled = false
  rules = {
    eventType: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    logicalOperator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    comparisons: [
      {
        from: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        operator: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      },
    ],
    emailDestinations: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    message: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    delayMinutes: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  } as {
    eventType: Array<unknown>
    trackPointRoute?: {
      from: Array<unknown>
      to: Array<unknown>
    }
    comparisons: [
      {
        from: Array<unknown>
        to: Array<unknown>
        operator: Array<unknown>
      }
    ]
    logicalOperator: Array<unknown>
    message: Array<unknown>
  }

  customFieldFromOptions = [] as IFieldOption[]

  isEventTypeDisabled(key: EEventType) {
    if (key === EEventType.ACTIVATING) {
      // Check current notification is activation in the begining and edit it
      if (this.isDisabledActivateEvent) {
        return this.customEventNotification.eventType !== EEventType.ACTIVATING
      }
      return this.isDisabledActivateEvent
    }
    return false
  }

  get trackingPointForm() {
    return this.trackingPointDatasArr.filter((item) => !isEmpty(item.value.trackPointForms))
  }

  get trackingPointTo() {
    const key = this.customEventNotificationData.trackPointRoute?.from
    const nextTrackpoints = objectToArray<ITrackPointForms>(
      this.trackingPointDatasArr.find((tp) => tp.key === key)?.value.trackPointForms || {}
    )

    const nextTrackPointsMap = nextTrackpoints.map((val) => {
      const trackpointName = this.trackingPointDatasArr.find((tp) => tp.key === val.key)?.value.name
      return {
        key: val.key,
        value: {
          ...val.value,
          name: trackpointName,
        },
      }
    })
    return nextTrackPointsMap
  }

  get phoneNoCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.PHONE_NUMBER)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get calendarCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.DATE)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get options() {
    return this.getCustomFieldFromOptions()
  }

  get isEmailAvail() {
    return !isEmpty(this.customEventNotificationData.emailDestinations.filter((v) => v !== ''))
  }

  get isEmailAliasAvail() {
    return !isEmpty(this.customEventNotificationData.emailDestinationAliases.filter((v) => v !== ''))
  }

  get restrictionType() {
    return {
      parent: 0,
      child: 1,
    }
  }

  get customEventType() {
    return {
      [EEventType.ACTIVATING]: this.$t('barcode_type.display_scanned_time'),
      [EEventType.TRACKING]: this.$t('barcode_type.display_tracking_time'),
    }
  }

  get timingType() {
    return {
      [ENotificationtiming.AFTER]: this.$t('after'),
      [ENotificationtiming.BEFORE]: this.$t('before'),
    }
  }

  get customValidationLogicalMethod() {
    return COMPILE_LOGICAL_COMPARE_METHOD_VALUE
  }

  get customValidationCompareMethod() {
    return COMPARE_METHOD_VALUE
  }

  get isEventTypeTracked() {
    return this.customEventNotificationData.eventType === EEventType.TRACKING
  }

  get delayMinutes() {
    return this.customEventNotificationData.delayMinutes
  }

  get hours() {
    return Math.ceil(this.delayMinutes / 60)
  }

  set hours(data: number) {
    this.customEventNotificationData.delayMinutes = data * 60
  }

  addNewEmail() {
    this.customEventNotificationData.emailDestinations.push('')
  }

  deleteEmail(idx: number) {
    if (this.customEventNotificationData.emailDestinations.length === 1 && idx === 0) return
    this.customEventNotificationData.emailDestinations.splice(idx, 1)
  }

  addNewEmailCF() {
    this.customEventNotificationData.emailDestinationAliases.push('')
  }

  deleteEmailCF(idx: number) {
    if (this.customEventNotificationData.emailDestinationAliases.length === 1 && idx === 0) return
    this.customEventNotificationData.emailDestinationAliases.splice(idx, 1)
  }

  getCustomFieldFromOptions(): IFieldOption[] {
    const options = []
    const replaceLabelKeys = [{ from: 'barcode_type.activation', to: 'barcode_type.display_scanned_time' }]

    const customFieldOptions = getCustomFieldOptions({
      fields: this.customFields || [],
      excludeFieldType: this.excludedFieldType,
      replaceLabelKeys,
    })

    options.push(...customFieldOptions)
    if (this.isEventTypeTracked) {
      const trackPointCustomFieldOptions = this.getCustomFieldTrackingPointRelationship()
      options.push(...trackPointCustomFieldOptions)
    }
    return reFormatCustomFieldOptions(options)
  }

  getCustomFieldTrackingPointRelationship(): IFieldOption[] {
    let customFields = [] as ICustomFieldCustomValidation[]
    const trackPoints = this.$store.state.project.projectDetail.trackPoints as Record<string, ITrackpoint>
    for (const from in trackPoints) {
      if (from !== this.customEventNotificationData.trackPointRoute?.from) {
        continue
      }
      const fromName = trackPoints[from].name || ''
      for (const to in trackPoints[from]?.trackPointForms) {
        if (to !== this.customEventNotificationData.trackPointRoute.to) {
          continue
        }
        const toName = trackPoints[to].name || ''
        const trackPointForm = trackPoints?.[from].trackPointForms?.[to] as ITrackPointForms

        const data = Object.entries(trackPointForm.customFields ?? {}).map(([key, field]) => ({
          ...field,
          fieldTypeKey: field.fieldType,
          customFieldKey: key,
          fromName,
          toName,
        })) as ICustomFieldCustomValidation[]
        customFields = customFields.concat(data)
      }
    }
    const replaceLabelKeys = [{ from: 'barcode_type.tracking', to: 'barcode_type.display_tracking_time' }]
    return getTrackingCustomFieldOptionsWithoutIndex({
      fields: customFields,
      excludeFieldType: this.excludedFieldType,
      replaceLabelKeys,
    })
  }

  created() {
    const customEventNotificationData = cloneDeep(this.customEventNotification) as ICustomNotificationEvent
    this.customEventNotificationData = customEventNotificationData
    this.changeEventType()
  }

  showOperator(operator: string) {
    return operator === COMPARE_METHOD_VALUE.equal ? '=' : '<>'
  }

  showLogicalOperator(operator: string) {
    return operator === LOGICAL_OPERATOR_VALUE.and ? 'and' : 'or'
  }

  changeRouteFrom() {
    if (this.customEventNotificationData.trackPointRoute && this.customEventNotificationData.trackPointRoute.to) {
      this.customEventNotificationData.trackPointRoute.to = ''
      this.customEventNotificationData.triggerDateField = ''
    }
  }

  @Watch('customEventNotificationData.eventType')
  changeEventType() {
    if (this.customEventNotificationData.eventType === EEventType.TRACKING) {
      this.rules.trackPointRoute = {
        from: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
        to: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
      }
    } else {
      delete this.rules.trackPointRoute
      this.customEventNotificationData.trackPointRoute = { from: '', to: '' }
    }

    this.customFieldFromOptions = this.getCustomFieldFromOptions()
  }

  closeDialog() {
    this.$emit('dialog:close')
  }

  onSave() {
    this.isSaveDisabled = true
    this.$refs.customEventNotificationForm.validate(async (valid: string) => {
      if (valid) {
        this.loading = true
        let isEmailValid = false

        if (this.hasEmail) this.$refs.emailForm.validate((valid: string) => (isEmailValid = !!valid))
        else {
          isEmailValid = true
          if (!isEmailValid) return
          const newRecord = cloneDeep(this.customEventNotificationData)
          newRecord.emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
          newRecord.emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]
          this.customEventNotificationData = {
            ...this.customEventNotificationData,
            ...newRecord,
          }
          this.$emit(
            'update:customEventNotification',
            cloneDeep(this.formatNotificationRoute(this.customEventNotificationData))
          )
          this.closeDialog()
        }
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  formatNotificationRoute(data: ICustomNotificationEvent): ICustomNotificationEvent {
    if (data.eventType !== EEventType.TRACKING) {
      delete data.trackPointRoute
    }
    return data
  }
}
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 100%;

  &__label {
    width: 170px !important;
    line-height: 20px !important;
  }
}
.custom-dialog:deep() {
  .pre-line {
    & .el-form-item__label {
      line-height: 20px !important;
    }
  }

  &.el-overlay {
    .el-dialog {
      &.el-dialog--custom {
        width: 1000px !important;
        max-width: 1000px !important;
        margin-top: 0px !important;
      }
    }
  }
}
</style>
