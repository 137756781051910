
import ActivationDataSyntaxSelectBox from '@/components/project/ActivationDataSyntaxSelectBox.vue'
import { FIELD_TYPE } from 'smartbarcode-web-core/src/utils/constants'
import { EDisplaySetting } from 'smartbarcode-web-core/src/utils/enums/index'
import { IUiConfig } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { ActivationDataSyntaxSelectBox },
  name: 'BarcodeUIConfig',
})
export default class BarcodeUIConfig extends Vue {
  @PropSync('uiConfig', { type: Object, default: {} }) _uiConfig!: IUiConfig

  basicUIConfig = [
    'qrCodeVisibility',
    'trackpointFlowchartVisibility',
    'tracingVisibility',
    'parentBarcodeVisibility',
    'childrenBarcodeVisibility',
    'previewAddTrackingVisiblity',
  ]

  defaultOptions: string[] = ['visible', 'visibleWhenLoggedIn', 'hidden']
  excludedFieldType = [FIELD_TYPE.ESIGN, FIELD_TYPE.LOCATION, FIELD_TYPE.FILES]

  @Watch('_uiConfig')
  formatData() {
    this._uiConfig.qrCodeVisibility =
      this._uiConfig.qrCodeVisibility === EDisplaySetting.UNKNOWN
        ? EDisplaySetting.VISIBLE
        : this._uiConfig.qrCodeVisibility
  }
}
