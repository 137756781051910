
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { ICountryCode, IPredefinedContact } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
@Options({
  emits: ['update:modelValue'],
  name: 'PredefinedContactsBlock',
})
export default class PredefinedContactsBlock extends Vue {
  @PropSync('modelValue', { type: Object }) modelValue_!: IPredefinedContact

  predefinedContacts = {} as IPredefinedContact

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST as Record<string, ICountryCode>,
      allCountries: OTHER_COUNTRY_LIST as Record<string, ICountryCode>,
    }
  }

  getCountryName(country: ICountryCode) {
    return `${this.$t(`countryList.${country.code}`)}${country.dialCode ? ` (${country.dialCode})` : ''}`
  }

  onSelectedCountryChanged(idx: number) {
    this.predefinedContacts.numberContacts[idx].number = ''
  }

  @Watch('modelValue_')
  modelValueChanged() {
    const cleanModelValue = this.cleanPredefinedContacts(this.modelValue_)
    const cleanPredefinedContacts = this.cleanPredefinedContacts(this.predefinedContacts)

    if (!isEqual(cleanModelValue, cleanPredefinedContacts)) this.predefinedContacts = cloneDeep(this.modelValue_)

    if (isEmpty(this.predefinedContacts.emails)) this.predefinedContacts.emails = ['']
    if (isEmpty(this.predefinedContacts.numberContacts)) {
      this.predefinedContacts.numberContacts = [{ name: '', number: '', countryCode: 'JP' }]
    }
  }

  cleanPredefinedContacts(predefinedContacts: IPredefinedContact) {
    return {
      emails: (predefinedContacts.emails ?? []).filter((i) => !isEmpty(i.replaceAll(' ', ''))),
      numberContacts: (predefinedContacts.numberContacts ?? []).filter(
        (i) => !isEmpty(i.name.replaceAll(' ', '')) && !isEmpty(i.number.replaceAll(' ', ''))
      ),
    }
  }

  @Watch('predefinedContacts', { deep: true })
  predefinedContactsChanged() {
    this.debouncedWatch()
  }

  addEmail() {
    this.predefinedContacts.emails.push('')
  }

  removeEmail(idx: number) {
    this.predefinedContacts.emails.splice(idx, 1)
  }

  addPhoneNo() {
    this.predefinedContacts.numberContacts.push({ name: '', number: '', countryCode: 'JP' })
  }

  removePhoneNo(idx: number) {
    this.predefinedContacts.numberContacts.splice(idx, 1)
  }

  created() {
    this.debouncedWatch = debounce(() => {
      this.modelValue_ = cloneDeep(this.cleanPredefinedContacts(this.predefinedContacts))
    }, 1000)
  }
}
