
import IconNotFound from '@/components/svg/IconNotFound.vue'
import IconUserGroup from '@/components/svg/IconUserGroup.vue'
import { PROJECT_NEW_KEY_ID } from '@/utils/constants'
import { ICreateUserGroup, IPermission, ITrackPointKeyVal, ITrackPointPermissions } from 'smartbarcode-web-core/src/utils/types/index'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { IconUserGroup, IconNotFound },
  directives: { maska },
  name: 'PreviewGroupUsers',
  emits: ['update:UserGroupHeight'],
})
export default class PreviewGroupUsers extends Vue {
  @Prop({ type: Object }) readonly groupUsers!: ICreateUserGroup[]
  @Prop({ type: Array }) arrTrackPoints!: ITrackPointKeyVal[]

  maxUserGroup = 1

  get trackPointsKey(): string[] {
    return this.arrTrackPoints.map((val) => val.key)
  }

  get newKeyProject() {
    return PROJECT_NEW_KEY_ID
  }

  groupPermissionTrackPoint(key: string): string[] {
    const groupAtTrackpoints: string[] = []
    this.groupUsers.forEach((val) => {
      const projectPermission = val.projectPermissions?.[this.newKeyProject] as IPermission
      const permission = projectPermission.trackingPermissions?.[key] as ITrackPointPermissions
      if (permission && permission.canEdit) {
        groupAtTrackpoints.push(val.name)
      }
    })
    if (groupAtTrackpoints.length > this.maxUserGroup) {
      this.maxUserGroup = groupAtTrackpoints.length
    }
    return groupAtTrackpoints
  }

  mounted() {
    let heightUserGroup = this.maxUserGroup * 40
    if (heightUserGroup < 96) {
      heightUserGroup = 96
    }
    this.$emit('update:UserGroupHeight', heightUserGroup)
  }
}
