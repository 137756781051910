<template>
  <el-form :disabled="isReadOnlyMode">
    <div class="mt-2">
      <div v-if="isLocationDataAvailable" class="overflow-x-auto">
        <table class="w-full rounded text-center">
          <thead class="bg-gray-200 word-break">
            <tr class="bg-gray-custom">
              <th class="border-solid border border-gray-300 px-5 py-6 text-center" :colspan="2">
                {{
                  `${$t('operation')
                    .toString()
                    .charAt(0)
                    .toUpperCase()}${$t('operation')
                    .toString()
                    .slice(1)
                    .toLowerCase()}`
                }}
              </th>
              <th
                v-for="(tp, idx) in filteredTrackpoints"
                :key="idx"
                class="border-solid border border-gray-300 px-5 py-4 text-center align-middle"
              >
                {{ tp.name }}
              </th>
              <th class="border-solid border border-gray-300 px-5 py-4 text-center align-middle">
                {{ $t('location.setting') }}
              </th>
            </tr>
          </thead>
          <tbody class="permission-tab overflow-auto">
            <template v-for="(location, groupKey) in _locations" :key="groupKey">
              <tr :class="groupKey % 2 === 0 ? 'bg-white' : 'blue-background-access-group'">
                <td
                  class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 whitespace-nowrap align-middle"
                  :rowspan="2"
                >
                  <div class="flex flex-col items-center text-center">
                    {{ location.locationName }}
                    <el-popover placement="bottom-end" :width="200" trigger="hover" popper-class="popover-barcode-list">
                      <template #reference>
                        <div class="w-5 h-5 mt-4 cover-svg-icon">
                          <IconVertialThreeDotCircle />
                        </div>
                      </template>
                      <div
                        @click="$emit('onEditLocation', groupKey)"
                        :class="['cursor-pointer text-center', 'text-black']"
                      >
                        {{ $t('projects.changeName') }}
                      </div>
                      <div class="border-solid border-b-1 border-gray-300 my-2"></div>
                      <div @click="$emit('onRemoveLocation', groupKey)" class="cursor-pointer text-center text-red-500">
                        {{ $t('delete') }}
                      </div>
                    </el-popover>
                  </div>
                </td>
                <td class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center whitespace-nowrap">
                  {{ $t('location.stock_in') }}
                </td>
                <td
                  v-for="(item, idx) in location.trackingPermissions"
                  :key="idx"
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                >
                  <el-checkbox v-model="item.canInStock" />
                </td>
                <td
                  class="px-6 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center align-middle"
                  :rowspan="2"
                >
                  <el-button style="padding: 14px" type="primary" @click="$emit('onEditSettingClicked', groupKey)">
                    {{ $t('setting') }}
                  </el-button>
                </td>
              </tr>
              <tr :class="groupKey % 2 === 0 ? 'bg-white' : 'blue-background-access-group'">
                <td class="px-2 py-3 border-solid border-t-1 border-b-1 border-gray-300 text-center whitespace-nowrap">
                  {{ $t('location.stock_out') }}
                </td>
                <td
                  v-for="(item, idx) in location.trackingPermissions"
                  :key="idx"
                  class="px-2 py-3 border-solid border border-gray-300 text-center"
                >
                  <el-checkbox v-model="item.canOutStock" />
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <ItemNotFound v-else :content="$t('location.no_data')" />
    </div>
  </el-form>
</template>

<script lang="ts">
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import IconVertialThreeDotCircle from '@/components/svg/IconVertialThreeDotCircle.vue'
import { IProjectLocation, ITrackpoint } from 'smartbarcode-web-core/src/utils/types/index'
import isEmpty from 'lodash/isEmpty'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  components: { IconNotFound, BaseInput, Dialog, ItemNotFound, IconVertialThreeDotCircle },
  directives: { maska },
  emits: ['onEditSettingClicked', 'update:tableLocation', 'onEditLocation', 'onRemoveLocation'],
  name: 'LocationTable',
})
export default class LocationTable extends Vue {
  @PropSync('locations', { type: Object }) _locations?: IProjectLocation[]
  @Prop({ type: String }) readonly projectId!: string
  @Prop({ type: Object }) readonly trackpoints!: Record<string, ITrackpoint>

  get filteredTrackpoints(): Record<string, ITrackpoint> {
    return Object.keys(this.trackpoints)
      .filter((key) => !this.trackpoints[key].isEnd)
      .reduce(
        (acc, tpKey) => ({
          ...acc,
          [tpKey]: this.trackpoints[tpKey],
        }),
        {}
      )
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get isLocationDataAvailable() {
    return !isEmpty(this.locations) && !isEmpty(this.filteredTrackpoints)
  }

  created() {
    this.initData()
  }

  @Watch('_locations')
  initData() {
    const TPKeys = Object.keys(this.filteredTrackpoints)
    ;(this._locations ?? []).forEach((i) => {
      Object.keys(i.trackingPermissions).forEach((k) => {
        if (!TPKeys.includes(k)) {
          delete i.trackingPermissions[k]
        }
      })
    })
  }

  onUpdated() {
    this.$emit('update:tableLocation', this._locations)
  }
}
</script>
<style lang="scss" scoped>
.word-break {
  word-break: keep-all;
}
.permission-tab {
  max-height: calc(100vh - 180px - 60px);
}
.blue-background-access-group {
  background: #ecf7fb;
}
</style>
