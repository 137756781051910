
import BarcodeIcon from '@/components/BarcodeIcon.vue'
import BaseInput from '@/components/common/BaseInput.vue'
import ColorPicker from '@/components/composables/ColorPicker.vue'
import IconPicker from '@/components/IconPicker.vue'
import {
  BARCODE_TYPE_COLOR_PALLET,
  BARCODE_TYPE_WHITE_TEXT_PALLET,
  COLOR_BLACK,
  COLOR_WHITE,
  DEFAULT_BARCODE_TYPES,
} from '@/utils/constants'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums/index'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, PropSync, Ref, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:selectedBarcodeType', 'update:barcodeTypeCode'],
  components: {
    BarcodeIcon,
    IconPicker,
    BaseInput,
    ColorPicker,
  },
  name: 'BasicInfoBlock',
})
export default class BasicInfoBlock extends Vue {
  @PropSync('selectedBarcodeType', { type: Object }) readonly _selectedBarcodeType!: IBarcodeDefinitionType
  @PropSync('barcodeTypeCode', { type: String }) _barcodeTypeCode!: string
  @Prop({ type: Boolean }) readonly isReadOnly!: boolean
  @Prop({ type: String }) readonly oldBarcodeTypeCode!: string
  @Ref() barcodeTypeName!: HTMLInputElement

  isShowIconPicker = false
  codeEmptyErrMsg = ''
  isCodeValidated = true

  validate(value: string) {
    this.clearError()
    this.isCodeValidated = !!value?.match('^[a-zA-Z0-9_-]{3,64}$')

    return this.isCodeValidated
  }

  clearError() {
    this.isCodeValidated = true
  }

  created() {
    this.codeEmptyErrMsg = this.$t('code_empty_error')
  }

  get isEditProject() {
    return !!this.$store.state.project.projectDetail.mainInfo?.id
  }

  @Watch('selectedBarcodeType.backgroundColor', { immediate: true })
  onBackgroundColorChange() {
    this.selectedBarcodeType.backgroundColor =
      this.selectedBarcodeType.backgroundColor ?? DEFAULT_BARCODE_TYPES.default.backgroundColor
  }

  @Watch('selectedBarcodeType.foregroundColor', { immediate: true })
  onForegroundColorChange() {
    this.selectedBarcodeType.foregroundColor =
      this.selectedBarcodeType.foregroundColor ?? DEFAULT_BARCODE_TYPES.default.foregroundColor
  }

  @Watch('selectedBarcodeType', { immediate: true })
  onBCChanged() {
    this.$nextTick(() => this.barcodeTypeName.focus())
  }

  onShowIconPicker() {
    this.isShowIconPicker = true
  }

  get whiteTextColorPallet(): readonly string[] {
    return BARCODE_TYPE_WHITE_TEXT_PALLET
  }

  get colorPallet() {
    return BARCODE_TYPE_COLOR_PALLET
  }

  get userTypeKeys() {
    return EUserType
  }

  @Watch('_selectedBarcodeType.backgroundColor')
  isBackgroundColorChanged(color: string) {
    if (!this._selectedBarcodeType) return
    const whiteTextColor = this.whiteTextColorPallet.find((val) => val === color)
    this._selectedBarcodeType.foregroundColor = whiteTextColor ? COLOR_WHITE : COLOR_BLACK
  }
}
