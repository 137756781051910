<template>
  <div :key="selectedNodeIndex">
    <!-- trackingPointName -->
    <div class="p-5 flex items-center gap-4 rounded-md bg-light-gray">
      <div>
        <h4 class="font-bold"><span class="text-red-500">* </span>{{ $t('projects.trackingPointName') }}</h4>
      </div>
      <div class="flex-1">
        <el-input
          :maxlength="30"
          v-model="trackingPointLocal.name"
          @input="updateTrackingPointName"
          name="trackPointTab.name"
          class="w-pull"
          size="medium"
        />
      </div>

      <div>
        <el-button
          type="default"
          class="bg-red-min text-white focus:text-white"
          size="medium"
          @click="$emit('delete:node', selectedNodeIndex)"
        >
          {{ $t('delete') }}
        </el-button>
      </div>
    </div>

    <!-- Next tracking point form -->
    <div class="my-4 hint-text-message">
      {{ trackPointFormsArr.length > 1 ? $t('projects.changable_by_DaD_hint_message') : '' }}
    </div>
    <el-collapse accordion v-model="activeCollapse">
      <Draggable
        v-model="trackPointFormsArr"
        tag="transition-group"
        :component-data="draggableComponentData"
        v-bind="draggableBindingData"
        @start="drag = true"
        @end="drag = false"
        item-key="key"
      >
        <template #item="item">
          <div class="bg-light-gray">
            <TrackingPointRelationship
              :trackPointId="item.element.key"
              :index="item.index"
              :selectedNodeIndex="selectedNodeIndex"
              :trackPointFormsArr="trackPointFormsArr"
              :trackPointFormData="item.element"
              :trackPoints="trackPoints"
              v-model:isDraggable="isDraggable"
              @trackPointForm:update="updateTrackPointForm"
              @trackPointForm:delete="deleteTrackPointForm"
              @trackPointForm:updateKey="updateKeyTrackPointForm"
            />
          </div>
        </template>
      </Draggable>
    </el-collapse>
  </div>

  <!-- Add next tracking button -->
  <div class="mt-5">
    <el-button type="primary" icon="el-icon-plus" @click="addRelationship" :disabled="isDisabledAddRelationship">
      {{ $t('projects.addNextTrackingPoint') }}
    </el-button>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { ITrackingPointDeletePath, ITrackpoint, ITrackPointForms } from 'smartbarcode-web-core/src/utils/types/index'
import { getInitFieldRestriction } from '@/utils/helpers'
import Dialog from '@/components/customFields/Dialog.vue'
import TrackingPointRelationship from '@/components/project/trackingPoint/TrackingPointRelationship.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import Draggable from 'vuedraggable'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import debounce from 'lodash/debounce'
import { TRACKING_POINT_NAME_DEBOUNCE_TIME } from '@/utils/constants'
import { arrayToObject, objectToArray } from 'smartbarcode-web-core/src/utils/helpers'

interface ITrackPointFormsKeyVal {
  key: string
  value: ITrackPointForms
}

@Options({
  components: { TrackingPointRelationship, Dialog, InputOnlyNumber, Draggable },
  emits: ['delete:node', 'update:node', 'delete:path'],
  name: 'TrackingPointItem',
})
export default class TrackingPointItem extends Vue {
  @Prop() trackPoints!: Record<string, ITrackpoint>
  @Prop() selectedNodeIndex?: number
  @Prop() trackingPoint?: ITrackpoint

  drag = false

  oldOrderValue = 0 as number | undefined
  activeCollapse = '0'
  isShowDialog = false
  trackingPointLocal: ITrackpoint = {}
  trackPointFormsArr: ITrackPointFormsKeyVal[] = []
  isDraggable = false

  created() {
    this.getTrackPointForms()
    this.debounceUpdateName = debounce(() => this.updateNode(true, true), TRACKING_POINT_NAME_DEBOUNCE_TIME)
  }

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 100,
      disabled: !this.isDraggable || this.isReadOnlyMode,
      ghostClass: 'ghost',
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get isDisabledAddRelationship() {
    return Object.keys(this.trackPoints).length - 1 === this.trackPointFormsArr.length
  }

  updateTrackingPointName() {
    this.debounceUpdateName()
  }

  @Watch('trackingPoint')
  getTrackPointForms() {
    this.trackingPointLocal = { ...this.trackingPoint }
    this.oldOrderValue = this.trackingPointLocal.order

    const arrTrackPointForms = objectToArray<ITrackPointForms>(this.trackingPointLocal?.trackPointForms || {})
    arrTrackPointForms.sort((a, b) => (a.value.order && b.value.order ? a.value.order - b.value.order : 1))
    this.trackPointFormsArr = arrTrackPointForms
  }

  @Emit('update:node')
  updateNode(loadNode: boolean, loadLine: boolean) {
    return { ...this.trackingPointLocal, loadNode, loadLine }
  }

  addRelationship() {
    let keyTrackPointForm = ''
    for (const kTP in this.trackPoints) {
      const existTPF = this.trackPointFormsArr.filter((item) => item.key === kTP)
      if (isEmpty(existTPF) && kTP !== String(this.selectedNodeIndex)) {
        keyTrackPointForm = String(kTP)
        break
      }
    }
    if (keyTrackPointForm === '') {
      return false
    }

    const order = this.trackPointFormsArr[this.trackPointFormsArr.length - 1]?.value.order ?? 0
    const newItem = {
      order: order ? order + 1 : 1,
      location: getInitFieldRestriction(),
    } as ITrackPointForms

    this.trackPointFormsArr.push({ key: keyTrackPointForm, value: newItem })
    this.trackingPointLocal = {
      ...this.trackingPointLocal,
      trackPointForms: arrayToObject<ITrackPointForms>(this.trackPointFormsArr),
    }

    this.updateNode(false, true)
  }

  updateTrackPointForm(data: ITrackPointFormsKeyVal) {
    this.trackingPointLocal = {
      ...this.trackingPoint,
      trackPointForms: { ...this.trackingPoint?.trackPointForms, [data.key]: data.value },
    }

    this.updateNode(false, false)
  }

  deleteTrackPointForm(key: string) {
    const newArr = this.trackPointFormsArr.filter((item) => item.key !== key)
    this.trackingPointLocal = { ...this.trackingPointLocal, trackPointForms: arrayToObject<ITrackPointForms>(newArr) }
    this.updateNode(true, true)
    this.$emit('delete:path', {
      fromKey: this.selectedNodeIndex ?? '',
      toKey: key,
    } as ITrackingPointDeletePath)
  }

  updateKeyTrackPointForm() {
    this.trackingPointLocal = {
      ...this.trackingPointLocal,
      trackPointForms: arrayToObject<ITrackPointForms>(this.trackPointFormsArr),
    }
    this.updateNode(true, true)
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
.hint-text-message {
  font-size: 12px;
  color: $items-light-grey;
}
.bg-light-grey-portal {
  background-color: #ccc;
}
.change-order-btn {
  white-space: nowrap;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #2f80ed;
    border: 1px solid #2f80ed;
    color: $pure-white;
  }
}

:deep() {
  .el-collapse {
    border: none !important;

    &__button-delete {
      position: relative;
      z-index: 100;
    }

    .el-collapse-item {
      margin: 2px 0px 2px 0px;
      border: none;
      border-radius: 5px;
      position: relative;

      &__header {
        background-color: inherit;
        border: none;
      }

      &__arrow {
        position: absolute;
        right: 45px;
        font-weight: bold;
        font-size: 16px;
      }

      &__wrap {
        background-color: inherit;
        border: 1px solid $light-grey;
      }
    }
  }
}

.flip-list-move {
  transition: transform 0s;
}
.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.bg-light-gray {
  background-color: $light-gray;
}
</style>
