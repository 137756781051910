
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import LocationPermissionDialog from '@/components/common/LocationPermissionDialog.vue'
import LocationTable from '@/components/common/LocationTable.vue'
import PermissionDialogSetting from '@/components/common/PermissionDialogSetting.vue'
import SimpleNameDialog from '@/components/common/SimpleNameDialog.vue'
import Dialog from '@/components/customFields/Dialog.vue'
import IconNotFound from '@/components/svg/IconNotFound.vue'
import { getLocationList } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { maska } from 'maska'
import {
  IBarcodeDefinitionType,
  ILocation,
  IProjectLocation,
  ITrackpoint,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

export const NONE = 'NONE'

@Options({
  components: {
    IconNotFound,
    BaseInput,
    Dialog,
    ItemNotFound,
    LocationTable,
    PermissionDialogSetting,
    LocationPermissionDialog,
    SimpleNameDialog,
  },
  directives: { maska },
  emits: ['update:location'],
  name: 'LocationTab',
})
export default class LocationTab extends Vue {
  arrTrackPoints: ITrackPointKeyVal[] = []

  isShowLocationNameDialog = false
  isShowLocationPermissionDialog = false

  locations = [] as IProjectLocation[]
  @Watch('$store.state.project.locations')
  onOriginLocationsChanged(locations: IProjectLocation[]) {
    this.locations = cloneDeep(locations) ?? []
  }

  @Watch('locations', { deep: true })
  onLocationsModelChanged() {
    this.$emit('update:location', this.locations)
  }

  get projectDetail() {
    return this.$store.state.project.projectDetail
  }

  get trackpoints(): Record<string, ITrackpoint> {
    return this.$store.state.project.projectDetail.trackPoints || {}
  }

  get barcodeTypes(): Record<string, IBarcodeDefinitionType> {
    return this.$store.state.project.projectDetail.barcodeTypes || {}
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  selectedPermission = {} as IProjectLocation
  selectedPermissionIndex = -1

  get defaultLocation() {
    return {
      locationId: '',
      locationName: '',
      projectId: '',
      availableBarcodeTypes: [],
      trackingPermissions: Object.keys(this.trackpoints ?? {})
        .filter((k) => !this.trackpoints[k].isEnd)
        .reduce(
          (acc, idx: string) => ({
            ...acc,
            [idx]: { CanInStock: false, canOutStock: false },
          }),
          {}
        ),
      locationItems: [],
    }
  }

  updatePermission(permission: IProjectLocation) {
    this.selectedPermission = {} as IProjectLocation
    const idx = this.locations.findIndex((i) => i.locationId === permission.locationId)
    this.locations[idx] = {
      ...this.defaultLocation,
      ...permission,
    }
  }

  selectedLocationId = NONE
  get isDisabledLocationList() {
    return isEmpty(this.locationOptions) || this.selectedLocationId === NONE
  }

  onAddLocationPermission() {
    const location = this.locationOptions.find((i) => i.id === this.selectedLocationId)
    this.locations.push({
      ...cloneDeep(this.defaultLocation),
      locationName: location?.name ?? '',
      locationId: location?.id ?? '',
      projectId: this.projectDetail.mainInfo.id,
    })
  }

  onEditLocationPermission(idx: number) {
    this.selectedPermission = { ...this.locations[idx] } as IProjectLocation
    this.selectedPermissionIndex = idx

    this.isShowLocationPermissionDialog = true
  }

  // Location Name
  locationName = ''

  addingLocation?: IProjectLocation = undefined
  onCreateNewLocation() {
    this.isShowLocationNameDialog = true
    const newLocation = cloneDeep(this.defaultLocation)
    this.addingLocation = newLocation
  }

  cancelLocationNameDialog() {
    this.addingLocation = undefined
    this.editingLocation = undefined
    this.locationName = ''

    this.isShowLocationNameDialog = false
  }

  editingLocation?: IProjectLocation = undefined
  @Watch('locationName')
  locationNameChanged(name: string) {
    if (this.editingLocation && name !== this.editingLocation.locationName) {
      this.editingLocation.locationName = name

      this.cancelLocationNameDialog()
    }

    if (this.addingLocation) {
      this.addingLocation.locationName = name
      this.locations.push(this.addingLocation)

      this.cancelLocationNameDialog()
    }
  }

  onEditLocation(idx: number) {
    this.editingLocation = this.locations[idx]
    this.locationName = this.editingLocation?.locationName ?? ''
    this.isShowLocationNameDialog = true
  }
  // end Location Name

  onRemoveLocation(idx: number) {
    this.locations.splice(idx, 1)
  }

  @Watch('trackpoints', { deep: true })
  rebuildTrackingPermission() {
    const tpkeys = Object.keys(this.trackpoints ?? {}).filter((k) => !this.trackpoints[k].isEnd)
    this.locations.forEach((location) => {
      location.trackingPermissions = tpkeys.reduce(
        (acc, k) => ({
          ...acc,
          [k]: location.trackingPermissions[k] ?? { CanInStock: false, canOutStock: false },
        }),
        {}
      )
    })
  }

  onAddNewNode() {
    // rebuild permission will run operation instead
  }

  reindexLocationGroup(node: string) {
    this.locations.map((location: IProjectLocation) => {
      for (const idx in location.trackingPermissions) {
        if (idx === node) {
          delete location.trackingPermissions[idx]
        } else if (Number(idx) > Number(node)) {
          const existItem = location.trackingPermissions[idx]
          delete location.trackingPermissions[idx]
          location.trackingPermissions[`${Number(idx) - 1}`] = existItem
        }
      }
    })
  }

  locationOptions = [] as ILocation[]
  async fetchLocations() {
    try {
      this.locationOptions = (await getLocationList({ isSummarize: true })).results
    } catch (err) {
      errorHandler(err)
    }
  }

  async created() {
    await this.fetchLocations()
  }
}
