<template>
  <div class="h-full w-full" v-loading.fullscreen="loading">
    <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader
          :isHideRefresh="true"
          :title="$t(`carrier.${id ? 'carrier_edit' : 'carrier_register'}`)"
          @load:refreshList="initStaffEdit"
        />
        <div class="flex flext-row flex-grow pl-6">
          <el-button
            type="primary"
            class="btn-original rounded-full w-28"
            :disabled="isSaveDisabled"
            @click="onCreate('carrierForm')"
          >
            {{ $t('save') }}
          </el-button>
        </div>
      </div>
      <div class="header-container__right">
        <el-button type="primary" @click="goToListStaff">
          {{ $t('carrier.list_staff') }}
        </el-button>
        <el-button type="primary" @click="goToCarrierPermission">
          {{ $t('carrier.permission_group_list') }}
        </el-button>
      </div>
    </div>
    <div class="py-8 text-lg">
      <div class="w-10/12 text-lg common-form-portal">
        <el-form
          class="form-container"
          status-icon
          :label-position="'right'"
          label-width="220px"
          :rules="rules"
          :model="staffDataModel"
          ref="carrierForm"
        >
          <div class="flex">
            <div class="w-6/12">
              <el-form-item prop="lastName" :label="$t('carrier.lastname')">
                <el-input
                  class="w-full"
                  v-model="staffDataModel.lastName"
                  name="lastName"
                  autocomplete="on"
                  size="medium"
                />
              </el-form-item>
            </div>

            <div class="w-6/12">
              <el-form-item prop="firstName" :label="$t('carrier.firstname')">
                <el-input
                  class="w-full"
                  v-model="staffDataModel.firstName"
                  name="firstName"
                  autocomplete="on"
                  size="medium"
                />
              </el-form-item>
            </div>
          </div>
          <el-form-item prop="phone.countryCode" :label="$t('carrier.phone_number')">
            <div class="flex">
              <div class="w-1/3">
                <el-form-item prop="phone.countryCode">
                  <el-select
                    :placeholder="$t('barcode_type.select')"
                    filterable
                    v-model="staffDataModel.phone.countryCode"
                    class="w-full"
                    size="medium"
                    @change="updatePhoneNumber"
                  >
                    <el-option-group
                      v-for="(group, groupKey) in countryList"
                      :key="groupKey"
                      :label="$t(`countryGroup.${groupKey}`)"
                    >
                      <el-option
                        v-for="(country, key) in group"
                        :key="key"
                        :value="key"
                        :label="getCountryName(country)"
                      >
                        {{ getCountryName(country) }}
                      </el-option>
                    </el-option-group>
                  </el-select>
                </el-form-item>
              </div>
              <div class="w-2/3">
                <el-form-item prop="phone.number">
                  <PhoneInputOnlyNumber v-model="staffDataModel.phone.number" name="phoneNumber" />
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="remarks" :label="$t('carrier.remarks')">
            <el-input
              minlength="0"
              maxlength="100"
              v-model="staffDataModel.remarks"
              name="remarks"
              autocomplete="on"
              size="medium"
              :show-word-limit="true"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('Projects')">
            <div class="flex">
              <div class="flex flex-grow overflow-x-auto h-14 overflow-y-hidden">
                <el-button
                  v-for="(item, idx) in projectList"
                  :key="idx"
                  class="p-3 border-gray-400 h-10"
                  :class="[idx === currentSelectedProjectIndex ? 'is-selected-barcode' : 'is-not-selected-barcode']"
                  @click="onSelectProject(idx)"
                  >{{ item.name }}</el-button
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="$t('permissions.group')">
            <div class="flex flex-row">
              <div class="flex flex-col">
                <el-checkbox
                  v-for="group in displayGroups"
                  :key="group"
                  v-model="group.isCheck"
                  name="label"
                  class="w-72"
                >
                  {{ group.name }}
                </el-checkbox>
              </div>
            </div>
          </el-form-item>
          <el-form-item prop="isActive" :label="$t('status')">
            <el-checkbox v-model="staffDataModel.isActive">{{ $t('active') }}</el-checkbox>
          </el-form-item>
          <el-form-item v-if="isSetDisplayedBarcodeByClient" :label="$t('barcode_type.client')">
            <el-transfer
              v-model="staffDataModel.clientIds"
              :filter-placeholder="$t('barcode_type.client')"
              :titles="[$t('unassigned'), $t('assigned')]"
              :props="{
                key: 'id',
                label: 'fullName',
              }"
              :data="getClientsList"
              filterable
            />
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import PageHeader from '@/components/common/PageHeader.vue'
import PhoneInputOnlyNumber from '@/components/common/PhoneInputOnlyNumber.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { FETCH_STAFF, LOAD_CLIENT_LIST, SET_PAGE_NOT_FOUND } from '@/store/actions'
import { createNewStaff, fetchStaffById, getProjectsList, getUserGroups, updateStaff } from '@/utils/api'
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import { removeCharacterPhoneNumber } from '@/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBaseContact,
  IBaseUserInfo,
  ICarrierPermission,
  IClientList,
  ICountryCode,
  ICreatedAuditData,
  ICreateUserGroup,
  IProject,
  IStaff,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import { maska } from 'maska'
import { mixins, Options } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { EUserType } from 'smartbarcode-web-core/src/utils/enums'

@Options({
  components: { PageHeader, PhoneInputOnlyNumber },
  directives: { maska },
  name: 'CarrierForm',
})
export default class CarrierForm extends mixins(ValidateForm) {
  projectList = [] as IProject[]
  currentSelectedProjectIndex = 0

  isSaveDisabled = false
  error = ''
  loading = false
  requiredMessage = ''
  staffDataModel: IStaff = {
    firstName: '',
    lastName: '',
    remarks: '',
    clientIds: [],
    phone: {
      number: '',
      countryCode: 'JP',
    },
    userGroupIds: [],
    isActive: true,
  }

  staffKeys = [] as string[]

  get isSetDisplayedBarcodeByClient() {
    return this.$store.state.profile?.organization?.setDisplayedBarcodeByClient || false
  }

  get getClientsList(): (
    | (IBaseUserInfo &
        IBaseContact & {
          roleType: string
          userType: EUserType
          accessCode: string
          organizationId: string
          userGroupIds: string
          fullName: string
          audit: ICreatedAuditData
          isActive: boolean
        })
    | undefined
  )[] {
    const clientIdsLength = this.staffDataModel.clientIds?.length || 0
    const clientList = [...this.$store.state.client.clients].map((val: IClientList) => {
      if (val.clientUser) {
        return {
          ...val.clientUser,
          disabled: clientIdsLength > 0 && !this.staffDataModel.clientIds?.includes(val.clientUser.id || ''),
        }
      }
    })
    return clientList
  }

  @Watch('staffDataModel.phone.number')
  onPhoneChange() {
    const phoneNumber = parsePhoneNumber(
      this.staffDataModel.phone.number || '',
      this.staffDataModel.phone.countryCode as CountryCode
    )
    if (phoneNumber) {
      this.staffDataModel.phone.number = new AsYouType(this.staffDataModel.phone.countryCode as CountryCode)
        .input(this.staffDataModel.phone.number || '')
        .replaceAll(' ', '-')
    }
  }

  formatPhoneValidate = (rule: string, value: string, callback: Function) => {
    if (isEmpty(value)) {
      return callback(new Error(this.messages.required))
    }

    const phoneNumber = parsePhoneNumber(
      this.staffDataModel.phone.number || '',
      this.staffDataModel.phone.countryCode as CountryCode
    )

    if (phoneNumber) {
      if (phoneNumber.isValid()) {
        callback()
      } else {
        callback(new Error(this.messages.number))
      }
    } else {
      callback(new Error(this.messages.number))
    }
  }

  groups: ICarrierPermission[] = []
  rules = {
    firstName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    lastName: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    phone: {
      number: [{ required: true, validator: this.formatPhoneValidate, trigger: 'blur' }],
      countryCode: [{ required: true, validator: this.requiredValidate, trigger: 'change' }],
    },
  }

  get id() {
    return this.$route.params.id
  }

  get displayGroups() {
    const displayGroups = [] as ICarrierPermission[]
    if (this.projectList) {
      const projectId = this.projectList?.[this.currentSelectedProjectIndex]?.id as string
      this.groups.forEach((item: ICarrierPermission) => {
        if (item.projectIds.includes(projectId)) {
          displayGroups.push(item)
        }
      })
    }
    return displayGroups
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST,
      allCountries: OTHER_COUNTRY_LIST,
    } as {
      popularCountries: Record<string, ICountryCode>
      allCountries: Record<string, ICountryCode>
    }
  }

  updatePhoneNumber() {
    this.staffDataModel.phone.number = ''
  }

  async onCreate(formName: string) {
    this.isSaveDisabled = true
    this.$refs[formName].validate(async (valid: string) => {
      if (valid) {
        this.loading = true
        await this.onCreateNewStaff()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        this.isSaveDisabled = false
        return false
      }
    })
  }

  async initStaffEdit() {
    try {
      this.loading = true
      const promises = [getProjectsList(), getUserGroups()]

      let currentStaffGroup: string[] = []
      if (this.id) {
        promises.push(fetchStaffById(this.id))
      }

      if (this.isSetDisplayedBarcodeByClient) {
        promises.push(this.$store.dispatch(LOAD_CLIENT_LIST))
      }
      const [projectResult, userGroups, staff] = await Promise.all(promises)

      this.projectList = projectResult.projects || []
      if (staff) {
        currentStaffGroup = staff.userGroupIds || []
        if (!staff.phone) {
          staff.phone = {
            number: '',
            countryCode: 'JP',
          }
        }
        this.staffDataModel = staff
      }

      let currentSelectedProjectIndex = -1
      const allDisplayProjectIds = [] as string[]
      this.groups = userGroups
        .filter((item: ICreateUserGroup) => {
          return item.isActive
        })
        .map((val: ICreateUserGroup) => {
          const projectIds = [] as string[]
          let seletedProjectIndex = -1
          const userGroupProjectIds = Object.keys(val.projectPermissions) as string[]
          const projects = this.projectList as IProject[]
          projects.forEach((item: IProject, index: number) => {
            if (userGroupProjectIds.includes(item.id as string)) {
              projectIds.push(item.id as string)

              if (!allDisplayProjectIds.includes(item.id as string)) {
                allDisplayProjectIds.push(item.id as string)
              }

              if (seletedProjectIndex === -1) {
                seletedProjectIndex = index
              }
            }
          })

          let isCheck = false
          if (this.id) {
            currentStaffGroup.forEach((el) => {
              if (val.id === el) {
                isCheck = true
              }
            })
          }

          if (isCheck && (seletedProjectIndex < currentSelectedProjectIndex || currentSelectedProjectIndex === -1)) {
            currentSelectedProjectIndex = seletedProjectIndex
          }

          return {
            name: val.name,
            id: val.id,
            isCheck: isCheck,
            projectIds: projectIds,
          }
        })

      this.projectList = this.projectList.filter((item) => {
        return allDisplayProjectIds.includes(item.id as string)
      })
      this.currentSelectedProjectIndex = currentSelectedProjectIndex !== -1 ? currentSelectedProjectIndex : 0
    } catch (error) {
      this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'carrier.staff_name' })
    } finally {
      this.loading = false
    }
  }

  onSelectProject(index: number) {
    this.currentSelectedProjectIndex = index
  }

  async created() {
    await this.initStaffEdit()
  }

  goToListStaff() {
    this.$router.push({ name: 'carriers' })
  }

  goToCarrierPermission() {
    this.$router.push({ name: 'userGroup' })
  }

  async onCreateNewStaff() {
    try {
      const filteredId = this.groups.filter((val) => val.isCheck).map((val) => val.id)

      const requestModel = {
        firstName: this.staffDataModel.firstName,
        lastName: this.staffDataModel.lastName,
        clientIds: this.staffDataModel.clientIds,
        phone: {
          number: removeCharacterPhoneNumber(this.staffDataModel?.phone.number || ''),
          countryCode: this.staffDataModel?.phone.countryCode,
        },
        remarks: this.staffDataModel?.remarks,
        userGroupIds: filteredId,
      }

      this.id
        ? await updateStaff({
            ...requestModel,
            userId: this.id,
            isActive: this.staffDataModel?.isActive,
          })
        : await createNewStaff({ ...requestModel, userGroupIds: filteredId })

      openMessage(this.$t('save_successful'), 'success')

      await this.$store.dispatch(FETCH_STAFF)
      this.$router.push({ name: 'carriers' })
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

:deep() {
  .el-transfer-panel {
    width: 350px;
  }

  .el-form-item__content {
    width: 100px;
  }
}
</style>
