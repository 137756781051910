
import { ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, mixins } from 'vue-class-component'
import { Ref, Prop, PropSync } from 'vue-property-decorator'
import CustomFieldIconList from '@/components/CustomFieldIconList.vue'
import TrackPointDiagramMixin, { ILine } from '@/components/mixins/TrackPointDiagramMixin.vue'
import LeaderLine from 'vue3-leaderline'

@Options({
  emits: ['update:heightTrackPoint', 'dataFlow:drawDone'],
  components: { CustomFieldIconList },
  name: 'SequenceTrackPointDiagram',
})
export default class SequenceTrackPointDiagram extends mixins(TrackPointDiagramMixin) {
  @Prop({ type: Array }) readonly arrTrackPoints!: ITrackPointKeyVal[]
  @PropSync('heightTrackPoint', { type: Number }) _heightTrackPoint!: number

  @Ref() leaderLineWrapper!: HTMLElement
  @Ref() customFieldWrapper!: HTMLElement
  @Ref() flowViewContainer!: HTMLElement

  llStore: LeaderLine[] = [] as LeaderLine[]
  selectedBlock: HTMLElement | undefined = undefined
  nodeIdPrefix = 'track-point-'

  mounted() {
    this.$nextTick(async () => {
      await this.startDraw()
      this.fixWrapperPosition()
      this.setViewContainerStyle()

      this.moveLines()
      this.moveLabels()
      this.$emit('dataFlow:drawDone')
    })
  }

  customFieldBlockHovered(lineId: string) {
    const block = document.getElementById(`custom-field-list-${lineId}`)
    if (this.selectedBlock) {
      this.selectedBlock.style.borderColor = 'grey'
      this.selectedBlock.style.borderWidth = '1px'
      this.selectedBlock.style.zIndex = '0'
    }
    if (block) {
      block.style.borderColor = '#409EFF'
      block.style.zIndex = '50'
      this.selectedBlock = block
    }

    const svgLines = this.leaderLineWrapper.querySelectorAll('svg.leader-line')
    ;(this.lines as ILine[]).forEach((line, idx) =>
      this.changeLineColor(svgLines[idx] as SVGElement, line.id === lineId)
    )
  }

  changeLineColor(line: SVGElement, isActive: boolean) {
    const e = line as SVGElement
    e.style.zIndex = isActive ? '50' : '0'
    const gs = e.getElementsByTagName('use')
    const color = isActive ? '#409EFF' : '#cecece'
    for (const g of gs) {
      g.style.fill = color
      g.style.stroke = color
    }
  }

  fixWrapperPosition() {
    const rectWrapper = this.leaderLineWrapper.getBoundingClientRect()
    const transformStr = `translate(-${rectWrapper.left + window.pageXOffset}px, -${
      rectWrapper.top + this.viewContainerHeight / 2 + window.pageYOffset
    }px)`

    this.leaderLineWrapper.style.transform = transformStr
    this.customFieldWrapper.style.transform = transformStr
  }

  moveLines() {
    const lines = document.querySelectorAll('svg.leader-line')
    for (const line of lines) {
      if (!line.id.includes('TPS_leaderLine')) continue
      this.leaderLineWrapper.appendChild(line)
    }
  }

  get viewContainerHeight() {
    return this.lineGravity.top + this.lineGravity.bottom + 150 + 80 // Add more offset 150px
  }

  setViewContainerStyle() {
    this.flowViewContainer.style.height = `${this.viewContainerHeight}px`
    this._heightTrackPoint = this.viewContainerHeight

    this.positionLines()
  }

  positionLines() {
    this.llStore.forEach((ll) => ll.position())
  }

  startDraw() {
    return this.drawLines({
      diagramDirection: 'horizontal',
      levelHeight: 50,
      trackpointEntries: this.arrTrackPoints,
      nodeIdPrefix: this.nodeIdPrefix,
      lineIdPrefix: 'TPS_leaderLine_',
      lineColor: '#cecece',
      lineIdAtMiddleLabel: true,
    })
  }

  moveLabels() {
    const lines = this.leaderLineWrapper.querySelectorAll('svg.leader-line')
    lines.forEach((line, idx) => {
      if (!line.id.includes('TPS_leaderLine')) return

      const label = line.getElementsByTagName('text')[0]
      const lineData = this.lines[idx]

      if (!lineData) return
      const customFieldList = document.getElementById(`custom-field-list-${lineData.id}`)

      if (label.parentNode !== null && customFieldList !== null) {
        const rect = label.getBoundingClientRect()
        this.scrollOffset = window.pageYOffset
        customFieldList.style.position = 'absolute'
        customFieldList.style.zIndex = `${idx}`
        const cf = customFieldList.getBoundingClientRect()

        const customFieldHeight = customFieldList.offsetHeight
        customFieldList.style.top = `${rect.top - (cf.height + cf.top) + customFieldHeight / 2}px`
        customFieldList.style.left = `${rect.left}px`
      }

      label.innerHTML = ''
    })
  }
}
