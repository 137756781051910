<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="grid grid-cols-5 gap-4">
      <div class="col-span-2 font-bold">
        {{ $t('barcode_type.pair_or_unpair') }}
      </div>
      <div class="col-span-3">
        <div class="mb-3">
          <el-checkbox v-model="_allowToBePaired">
            {{ $t('barcode_type.allow_to_be_child_barcode') }}
          </el-checkbox>
        </div>
        <div>
          <el-checkbox v-model="_allowToPair">
            {{ $t('barcode_type.allow_to_be_parent_barcode') }}
          </el-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:allowToPair', 'update:allowToBePaired'],
  name: 'PairedAllowanceBlock',
})
export default class PairedAllowanceBlock extends Vue {
  @PropSync('allowToBePaired', { type: Boolean }) _allowToBePaired!: boolean
  @PropSync('allowToPair', { type: Boolean }) _allowToPair!: boolean
}
</script>
