
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { IRestrictedTrackPointRoutePath, ITrackPointForms, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import isEmpty from 'lodash/isEmpty'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
@Options({
  name: 'RestrictionRouteBlock',
})
export default class RestrictionRouteBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @PropSync('trackPointRouteRestrictedPaths', { type: Array, default: [] })
  _restrictedTrackPointRoutePath!: IRestrictedTrackPointRoutePath[]

  @Prop({ type: Array }) readonly overridableStartTrackPoints?: string[]

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this._restrictedTrackPointRoutePath.forEach((val, idx) => {
      const found = this.getNextTrackpointsForm(val.from).find((endTPRoute) => endTPRoute.key === val.to)
      if (!found && val.to) this.deleteRoutePath(idx)
    })
  }

  deleteRoutePath(idx: number) {
    this._restrictedTrackPointRoutePath.splice(idx, 1)
  }

  isDisableEndRestrictRoute(tp: ITrackPointKeyVal, startRestrictedRoute: string) {
    return !!this._restrictedTrackPointRoutePath.find((v) => v.from === startRestrictedRoute && v.to === tp.key)
  }

  isDisableStartRestrictRoute(trackpoint: ITrackPointKeyVal) {
    return (
      (this.overridableStartTrackPoints?.includes(trackpoint.key) && !this.isMultipleTrackPoint(trackpoint.key)) ||
      isEmpty(this.getEndRestrictRouteAvailable(trackpoint.key))
    )
  }

  getEndRestrictRouteAvailable(startRestrictedKey: string) {
    const found = this._restrictedTrackPointRoutePath.filter((v) => v.from === startRestrictedKey)
    return this.getNextTrackpointsForm(startRestrictedKey).filter((o) => !found.find((v) => v.to === o.key))
  }

  isMultipleTrackPoint(key: string) {
    return Object.keys(this.trackingPointDatasArr.find((o) => o.key === key)?.value.trackPointForms || {}).length > 1
  }

  onTrackpointRestrictChange(restrictRoute: IRestrictedTrackPointRoutePath) {
    restrictRoute.to = this.getEndRestrictRouteAvailable(restrictRoute.from)?.[0]?.key || ''
  }

  @Watch('trackingPointDatasArr')
  trackingPointDatasRestrictable(restrictRoute: IRestrictedTrackPointRoutePath, index: number) {
    let isFound = false
    const restrictableArray = this.trackingPointDatasArr.filter((val) => {
      if (val.key === restrictRoute.from) isFound = true
      return !val.value.isEnd
    })
    // if (!isFound && this._restrictedTrackPointRoutePath[index]) this._restrictedTrackPointRoutePath[index].from = ''

    return restrictableArray
  }

  @Watch('trackingPointDatasArr')
  getNextTrackpointsForm(key: string) {
    return objectToArray<ITrackPointForms>(
      this.trackingPointDatasArr.find((tp) => tp.key === key)?.value.trackPointForms || {}
    ).map((val) => ({
      key: val.key,
      value: {
        ...val.value,
        name: this.trackingPointDatasArr.find((tp) => tp.key === val.key)?.value.name,
      },
    }))
  }

  onAddRestrictRule() {
    this._restrictedTrackPointRoutePath = this._restrictedTrackPointRoutePath ?? []
    this._restrictedTrackPointRoutePath.push({
      from: '',
      to: '',
      allowChildrenToBypassRestriction: true,
    })
  }
}
