<template>
  <div class="border-b border-gray-400 border-solid py-8">
    <div class="grid grid-cols-5 gap-4 py-4">
      <div class="font-bold">
        {{ $t('barcode_type.barcode_recycle_setting') }}
      </div>
    </div>

    <div class="grid grid-cols-5 gap-x-4 py-4">
      <div class="col-span-2"></div>
      <div class="col-span-3">
        <!-- <div class="mb-2">
          <el-checkbox v-model="_isRecycleToLastestVersion" :disabled="recycleToLastestVersion">
            {{ $t('barcode_type.recycle_to_last_project_version') }}
          </el-checkbox>
        </div> -->
        <div>
          <el-checkbox v-model="_allowBarcodeRecycle"> {{ $t('Recycle') }} </el-checkbox>
        </div>
        <div class="flex">
          <el-checkbox class="self-center" v-model="enableRecycleAlert">
            {{ $t('barcode_type.use_recycle_notification') }}
          </el-checkbox>
          <el-button
            :disabled="!enableRecycleAlert"
            class="mx-2"
            type="primary"
            @click="openNotifSettingDialog"
            size="medium"
          >
            {{ $t('barcode_type.settings') }}
          </el-button>
        </div>
      </div>
    </div>
    <el-form :disabled="!allowBarcodeRecycle || isReadOnly">
      <TrackPointCheckboxesBlock
        :isLabelRTL="true"
        :label="$t('barcode_type.barcode_recyclable_track_points')"
        :isShowAll="true"
        v-model="_barcodeRecyclableTrackPoints"
      />

      <div class="grid grid-cols-5 gap-4 my-8">
        <div class="col-span-2 self-center right-to-left">
          {{ $t('barcode_type.barcode_recycle_start_track_point') }}
        </div>
        <div class="col-span-3">
          <el-select :placeholder="$t('barcode_type.select')" v-model="_barcodeRecycleStartTrackPoint">
            <el-option
              v-for="tp in trackingPointDataShown"
              :key="tp.key"
              :label="`${tp.value.name} (${tp.key})`"
              :value="tp.key"
            >
              {{ tp.value.name }} {{ `(${tp.key})` }}
            </el-option>
          </el-select>
        </div>
      </div>
    </el-form>
  </div>

  <div class="dialog-area">
    <el-dialog
      custom-class="el-dialog--custom"
      v-model="isShowNotifSettingDialog"
      width="700px"
      top="0"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="closeNotiSettingDialog"
    >
      <template #title> {{ $t('barcode_type.notification_settings') }} </template>
      <div>
        <div class="py-4">
          <el-form
            :model="currentNotificationSetting"
            status-icon
            :disabled="isReadOnly"
            ref="recycleAlertForm"
            :rules="emailRules"
          >
            <div class="grid grid-cols-9 gap-4 mt-6">
              <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.recycle_count_alert') }}</div>
              <div class="col-span-1">
                <el-form-item prop="recycleCountAlert">
                  <el-input-number
                    class="w-full"
                    size="medium"
                    :min="0"
                    v-model="currentNotificationSetting.recycleCountAlert"
                    :controls="false"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="grid grid-cols-9 gap-4 mt-4">
              <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.email_address') }}</div>
              <div class="col-span-7 flex flex-col">
                <div
                  v-for="(email, idx) in currentNotificationSetting.emailDestinations"
                  :key="idx"
                  class="flex flex-row"
                >
                  <div class="w-3/4">
                    <el-form-item
                      :prop="`emailDestinations[${idx}]`"
                      :rules="[
                        {
                          required: true,
                          validator: requiredEmailValidate,
                        },
                      ]"
                    >
                      <el-input size="medium" v-model="currentNotificationSetting.emailDestinations[idx]"></el-input>
                    </el-form-item>
                  </div>
                  <div class="w-20 pt-1 pl-3 grid justify-items-end">
                    <div>
                      <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmail(idx)">
                      </el-button>
                      <el-button
                        v-if="idx === currentNotificationSetting.emailDestinations.length - 1"
                        type="primary"
                        icon="el-icon-plus"
                        size="small"
                        circle
                        @click="addNewEmail()"
                      >
                      </el-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-9 gap-4 mt-2">
              <div class="col-span-2 flex items-start pre-line">
                <div class="h-10 flex justify-end items-center w-full text-right leading-5">
                  {{ $t('barcode_type.email_custom_field') }}
                </div>
              </div>
              <div class="col-span-7 flex flex-col">
                <div
                  v-for="(alias, idx) in currentNotificationSetting.emailDestinationAliases"
                  :key="idx"
                  :class="`flex flex-row ${idx !== 0 ? 'mt-4' : ''}`"
                >
                  <div class="w-3/4">
                    <SelectAutocomplete
                      v-model="currentNotificationSetting.emailDestinationAliases[idx]"
                      :options="emailCFs"
                    />
                  </div>
                  <div class="w-20 pt-1 pl-3 grid justify-items-end">
                    <div>
                      <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmailAlias(idx)" />
                      <el-button
                        v-if="idx === currentNotificationSetting.emailDestinationAliases.length - 1"
                        type="primary"
                        icon="el-icon-plus"
                        size="small"
                        circle
                        @click="addNewAliasEmail"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="grid grid-cols-9 gap-4 mt-6">
              <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.subject') }}</div>
              <div class="col-span-7">
                <el-form-item prop="emailSubject">
                  <el-input v-model="currentNotificationSetting.emailSubject" size="medium" />
                </el-form-item>
              </div>
            </div>

            <div class="grid grid-cols-9 gap-4 mt-2">
              <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.content') }}</div>
              <div class="col-span-7">
                <el-form-item prop="emailBody">
                  <el-input v-model="currentNotificationSetting.emailBody" type="textarea" :rows="4" />
                </el-form-item>
              </div>
            </div>
            <div class="grid grid-cols-9 gap-4 mt-2">
              <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.is_display_on_mobile') }}</div>
              <div class="col-span-7">
                <el-form-item prop="isDisplayOnMobile">
                  <el-checkbox v-model="currentNotificationSetting.isDisplayOnMobile" size="large">{{
                    $t('barcode_type.is_display_on_mobile')
                  }}</el-checkbox>
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="closeNotiSettingDialog">
              {{ $t('barcode_type.cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="addRecycleNotification">
              {{ $t('ok') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeRecycleAlertSetting, ICustomFieldFormData, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'
import TrackPointCheckboxesBlock from './TrackPointCheckboxesBlock.vue'
@Options({
  emits: [
    'update:allowBarcodeRecycle',
    'update:copyOriginOnRecycle',
    'update:copyDestinationOnRecycle',
    'update:copyPackageInfoOnRecycle',
    'update:barcodeRecycleStartTrackPoint',
    'update:barcodeRecyclableTrackPoints',
    'update:barcodeRecycleAlertSetting',
  ],
  components: { TrackPointCheckboxesBlock, InputOnlyNumber, SelectAutocomplete },
  name: 'RecycleBarcodeBlock',
})
export default class RecycleBarcodeBlock extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Boolean, default: false }) readonly isReadOnly!: boolean
  @Prop({ type: Boolean, default: false }) readonly recycleToLastestVersion!: boolean

  @PropSync('barcodeRecycleAlertSetting', { type: Object, default: {} })
  _barcodeRecycleAlertSetting!: IBarcodeRecycleAlertSetting

  @PropSync('allowBarcodeRecycle', { type: Boolean, default: false })
  _allowBarcodeRecycle!: boolean

  @PropSync('isRecycleToLastestVersion', { type: Boolean, default: false })
  _isRecycleToLastestVersion!: boolean

  @PropSync('barcodeRecycleStartTrackPoint', { type: String })
  _barcodeRecycleStartTrackPoint!: string

  @PropSync('barcodeRecyclableTrackPoints', { type: Array })
  _barcodeRecyclableTrackPoints!: string[]

  isShowNotifSettingDialog = false

  currentNotificationSetting: IBarcodeRecycleAlertSetting = {
    emailDestinations: [],
    emailDestinationAliases: [],
    recycleCountAlert: 0,
    emailSubject: '',
    emailBody: '',
  }

  emailRules = {
    recycleCountAlert: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get enableRecycleAlert() {
    return this._barcodeRecycleAlertSetting.recycleCountAlert > 0
  }

  set enableRecycleAlert(val: boolean) {
    this._barcodeRecycleAlertSetting = {
      recycleCountAlert: val ? 1 : 0,
      emailDestinations: [''],
      emailDestinationAliases: [''],
      emailSubject: '',
      emailBody: '',
    }
  }

  get isEmailAvail() {
    return !isEmpty(this.currentNotificationSetting.emailDestinations.filter((v) => v !== ''))
  }

  deleteEmail(idx: number) {
    if (this.currentNotificationSetting.emailDestinations.length < 2) return
    this.currentNotificationSetting.emailDestinations.splice(idx, 1)
  }

  deleteEmailAlias(idx: number) {
    if (this.currentNotificationSetting.emailDestinationAliases.length < 2) return
    this.currentNotificationSetting.emailDestinationAliases.splice(idx, 1)
  }

  addNewAliasEmail() {
    this.currentNotificationSetting.emailDestinationAliases.push('')
  }

  addNewEmail() {
    this.currentNotificationSetting.emailDestinations.push('')
  }

  openNotifSettingDialog() {
    this.isShowNotifSettingDialog = true
    this.currentNotificationSetting = cloneDeep(this._barcodeRecycleAlertSetting)
  }

  closeNotiSettingDialog() {
    this.isShowNotifSettingDialog = false
    this.currentNotificationSetting.emailDestinations = []
    this.currentNotificationSetting.emailDestinationAliases = []
    this.currentNotificationSetting.emailSubject = ''
    this.currentNotificationSetting.emailBody = ''
  }

  addRecycleNotification() {
    let isEmailValid = true

    this.$refs.recycleAlertForm.validate((valid: string) => (isEmailValid = !!valid))

    if (!isEmailValid) return

    const newRecord = cloneDeep(this.currentNotificationSetting)

    // remove duplicate records
    const emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
    const emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]
    newRecord.emailDestinations = emailDestinations.length > 0 ? emailDestinations : ['']
    newRecord.emailDestinationAliases = emailDestinationAliases.length > 0 ? emailDestinationAliases : ['']
    this._barcodeRecycleAlertSetting = newRecord || this._barcodeRecycleAlertSetting
    this.closeNotiSettingDialog()
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';

.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 700px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }

  .el-form-item {
    margin-bottom: 16px;

    &__item {
      top: calc(100% - 5px);
    }
  }
}

.right-to-left {
  direction: rtl;
}
</style>
