<template>
  <div>
    <el-dialog
      :custom-class="'el-dialog--custom'"
      v-model="_isShow"
      top="0"
      :destroy-on-close="false"
      :close-on-click-modal="false"
      :show-close="true"
      @close="onCancel"
    >
      <div class="flex items-center mb-5">
        <div class="text-right w-1/3 mr-4">{{ $t('location.name') }}</div>
        <div class="w-2/3">
          <BaseInput
            :error="errorMsg"
            :placeholder="$t('location.name')"
            v-model="displayName"
            @input="clearError()"
            @change="clearError()"
            @blur="validate()"
          />
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-cancel" @click="onCancel">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="onSave">{{ $t('ok') }}</el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import BaseInput from '@/components/common/BaseInput.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'

@Options({
  components: { BaseInput },
  emits: ['update:name', 'update:isShow', 'cancel'],
  name: 'SimpleNameDialog',
})
export default class SimpleNameDialog extends Vue {
  @PropSync('isShow', { type: Boolean }) _isShow!: boolean
  @PropSync('name', { type: String }) _name!: string

  displayName = ''
  errorMsg = ''

  onCancel() {
    this.$emit('cancel')
  }

  validate() {
    if (this.displayName !== '') return true
    this.errorMsg = this.$t('mandatory_field')
    return false
  }

  clearError() {
    this.errorMsg = ''
  }

  onSave() {
    if (!isEmpty(this.errorMsg)) return
    this._name = this.displayName
    this.displayName = ''
    this._isShow = false
  }

  @Watch('_name')
  onNameModelChanged() {
    this.displayName = this._name
  }
}
</script>
