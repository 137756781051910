<template>
  <div class="flex flex-col items-center justify-center border mt-5 p-5 border-solid border-gray-400 rounded-lg">
    <div class="flex w-full">
      <div class="flex-grow font-bold self-center">{{ $t('barcode_type.tracking_notification') }}</div>
      <el-button type="primary" icon="el-icon-plus" size="medium" @click="addTrackingNotification">
        {{ $t('barcode_type.add_new_route') }}
      </el-button>
    </div>
    <div v-if="notificationRecords.length !== 0" class="w-9/12">
      <div v-for="(notif, idx) in notificationRecords" :key="idx" class="mb-4 px-12">
        <div class="flex items-center gap-4 py-3">
          <div class="flex items-center flex-grow">
            <div class="mr-3">
              <el-select v-model="notif.from" @change="onFromChanged(notif)" :placeholder="$t('barcode_type.select')">
                <el-option
                  v-for="tpData in trackingPointDatasArr"
                  :key="`${tpData.value.name} (${tpData.key})`"
                  :value="tpData.key"
                  :label="`${tpData?.value?.name} (${tpData.key})`"
                  :disabled="isDisableFromOption(tpData.key)"
                >
                  {{ tpData.value.name }} {{ `(${tpData.key})` }}
                </el-option>
              </el-select>
            </div>
            <i class="el-icon-right mx-10"></i>
            <div class="mx-3">
              <el-select v-model="notif.to" :placeholder="$t('barcode_type.select')">
                <el-option
                  v-for="tpData in loadTP(notif.from)"
                  :key="`${tpData.value.name} (${tpData.key})`"
                  :value="tpData.key"
                  :label="`${tpData.value.name} (${tpData.key})`"
                  :disabled="isDisableToOption(notif.from, tpData.key)"
                >
                  {{ tpData.value.name }} {{ `(${tpData.key})` }}
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="mr-4 flex flex-row">
            <el-button class="mx-2" type="primary" @click="openNotifSettingDialog(idx)" size="medium">
              {{ $t('barcode_type.settings') }}
            </el-button>
            <el-button
              type="default"
              class="bg-red-min text-white focus:text-white mx-2"
              @click="deleteTrackingNotification(idx)"
              size="medium"
            >
              {{ $t('barcode_type.delete') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog-area">
      <el-dialog
        custom-class="el-dialog--custom"
        v-model="isShowNotifSettingDialog"
        width="700px"
        top="0"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        @close="closeNotifSettingDialog"
      >
        <template #title> {{ $t('barcode_type.notification_settings') }} </template>
        <div>
          <div class="border-b border-gray-400 border-solid py-4 mb-6">
            <div class="ml-14">
              <el-checkbox v-model="hasSMS" size="large">{{ $t('barcode_type.sms') }}</el-checkbox>
            </div>
            <el-form :disabled="!hasSMS" ref="smsForm" :model="currentNotificationRoute" :rules="smsRules">
              <!-- PHONE NUMBER -->
              <div class="grid grid-cols-9 gap-4 mt-4">
                <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.phone_number') }}</div>
                <div class="col-span-7 flex flex-col">
                  <div
                    v-for="(smsObj, idx) in currentNotificationRoute.smsDestinations"
                    :key="idx"
                    class="flex flex-row"
                  >
                    <div class="w-1/4">
                      <el-form-item :prop="`smsDestinations.${idx}.countryCode`">
                        <el-select
                          :placeholder="$t('barcode_type.select')"
                          v-model="smsObj.countryCode"
                          filterable
                          class="w-full"
                          size="medium"
                          @change="onSelectedCountryChanged(idx)"
                        >
                          <el-option-group
                            v-for="(group, groupKey) in countryList"
                            :key="groupKey"
                            :label="$t(`countryGroup.${groupKey}`)"
                          >
                            <el-option
                              v-for="(country, key) in group"
                              :key="key"
                              :value="key"
                              :label="getCountryName(country)"
                            >
                              {{ getCountryName(country) }}
                            </el-option>
                          </el-option-group>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div class="w-2/4">
                      <el-form-item
                        :prop="`smsDestinations.${idx}.number`"
                        :rules="[
                          {
                            required: isPhoneAvail || !isPhoneAliasAvail,
                            validator: isPhoneAvail || !isPhoneAliasAvail ? formatPhoneValidate : () => true,
                            countryCode: smsObj.countryCode,
                          },
                        ]"
                      >
                        <el-input size="medium" @change="onPhoneChanged(idx)" v-model="smsObj.number"></el-input>
                      </el-form-item>
                    </div>

                    <div class="w-20 pt-1 pl-3 grid justify-items-end">
                      <div>
                        <el-button type="info" icon="el-icon-minus" size="small" circle @click="deletePhoneNo(idx)" />
                        <el-button
                          v-if="idx === currentNotificationRoute.smsDestinations.length - 1"
                          type="primary"
                          icon="el-icon-plus"
                          size="small"
                          circle
                          @click="addNewPhoneNo"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- PHONE NUMBER CUSTOM FIELD -->
              <div class="grid grid-cols-9 gap-4 mt-2">
                <div class="col-span-2 flex items-start pre-line">
                  <div class="h-10 flex justify-end items-center w-full text-right leading-5">
                    {{ $t('barcode_type.phone_number_custom_field') }}
                  </div>
                </div>
                <div class="col-span-7 flex flex-col">
                  <div
                    v-for="(alias, idx) in currentNotificationRoute.smsDestinationAliases"
                    :key="idx"
                    :class="`flex flex-row ${idx !== 0 ? 'mt-4' : ''}`"
                  >
                    <div class="w-3/4">
                      <SelectAutocomplete
                        v-model="currentNotificationRoute.smsDestinationAliases[idx]"
                        :options="phoneNoCFs"
                      />
                    </div>
                    <div class="w-20 pt-1 pl-3 grid justify-items-end">
                      <div>
                        <el-button type="info" icon="el-icon-minus" size="small" circle @click="deletePhoneNoCF(idx)" />
                        <el-button
                          v-if="idx === currentNotificationRoute.smsDestinationAliases.length - 1"
                          type="primary"
                          icon="el-icon-plus"
                          size="small"
                          circle
                          @click="addNewPhoneNoCF"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-9 gap-4 mt-6">
                <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.content') }}</div>
                <div class="col-span-7">
                  <el-form-item prop="smsBody">
                    <el-input v-model="currentNotificationRoute.smsBody" type="textarea" :rows="4"></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </div>
          <div class="py-4">
            <div class="ml-14">
              <el-checkbox v-model="hasEmail" size="large">{{ $t('barcode_type.email') }}</el-checkbox>
            </div>
            <el-form :disabled="!hasEmail" ref="emailForm" :model="currentNotificationRoute" :rules="emailRules">
              <div class="grid grid-cols-9 gap-4 mt-4">
                <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.email_address') }}</div>
                <div class="col-span-7 flex flex-col">
                  <div
                    v-for="(email, idx) in currentNotificationRoute.emailDestinations"
                    :key="idx"
                    class="flex flex-row"
                  >
                    <div class="w-3/4">
                      <el-form-item
                        :prop="`emailDestinations[${idx}]`"
                        :rules="[
                          {
                            required: isEmailAvail || !isEmailAliasAvail,
                            validator: isEmailAvail || !isEmailAliasAvail ? requiredEmailValidate : () => true,
                          },
                        ]"
                      >
                        <el-input size="medium" v-model="currentNotificationRoute.emailDestinations[idx]"></el-input>
                      </el-form-item>
                    </div>
                    <div class="w-20 pt-1 pl-3 grid justify-items-end">
                      <div>
                        <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmail(idx)">
                        </el-button>
                        <el-button
                          v-if="idx === currentNotificationRoute.emailDestinations.length - 1"
                          type="primary"
                          icon="el-icon-plus"
                          size="small"
                          circle
                          @click="addNewEmail"
                        >
                        </el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- EMAIL CUSTOM FIELD -->
              <div class="grid grid-cols-9 gap-4 mt-2">
                <div class="col-span-2 flex items-start pre-line">
                  <div class="h-10 flex justify-end items-center w-full text-right leading-5">
                    {{ $t('barcode_type.email_custom_field') }}
                  </div>
                </div>
                <div class="col-span-7 flex flex-col">
                  <div
                    v-for="(alias, idx) in currentNotificationRoute.emailDestinationAliases"
                    :key="idx"
                    :class="`flex flex-row ${idx !== 0 ? 'mt-4' : ''}`"
                  >
                    <div class="w-3/4">
                      <SelectAutocomplete
                        v-model="currentNotificationRoute.emailDestinationAliases[idx]"
                        :options="emailCFs"
                      />
                    </div>
                    <div class="w-20 pt-1 pl-3 grid justify-items-end">
                      <div>
                        <el-button type="info" icon="el-icon-minus" size="small" circle @click="deleteEmailCF(idx)" />
                        <el-button
                          v-if="idx === currentNotificationRoute.emailDestinationAliases.length - 1"
                          type="primary"
                          icon="el-icon-plus"
                          size="small"
                          circle
                          @click="addNewEmailCF"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="grid grid-cols-9 gap-4 mt-6">
                <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.subject') }}</div>
                <div class="col-span-7">
                  <el-form-item prop="emailSubject">
                    <el-input v-model="currentNotificationRoute.emailSubject" size="medium" />
                  </el-form-item>
                </div>
              </div>

              <div class="grid grid-cols-9 gap-4 mt-2">
                <div class="col-span-2 pt-4 text-right">{{ $t('barcode_type.content') }}</div>
                <div class="col-span-7">
                  <el-form-item prop="emailBody">
                    <el-input v-model="currentNotificationRoute.emailBody" type="textarea" :rows="4" />
                  </el-form-item>
                </div>
              </div>
              <div class="grid grid-cols-9 gap-4 mt-2">
                <div class="col-span-2 pt-3 text-right">{{ $t('barcode_type.report_output') }}</div>
                <div class="col-span-7">
                  <el-select
                    :placeholder="$t('barcode_type.select')"
                    v-model="currentNotificationRoute.requestReportButtonLabelGenerates"
                    multiple
                    filterable
                    class="w-full"
                    size="medium"
                  >
                    <el-option
                      v-for="template in barcodeReportTemplates.filter(
                        (t) => t.templateFilePath !== '' && t.buttonLabel !== ''
                      )"
                      :key="template.code"
                      :value="template.code"
                      :label="template.buttonLabel"
                    >
                      {{ template.buttonLabel }}
                    </el-option>
                  </el-select>
                </div>
              </div>
            </el-form>
          </div>
        </div>

        <template #footer>
          <div class="flex">
            <div class="flex-1">
              <el-button type="default" class="btn-default-cancel" @click="closeNotifSettingDialog">
                {{ $t('barcode_type.cancel') }}
              </el-button>
            </div>
            <div class="flex-1">
              <el-button type="primary" @click="onSave">
                {{ $t('barcode_type.add') }}
              </el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script lang="ts">
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { POPULAR_COUNTRY_LIST } from '@/utils/constants'
import parsePhoneNumber, { AsYouType, CountryCode } from 'libphonenumber-js'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import uniqBy from 'lodash/uniqBy'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import {
  IBarcodeReportTemplate,
  ICountryCode,
  ICustomFieldFormData,
  INotificationRoute,
  ISMSDestination,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

export interface IRule {
  field: string
  fullField: string
  required: boolean
  type: string
  countryCode?: string
  validator: Function
}

@Options({
  components: { SelectAutocomplete },
  emits: ['update:modelValue'],
  name: 'TrackingNotificationBlock',
})
export default class TrackingNotificationBlock extends mixins(ValidateForm) {
  @PropSync('modelValue', { type: Object }) syncedModelValue!: INotificationRoute[]
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Boolean }) readonly isReadOnly?: boolean
  @Prop({ type: Object }) readonly barcodeReportTemplates!: IBarcodeReportTemplate[]

  get phoneNoCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.PHONE_NUMBER)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get isPhoneAvail() {
    return !isEmpty(this.currentNotificationRoute.smsDestinations.filter((v) => v.number !== ''))
  }

  get isPhoneAliasAvail() {
    return !isEmpty(this.currentNotificationRoute.smsDestinationAliases.filter((v) => v !== ''))
  }

  get isEmailAvail() {
    return !isEmpty(this.currentNotificationRoute.emailDestinations.filter((v) => v !== ''))
  }

  get isEmailAliasAvail() {
    return !isEmpty(this.currentNotificationRoute.emailDestinationAliases.filter((v) => v !== ''))
  }

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this.notificationRecords.forEach((val, idx) => {
      const endTPRouteAvailable = this.loadTP(val.from)

      const found = endTPRouteAvailable.find((val) => {
        return val.key === this.notificationRecords?.[idx]?.to
      })
      if (!found && val.to) {
        this.notificationRecords.splice(idx, 1)
      }
    })
  }

  isShowNotifSettingDialog = false
  notificationRecords = [] as INotificationRoute[]
  selectedNotifSettingIdx = -1
  hasSMS = true
  hasEmail = true

  currentNotificationRoute = {} as INotificationRoute

  addNewPhoneNo() {
    this.currentNotificationRoute.smsDestinations.push({ number: '', countryCode: 'JP' })
  }

  deletePhoneNo(idx: number) {
    if (this.currentNotificationRoute.smsDestinations.length === 1 && idx === 0) return
    this.currentNotificationRoute.smsDestinations.splice(idx, 1)
  }

  addNewPhoneNoCF() {
    this.currentNotificationRoute.smsDestinationAliases.push('')
  }

  deletePhoneNoCF(idx: number) {
    if (this.currentNotificationRoute.smsDestinationAliases.length === 1 && idx === 0) return
    this.currentNotificationRoute.smsDestinationAliases.splice(idx, 1)
  }

  addNewEmail() {
    this.currentNotificationRoute.emailDestinations.push('')
  }

  deleteEmail(idx: number) {
    if (this.currentNotificationRoute.emailDestinations.length === 1 && idx === 0) return
    this.currentNotificationRoute.emailDestinations.splice(idx, 1)
  }

  addNewEmailCF() {
    this.currentNotificationRoute.emailDestinationAliases.push('')
  }

  deleteEmailCF(idx: number) {
    if (this.currentNotificationRoute.emailDestinationAliases.length === 1 && idx === 0) return
    this.currentNotificationRoute.emailDestinationAliases.splice(idx, 1)
  }

  smsRules = {
    smsBody: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  emailRules = {
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  async onSave() {
    let isSmsValid = false
    let isEmailValid = false

    if (this.hasSMS) this.$refs.smsForm.validate((valid: string) => (isSmsValid = !!valid))
    else isSmsValid = true
    if (this.hasEmail) this.$refs.emailForm.validate((valid: string) => (isEmailValid = !!valid))
    else isEmailValid = true
    setTimeout(() => {
      if (!(isSmsValid && isEmailValid)) return

      const newRecord = cloneDeep(this.currentNotificationRoute)
      if (!this.hasSMS) {
        newRecord.smsDestinations = []
        newRecord.smsDestinationAliases = []
        newRecord.smsBody = ''
      }
      if (!this.hasEmail) {
        newRecord.emailDestinations = []
        newRecord.emailDestinationAliases = []
        newRecord.emailSubject = ''
        newRecord.emailBody = ''
        newRecord.requestReportButtonLabelGenerates = []
      }

      // remove duplicate records
      newRecord.smsDestinations = uniqBy(
        newRecord.smsDestinations.filter((v) => v.number !== ''),
        (item) => item.countryCode && item.number
      )
      newRecord.emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
      newRecord.smsDestinationAliases = [...new Set(newRecord.smsDestinationAliases.filter((v) => v !== ''))]
      newRecord.emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]

      this.notificationRecords[this.selectedNotifSettingIdx] = newRecord

      this.closeNotifSettingDialog()
    }, 100)
  }

  get countryList() {
    return {
      popularCountries: POPULAR_COUNTRY_LIST as Record<string, ICountryCode>,
      allCountries: OTHER_COUNTRY_LIST as Record<string, ICountryCode>,
    }
  }

  onPhoneChanged(idx: number) {
    const sms = this.currentNotificationRoute.smsDestinations[idx] as ISMSDestination
    const pn = parsePhoneNumber(sms.number ?? '', sms.countryCode as CountryCode)
    if (!pn) return
    sms.number = new AsYouType(sms.countryCode as CountryCode).input(sms.number ?? '').replaceAll(' ', '-')
  }

  formatPhoneValidate = (rule: IRule, value: string, callback: Function) => {
    if (isEmpty(value)) return callback(new Error(this.messages.required))
    const phoneNumber = parsePhoneNumber(value, rule.countryCode as CountryCode)
    if (phoneNumber && phoneNumber.isValid()) callback()
    else callback(new Error(this.messages.number))
  }

  onSelectedCountryChanged(idx: number) {
    this.currentNotificationRoute.smsDestinations[idx].number = ''
  }

  @Watch('currentNotificationRoute')
  onNotifRouteChanged() {
    this.hasSMS = !(
      isEmpty(this.currentNotificationRoute.smsDestinations) && isEmpty(this.currentNotificationRoute.smsBody)
    )

    this.hasEmail = !(
      isEmpty(this.currentNotificationRoute.emailDestinations) &&
      isEmpty(this.currentNotificationRoute.emailSubject) &&
      isEmpty(this.currentNotificationRoute.emailBody)
    )
  }

  @Watch('currentNotificationRoute.emailDestinations')
  onEmailDestListChanged() {
    if (!isEmpty(this.currentNotificationRoute.emailDestinations)) return
    this.currentNotificationRoute.emailDestinations.push('')
  }

  @Watch('currentNotificationRoute.emailDestinationAliases')
  onEmailDestAlListChanged() {
    if (!isEmpty(this.currentNotificationRoute.emailDestinationAliases)) return
    this.currentNotificationRoute.emailDestinationAliases.push('')
  }

  @Watch('currentNotificationRoute.smsDestinations')
  onSMSDestListChanged() {
    if (!isEmpty(this.currentNotificationRoute.smsDestinations)) return
    this.currentNotificationRoute.smsDestinations.push({ number: '', countryCode: 'JP' })
  }

  @Watch('currentNotificationRoute.smsDestinationAliases')
  onSMSDestAlListChanged() {
    if (!isEmpty(this.currentNotificationRoute.smsDestinationAliases)) return
    this.currentNotificationRoute.smsDestinationAliases.push('')
  }

  @Watch('syncedModelValue', { deep: true })
  onModelValueChanged() {
    if (isEqual(this.syncedModelValue, this.cleanRecords)) return
    this.notificationRecords = cloneDeep(this.syncedModelValue)
  }

  @Watch('notificationRecords', { deep: true })
  onNotificationRecordsChanged() {
    this.syncedModelValue = cloneDeep(this.cleanRecords)
  }

  get cleanRecords() {
    const newRecords = cloneDeep(this.notificationRecords).filter(
      (rc) => (!isEmpty(rc.from) && !isEmpty(rc.to)) || rc.isNew
    )
    newRecords.forEach((rc) => {
      rc.smsDestinations.map((sms) => (sms.number = sms.number.replaceAll('-', '')))
    })

    return newRecords
  }

  openNotifSettingDialog(idx: number) {
    this.isShowNotifSettingDialog = true
    this.selectedNotifSettingIdx = idx
    this.currentNotificationRoute = cloneDeep(this.notificationRecords[idx])
    this.currentNotificationRoute.smsDestinationAliases = this.currentNotificationRoute?.smsDestinationAliases ?? []
    this.currentNotificationRoute.emailDestinationAliases = this.currentNotificationRoute?.emailDestinationAliases ?? []
  }

  closeNotifSettingDialog() {
    this.isShowNotifSettingDialog = false
    this.notifSettingIdx = -1
  }

  addTrackingNotification() {
    this.notificationRecords.push({
      isNew: true,
      from: '',
      to: '',
      smsDestinations: [] as ISMSDestination[],
      emailDestinations: [] as string[],
      smsDestinationAliases: [] as string[],
      emailDestinationAliases: [] as string[],
      smsBody: '',
      emailSubject: '',
      emailBody: '',
      requestReportButtonLabelGenerates: [] as string[],
    } as INotificationRoute)
  }

  deleteTrackingNotification(idx: number) {
    this.notificationRecords.splice(idx, 1)
  }

  isDisableFromOption(fromIdx: string) {
    const keys = Object.keys(this.trackingPointDatasArr.find((tps) => fromIdx === tps.key)?.value.trackPointForms ?? {})
    const unavailToKeys = this.notificationRecords.filter((no) => no.from === fromIdx).map((no) => no.to)
    return isEmpty(keys) || !keys.find((k) => !unavailToKeys.includes(k))
  }

  isDisableToOption(fromIdx: string, toIdx: string) {
    const unavailToKeys = this.notificationRecords.filter((no) => no.from === fromIdx).map((no) => no.to)
    return unavailToKeys.includes(toIdx)
  }

  @Watch('trackingPointDatasArr')
  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  onFromChanged(nr: INotificationRoute) {
    const keys = this.loadTP(nr.from).map((tp) => tp.key)
    const unavailToKeys = this.notificationRecords.filter((no) => no.from === nr.from).map((no) => no.to)
    const remainKeys = keys.filter((k) => !unavailToKeys.includes(k))
    nr.to = !isEmpty(remainKeys) ? remainKeys[0] : ''
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.dialog-area :deep() {
  .el-overlay .el-dialog {
    max-width: 700px;
  }

  .el-button--small {
    padding: 7px;
    min-height: 28px !important;
    margin-top: 3px;
  }
  .el-form-item {
    margin-bottom: 16px;
    &__item {
      top: calc(100% - 5px);
    }
  }
}
</style>
