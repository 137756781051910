
import { Vue, Options } from 'vue-class-component'
import { Emit, Prop, Watch } from 'vue-property-decorator'
import { ITrackingPointDeletePath, ITrackpoint, ITrackPointForms } from 'smartbarcode-web-core/src/utils/types/index'
import { getInitFieldRestriction } from '@/utils/helpers'
import Dialog from '@/components/customFields/Dialog.vue'
import TrackingPointRelationship from '@/components/project/trackingPoint/TrackingPointRelationship.vue'
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import Draggable from 'vuedraggable'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import debounce from 'lodash/debounce'
import { TRACKING_POINT_NAME_DEBOUNCE_TIME } from '@/utils/constants'
import { arrayToObject, objectToArray } from 'smartbarcode-web-core/src/utils/helpers'

interface ITrackPointFormsKeyVal {
  key: string
  value: ITrackPointForms
}

@Options({
  components: { TrackingPointRelationship, Dialog, InputOnlyNumber, Draggable },
  emits: ['delete:node', 'update:node', 'delete:path'],
  name: 'TrackingPointItem',
})
export default class TrackingPointItem extends Vue {
  @Prop() trackPoints!: Record<string, ITrackpoint>
  @Prop() selectedNodeIndex?: number
  @Prop() trackingPoint?: ITrackpoint

  drag = false

  oldOrderValue = 0 as number | undefined
  activeCollapse = '0'
  isShowDialog = false
  trackingPointLocal: ITrackpoint = {}
  trackPointFormsArr: ITrackPointFormsKeyVal[] = []
  isDraggable = false

  created() {
    this.getTrackPointForms()
    this.debounceUpdateName = debounce(() => this.updateNode(true, true), TRACKING_POINT_NAME_DEBOUNCE_TIME)
  }

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 100,
      disabled: !this.isDraggable || this.isReadOnlyMode,
      ghostClass: 'ghost',
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get isDisabledAddRelationship() {
    return Object.keys(this.trackPoints).length - 1 === this.trackPointFormsArr.length
  }

  updateTrackingPointName() {
    this.debounceUpdateName()
  }

  @Watch('trackingPoint')
  getTrackPointForms() {
    this.trackingPointLocal = { ...this.trackingPoint }
    this.oldOrderValue = this.trackingPointLocal.order

    const arrTrackPointForms = objectToArray<ITrackPointForms>(this.trackingPointLocal?.trackPointForms || {})
    arrTrackPointForms.sort((a, b) => (a.value.order && b.value.order ? a.value.order - b.value.order : 1))
    this.trackPointFormsArr = arrTrackPointForms
  }

  @Emit('update:node')
  updateNode(loadNode: boolean, loadLine: boolean) {
    return { ...this.trackingPointLocal, loadNode, loadLine }
  }

  addRelationship() {
    let keyTrackPointForm = ''
    for (const kTP in this.trackPoints) {
      const existTPF = this.trackPointFormsArr.filter((item) => item.key === kTP)
      if (isEmpty(existTPF) && kTP !== String(this.selectedNodeIndex)) {
        keyTrackPointForm = String(kTP)
        break
      }
    }
    if (keyTrackPointForm === '') {
      return false
    }

    const order = this.trackPointFormsArr[this.trackPointFormsArr.length - 1]?.value.order ?? 0
    const newItem = {
      order: order ? order + 1 : 1,
      location: getInitFieldRestriction(),
    } as ITrackPointForms

    this.trackPointFormsArr.push({ key: keyTrackPointForm, value: newItem })
    this.trackingPointLocal = {
      ...this.trackingPointLocal,
      trackPointForms: arrayToObject<ITrackPointForms>(this.trackPointFormsArr),
    }

    this.updateNode(false, true)
  }

  updateTrackPointForm(data: ITrackPointFormsKeyVal) {
    this.trackingPointLocal = {
      ...this.trackingPoint,
      trackPointForms: { ...this.trackingPoint?.trackPointForms, [data.key]: data.value },
    }

    this.updateNode(false, false)
  }

  deleteTrackPointForm(key: string) {
    const newArr = this.trackPointFormsArr.filter((item) => item.key !== key)
    this.trackingPointLocal = { ...this.trackingPointLocal, trackPointForms: arrayToObject<ITrackPointForms>(newArr) }
    this.updateNode(true, true)
    this.$emit('delete:path', {
      fromKey: this.selectedNodeIndex ?? '',
      toKey: key,
    } as ITrackingPointDeletePath)
  }

  updateKeyTrackPointForm() {
    this.trackingPointLocal = {
      ...this.trackingPointLocal,
      trackPointForms: arrayToObject<ITrackPointForms>(this.trackPointFormsArr),
    }
    this.updateNode(true, true)
  }
}
