
import { Options, mixins } from 'vue-class-component'
import { Ref, Prop } from 'vue-property-decorator'
import TrackPointNode from '@/components/project/trackingPoint/TrackPointNode.vue'
import LeaderLine from 'vue3-leaderline'
import { IBarcodeDefinitionType, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import TrackPointDiagramMixin from '@/components/mixins/TrackPointDiagramMixin.vue'

@Options({
  components: { TrackPointNode },
  emits: ['update:barcodeTitleHeight', 'dataFlowBarcodeType:drawDone'],
  name: 'TrackPointNodeList',
})
export default class TrackPointNodeList extends mixins(TrackPointDiagramMixin) {
  @Prop({ type: String }) code?: string
  @Prop({ type: Object }) barcodeType?: IBarcodeDefinitionType
  @Prop({ type: Array }) arrTrackPoints!: ITrackPointKeyVal[]
  @Prop({ type: Number }) barcodeTitleHeight!: number

  @Ref() leaderLineWrapper!: HTMLElement

  llStore: LeaderLine[] = [] as LeaderLine[]
  diameter = 22

  get color() {
    return this.barcodeType?.backgroundColor
  }

  get lineStyles() {
    // Each column width is 160
    return {
      'background-color': this.color,
      'margin-left': 160 / 2 + 'px',
      width: (this.arrTrackPoints.length - 1) * 160 + 'px',
    }
  }

  getIsShowPair(idx: string) {
    return this.barcodeType?.pairing?.[idx]?.isAvailable ?? false
  }

  getIsShowUnpair(idx: string) {
    return this.barcodeType?.unpairing?.[idx]?.isAvailable ?? false
  }

  mounted() {
    this.$nextTick(async () => {
      await this.startDraw()
      this.fixWrapperPosition()
      this.moveLines()
      this.$emit('dataFlowBarcodeType:drawDone')
    })
  }

  get containerHeight() {
    const curLinesHeight = this.lineGravity.top + this.lineGravity.bottom + 100
    const totalHeight = Math.max(this.barcodeTitleHeight, curLinesHeight)
    const changeHeight = (this.barcodeTitleHeight - curLinesHeight) / 2
    if (changeHeight > 0) {
      this.leaderLineWrapper.style.transform = 'translate(-0px, -' + changeHeight + 'px)'
    }

    this.$emit('update:barcodeTitleHeight', totalHeight)
    this.positionLines()

    return totalHeight
  }

  fixWrapperPosition() {
    const rectWrapper = this.leaderLineWrapper.getBoundingClientRect()
    this.leaderLineWrapper.style.transform = `translate(-${rectWrapper.left +
      window.pageXOffset}px, -${rectWrapper.top + window.pageYOffset}px`
  }

  positionLines() {
    this.llStore.forEach((ll) => ll.position())
  }

  moveLines() {
    const lines = document.querySelectorAll('svg.leader-line')
    for (const line of lines) {
      if (line.id.includes(`${this.code}_leaderLine`)) {
        this.leaderLineWrapper.appendChild(line)
      }
    }
  }

  startDraw() {
    return this.drawLines({
      diagramDirection: 'horizontal',
      levelHeight: 20,
      trackpointEntries: this.arrTrackPoints,
      nodeIdPrefix: `node_${this.code}_`,
      lineIdPrefix: `${this.code}_leaderLine_`,
      lineColor: this.color,
      lineIdAtMiddleLabel: false,
    })
  }
}
