
import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import SelectAutocomplete from '@/components/common/SelectAutocomplete.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { IBarcodeRecycleAlertSetting, ICustomFieldFormData, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import cloneDeep from 'lodash/cloneDeep'
import { mixins, Options } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'
import TrackPointCheckboxesBlock from './TrackPointCheckboxesBlock.vue'
@Options({
  emits: [
    'update:allowBarcodeRecycle',
    'update:copyOriginOnRecycle',
    'update:copyDestinationOnRecycle',
    'update:copyPackageInfoOnRecycle',
    'update:barcodeRecycleStartTrackPoint',
    'update:barcodeRecyclableTrackPoints',
    'update:barcodeRecycleAlertSetting',
  ],
  components: { TrackPointCheckboxesBlock, InputOnlyNumber, SelectAutocomplete },
  name: 'RecycleBarcodeBlock',
})
export default class RecycleBarcodeBlock extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @Prop({ type: Boolean, default: false }) readonly isReadOnly!: boolean
  @Prop({ type: Boolean, default: false }) readonly recycleToLastestVersion!: boolean

  @PropSync('barcodeRecycleAlertSetting', { type: Object, default: {} })
  _barcodeRecycleAlertSetting!: IBarcodeRecycleAlertSetting

  @PropSync('allowBarcodeRecycle', { type: Boolean, default: false })
  _allowBarcodeRecycle!: boolean

  @PropSync('isRecycleToLastestVersion', { type: Boolean, default: false })
  _isRecycleToLastestVersion!: boolean

  @PropSync('barcodeRecycleStartTrackPoint', { type: String })
  _barcodeRecycleStartTrackPoint!: string

  @PropSync('barcodeRecyclableTrackPoints', { type: Array })
  _barcodeRecyclableTrackPoints!: string[]

  isShowNotifSettingDialog = false

  currentNotificationSetting: IBarcodeRecycleAlertSetting = {
    emailDestinations: [],
    emailDestinationAliases: [],
    recycleCountAlert: 0,
    emailSubject: '',
    emailBody: '',
  }

  emailRules = {
    recycleCountAlert: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    emailSubject: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
    emailBody: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  get emailCFs() {
    return (this.customFields ?? [])
      .filter((f) => f.fieldType === ECustomFieldType.EMAIL)
      .map((f) => ({ value: `{{activationData.customFields.${f.customFieldKey}}}`, label: f.label }))
  }

  get enableRecycleAlert() {
    return this._barcodeRecycleAlertSetting.recycleCountAlert > 0
  }

  set enableRecycleAlert(val: boolean) {
    this._barcodeRecycleAlertSetting = {
      recycleCountAlert: val ? 1 : 0,
      emailDestinations: [''],
      emailDestinationAliases: [''],
      emailSubject: '',
      emailBody: '',
    }
  }

  get isEmailAvail() {
    return !isEmpty(this.currentNotificationSetting.emailDestinations.filter((v) => v !== ''))
  }

  deleteEmail(idx: number) {
    if (this.currentNotificationSetting.emailDestinations.length < 2) return
    this.currentNotificationSetting.emailDestinations.splice(idx, 1)
  }

  deleteEmailAlias(idx: number) {
    if (this.currentNotificationSetting.emailDestinationAliases.length < 2) return
    this.currentNotificationSetting.emailDestinationAliases.splice(idx, 1)
  }

  addNewAliasEmail() {
    this.currentNotificationSetting.emailDestinationAliases.push('')
  }

  addNewEmail() {
    this.currentNotificationSetting.emailDestinations.push('')
  }

  openNotifSettingDialog() {
    this.isShowNotifSettingDialog = true
    this.currentNotificationSetting = cloneDeep(this._barcodeRecycleAlertSetting)
  }

  closeNotiSettingDialog() {
    this.isShowNotifSettingDialog = false
    this.currentNotificationSetting.emailDestinations = []
    this.currentNotificationSetting.emailDestinationAliases = []
    this.currentNotificationSetting.emailSubject = ''
    this.currentNotificationSetting.emailBody = ''
  }

  addRecycleNotification() {
    let isEmailValid = true

    this.$refs.recycleAlertForm.validate((valid: string) => (isEmailValid = !!valid))

    if (!isEmailValid) return

    const newRecord = cloneDeep(this.currentNotificationSetting)

    // remove duplicate records
    const emailDestinations = [...new Set(newRecord.emailDestinations.filter((v) => v !== ''))]
    const emailDestinationAliases = [...new Set(newRecord.emailDestinationAliases.filter((v) => v !== ''))]
    newRecord.emailDestinations = emailDestinations.length > 0 ? emailDestinations : ['']
    newRecord.emailDestinationAliases = emailDestinationAliases.length > 0 ? emailDestinationAliases : ['']
    this._barcodeRecycleAlertSetting = newRecord || this._barcodeRecycleAlertSetting
    this.closeNotiSettingDialog()
  }
}
