<template>
  <div class="h-full w-full overflow-y-auto">
    <div v-if="allUsers" class="user-group-transfer mt-5" style="min-width: 870px">
      <el-transfer
        v-model="usersInGroup"
        style="text-align: left; display: flex; width: 100%; justify-content: flex-start; align-items: center"
        filterable
        :filter-placeholder="$t('carrier.staff_name')"
        :titles="[$t('carrier.all_staff'), $t('carrier.staff_in_this_group')]"
        :format="{
          noChecked: '0/${total}',
          hasChecked: '${checked}/${total}',
        }"
        :data="allUsers"
        @change="handleChange"
      >
        <template #default="{ option }">
          <span>{{ option.label }}</span>
        </template>
      </el-transfer>
    </div>
    <template v-else>
      <ItemNotFound class="mt-56" :content="$t('empty')" />
    </template>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import { IStaff, ITransferData } from 'smartbarcode-web-core/src/utils/types/index'
import { UPDATE_USER_LIST } from '@/store/actions'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: { ItemNotFound },
  directives: { maska },
  name: 'UserGroupUserList',
})
export default class UserGroupUserList extends Vue {
  allUsers = [] as ITransferData[]
  usersInGroup = [] as string[]

  get userGroupId() {
    return this.$route.params?.id || ''
  }

  async created() {
    const staffs = (this.$store.state.userGroup.staffs || []) as IStaff[]
    staffs.forEach((item) => {
      if (item.id) {
        this.allUsers.push({
          key: item.id || '',
          label: item.fullName || '',
        })
      }
    })

    this.usersInGroup = cloneDeep(this.$store.state.userGroup?.userGroup?.userIds) || ([] as string[])
  }

  handleChange(value: string[]) {
    this.$store.commit(UPDATE_USER_LIST, value)
  }
}
</script>
<style lang="scss" scoped>
</style>
