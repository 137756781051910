<template>
  <div class="grid grid-cols-8 bg-white min-h-24">
    <div class="flex preview-col-width">
      <template v-if="!!groupUsers && trackPointsKey.length > 0">
        <div
          v-for="trackPoint in trackPointsKey"
          :key="trackPoint"
          class="
            flex flex-col
            preview-col-width
            border-box
            h-full
            items-center
            border-l border-solid border-gray-200
            bg-white
            justify-center
          "
        >
          <span
            v-for="userGroup in groupPermissionTrackPoint(trackPoint)"
            :key="userGroup"
            class="text-center p-2 truncate leading-normal w-11/12"
          >
            {{ userGroup }}
          </span>
          <span
            class="text-center p-2 truncate leading-normal w-11/12"
            v-show="groupPermissionTrackPoint(trackPoint).length < 1"
          >
            {{ $t('permissions.no_data') }}
          </span>
        </div>
      </template>
      <p v-else class="text-gray-200 flex items-center">
        <IconNotFound class="mr-3 w-5 h-5 bg-gray-200" /> {{ $t('permissions.no_data') }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import IconNotFound from '@/components/svg/IconNotFound.vue'
import IconUserGroup from '@/components/svg/IconUserGroup.vue'
import { PROJECT_NEW_KEY_ID } from '@/utils/constants'
import { ICreateUserGroup, IPermission, ITrackPointKeyVal, ITrackPointPermissions } from 'smartbarcode-web-core/src/utils/types/index'
import { maska } from 'maska'
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Options({
  components: { IconUserGroup, IconNotFound },
  directives: { maska },
  name: 'PreviewGroupUsers',
  emits: ['update:UserGroupHeight'],
})
export default class PreviewGroupUsers extends Vue {
  @Prop({ type: Object }) readonly groupUsers!: ICreateUserGroup[]
  @Prop({ type: Array }) arrTrackPoints!: ITrackPointKeyVal[]

  maxUserGroup = 1

  get trackPointsKey(): string[] {
    return this.arrTrackPoints.map((val) => val.key)
  }

  get newKeyProject() {
    return PROJECT_NEW_KEY_ID
  }

  groupPermissionTrackPoint(key: string): string[] {
    const groupAtTrackpoints: string[] = []
    this.groupUsers.forEach((val) => {
      const projectPermission = val.projectPermissions?.[this.newKeyProject] as IPermission
      const permission = projectPermission.trackingPermissions?.[key] as ITrackPointPermissions
      if (permission && permission.canEdit) {
        groupAtTrackpoints.push(val.name)
      }
    })
    if (groupAtTrackpoints.length > this.maxUserGroup) {
      this.maxUserGroup = groupAtTrackpoints.length
    }
    return groupAtTrackpoints
  }

  mounted() {
    let heightUserGroup = this.maxUserGroup * 40
    if (heightUserGroup < 96) {
      heightUserGroup = 96
    }
    this.$emit('update:UserGroupHeight', heightUserGroup)
  }
}
</script>
