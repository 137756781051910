
import TrackedDataFlow from '@/components/project/trackingPoint/TrackedDataFlow.vue'
import TrackingPointItem from '@/components/project/trackingPoint/TrackingPointItem.vue'
import { SAVE_PROJECT } from '@/store/actions'
import { removeIdxList } from '@/utils/arrayUtils'
import { LABEL_CHARACTERS_MAX } from '@/utils/constants'
import { runValidationPipe } from '@/utils/helpers'
import { openMessage, sortTrackingPoints } from '@/utils/utils'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { arrayToObject, objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import {
  IBarcodeDefinitionType,
  ICustomFieldFormData,
  IProjectDetail,
  IRoutePath,
  ITrackingPointDeletePath,
  ITrackpoint,
  ITrackPointForms,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Prop, ProvideReactive, Watch } from 'vue-property-decorator'
@Options({
  components: { TrackedDataFlow, TrackingPointItem },
  emits: ['update:reindexTrackPoint', 'update:addNode', 'update:reindexTrackingPath'],
  name: 'TrackingPointTab',
})
export default class TrackingPointTab extends Vue {
  /** FIXME: Provide a default value for Calculation customField richTextEditor component */
  @ProvideReactive() customFields: ICustomFieldFormData[] = []

  @Prop({ type: Boolean }) startLoading?: boolean

  trackingPointDatas = {} as Record<string, ITrackpoint>
  trackingPointDatasArr: ITrackPointKeyVal[] = []
  startNodeIndex = ''
  endNodeIndex = ''
  endNodeList = [] as ITrackPointKeyVal[]

  // Default is start node
  selectedNodeIndex = ''

  created() {
    this.getTrackingPoint()
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get selectedNode(): ITrackpoint {
    const trackPoint = this.trackingPointDatas?.[this.selectedNodeIndex]
    if (trackPoint) trackPoint.index = this.selectedNodeIndex
    return trackPoint
  }

  get projectDetail(): IProjectDetail {
    return cloneDeep(this.$store.state?.project?.projectDetail)
  }

  updateIndexNode(idx: string) {
    this.selectedNodeIndex = idx
  }

  onStartNodeChanged() {
    this.setIsStartNode(this.startNodeIndex)
    this.updateOriginData()
  }

  onEndNodeChanged() {
    this.setIsEndNode(this.endNodeIndex)
    this.updateOriginData()

    if (this.endNodeIndex) {
      // const barcodeTypes = this.barcodeTypes || {}
      Object.values(this.barcodeTypes || {}).forEach((bcType) => {
        const indexBCLinking = bcType.barcodeLinkableTrackPoints.findIndex((item: string) => item === this.endNodeIndex)
        if (indexBCLinking > -1) bcType.barcodeLinkableTrackPoints.splice(indexBCLinking, 1)
        if (bcType.pairing?.[this.endNodeIndex]) delete bcType.pairing?.[this.endNodeIndex]
        if (bcType.unpairing?.[this.endNodeIndex]) delete bcType.unpairing?.[this.endNodeIndex]
        if (bcType.barcodeRecycleStartTrackPoint === this.endNodeIndex) {
          bcType.barcodeRecycleStartTrackPoint = this.startNodeIndex
        }
      })

      this.updateProjectInfo()
    }
  }

  @Watch('$store.state.project.projectDetail.trackPoints', { deep: true })
  getTrackingPoint() {
    this.trackingPointDatas = cloneDeep(this.$store.state.project?.projectDetail.trackPoints)
    this.updateDestinationData()
    this.setSelectedStartEnd()
    this.setSelectedNodeIndex()
    this.getStartEndNodeList()
  }

  setIsStartNode(idx: string) {
    this.trackingPointDatasArr.forEach((item) => (item.value.isStart = item.key === idx))
  }

  setIsEndNode(idx: string) {
    this.trackingPointDatasArr.forEach((item) => (item.value.isEnd = item.key === idx))
  }

  setSelectedStartEnd() {
    const startNode = this.trackingPointDatasArr.filter((item) => item.value.isStart)[0]
    const endNode = this.trackingPointDatasArr.filter((item) => item.value.isEnd)[0]
    this.startNodeIndex = startNode?.key || ''
    this.endNodeIndex = endNode?.key || ''
  }

  setSelectedNodeIndex() {
    this.selectedNodeIndex = this.selectedNodeIndex ? this.selectedNodeIndex : this.startNodeIndex
    const isNodeExist = !!this.trackingPointDatasArr.filter((item) => item.key === this.selectedNodeIndex).length

    // Set default is first node or not selected
    if (!isNodeExist) this.selectedNodeIndex = this.trackingPointDatasArr[0]?.key || ''
  }

  deleteNode(node: string) {
    if (this.trackingPointDatasArr.length <= 1) return
    if (this.startNodeIndex === node) this.startNodeIndex = ''
    if (this.endNodeIndex === node) this.endNodeIndex = ''

    const trackingPointDatas = cloneDeep(this.trackingPointDatas)
    if (trackingPointDatas[node]) delete trackingPointDatas[node]
    for (const key in trackingPointDatas) {
      const trackPointForms = cloneDeep(trackingPointDatas[key].trackPointForms)
      for (const subKey in trackPointForms) {
        if (subKey === node) delete trackPointForms[subKey]
      }
      trackingPointDatas[key].trackPointForms = trackPointForms
    }

    let flagIndex = 0
    for (const index in trackingPointDatas) {
      if (Number(index) !== flagIndex) {
        const item = cloneDeep(trackingPointDatas[index])
        trackingPointDatas[flagIndex] = item
        delete trackingPointDatas[index]

        for (const key in trackingPointDatas) {
          const trackPointForms = cloneDeep(trackingPointDatas[key].trackPointForms)
          for (const subKey in trackPointForms) {
            if (subKey === index) {
              const tempItem = trackPointForms[subKey]
              delete trackPointForms[subKey]
              trackPointForms[flagIndex] = tempItem
            }
          }

          trackingPointDatas[key].trackPointForms = trackPointForms
        }
      }
      flagIndex++
    }

    this.trackingPointDatas = trackingPointDatas

    this.updateDestinationData()
    this.setSelectedNodeIndex()

    // bctypes params
    this.reindexTrackPointData(node)
    // permission
    this.$emit('update:reindexTrackPoint', node)

    this.selectedNodeIndex = '0'
    if (this.$refs.trackPointFlow) {
      this.$refs.trackPointFlow.setStartNode(this.startNodeIndex)
      this.$refs.trackPointFlow.setEndNode(this.endNodeIndex)
      this.$refs.trackPointFlow.setCurrentNode(this.selectedNodeIndex)
    }
  }

  deletePath(data: ITrackingPointDeletePath) {
    // this.$emit('update:reindexTrackingPath', data)
    this.updateTrackingPath(data)
  }

  addNode(): void {
    const newNodeIndex = this.getNextNodeKey()
    const newOrderNumber = this.getNextOrderNumber()
    const newNodeName = this.getNextNodeName()

    const newNode: ITrackpoint = {
      name: newNodeName,
      isStart: false,
      isEnd: false,
      order: newOrderNumber,
      trackPointForms: {} as Record<string, ITrackPointForms>,
    }

    this.trackingPointDatasArr.push({ key: newNodeIndex, value: newNode })
    this.updateOriginData()

    // Set selected node to just added node
    this.selectedNodeIndex = newNodeIndex
    if (this.$refs.trackPointFlow) this.$refs.trackPointFlow.setCurrentNode(newNodeIndex)
    this.$emit('update:addNode', newNodeIndex)
  }

  isValidNodeName = false

  validate(): boolean {
    // valid node have relationship
    let haveParentNodes = [] as string[]
    for (const item of this.trackingPointDatasArr) {
      const trackPointFormKeys = Object.keys(item.value.trackPointForms || {})
      haveParentNodes = haveParentNodes.concat(trackPointFormKeys)
    }

    const isNotValidNodes = this.trackingPointDatasArr
      .filter(
        (i) => !(i.value.trackPointForms && !isEmpty(i.value.trackPointForms)) && !haveParentNodes.includes(i.key)
      )
      .map((i) => i.value.name ?? '') as string[]

    // validation trackpoint forms custom fields
    let isTPLabelValid = true
    let fieldName: string | undefined
    Object.keys(this.trackingPointDatasArr).forEach((tpKey) => {
      const trackpoint: ITrackpoint = this.trackingPointDatasArr[Number(tpKey)].value
      if ((trackpoint?.name ?? '').length > LABEL_CHARACTERS_MAX && !fieldName) {
        fieldName = `${this.$t('barcode_type.trackpoint_forms')} → ${trackpoint.name}`
        isTPLabelValid = false
      }

      if (!trackpoint.trackPointForms) return

      const tpForms = trackpoint.trackPointForms as Record<string, ITrackPointForms>
      Object.keys(tpForms).forEach((formKey) => {
        Object.values(tpForms[formKey].customFields ?? {}).forEach((field) => {
          if (field.label.length > LABEL_CHARACTERS_MAX && !fieldName) {
            fieldName = `${this.$t('barcode_type.trackpoint_forms')} → ${trackpoint.name} → ${field.label}`
            isTPLabelValid = false
          }

          if ([ECustomFieldType.SINGLE_SELECT, ECustomFieldType.MULTI_SELECT].includes(field.fieldType ?? '')) {
            Object.values(field.selections ?? {}).forEach((v) => {
              if (v.label.length > LABEL_CHARACTERS_MAX && !fieldName) {
                fieldName = `${this.$t('barcode_type.trackpoint_forms')} → ${trackpoint.name} → ${field.label} → ${
                  v.label
                }`
                isTPLabelValid = false
              }
            })
          }
        })
      })
    })

    return runValidationPipe([
      {
        isInvalid: !!this.trackingPointDatasArr.find((item) => item.value.name === ''),
        exec: () => openMessage(this.$t('projects.trackpoints_has_empty_node_name'), 'error'),
      },
      {
        isInvalid: this.startNodeIndex === '',
        exec: () => openMessage(this.$t('projects.trackpoints_start_node_empty'), 'error'),
      },
      {
        isInvalid: this.endNodeIndex === '',
        exec: () => openMessage(this.$t('projects.trackpoints_end_node_empty'), 'error'),
      },
      {
        isInvalid: this.endNodeIndex === this.startNodeIndex,
        exec: () => openMessage(this.$t('projects.trackpoints_start_end_node_same'), 'error'),
      },
      {
        isInvalid: !isEmpty(isNotValidNodes),
        exec: () =>
          openMessage(
            this.$t('projects.trackpoints_must_not_include_alone_node', { field: isNotValidNodes.join(', ') }),
            'error'
          ),
      },
      {
        isInvalid: !isTPLabelValid,
        exec: () =>
          openMessage(
            this.$t('field_label_maxLength_error', { field: fieldName, val1: LABEL_CHARACTERS_MAX }),
            'error'
          ),
      },
    ])
  }

  updateNode(data: ITrackpoint & { loadNode: boolean } & { loadLine: boolean }) {
    const { loadNode, loadLine, ...formatData } = data

    this.trackingPointDatasArr.map((item) => {
      if (item.key === data.index) item.value = formatData
      return item
    })

    this.updateOriginData()
    if (loadNode) this.$refs.trackPointFlow.setEndNode(this.endNodeIndex)
    if (loadLine) this.$refs.trackPointFlow.drawLeaderLine(this.trackingPointDatasArr)
  }

  updateNodeOrder(formatData: ITrackpoint, selectedNodeIndex: string): boolean {
    const existOrderNode = this.trackingPointDatasArr.filter((item) => item.value.order === formatData.order)
    this.trackingPointDatasArr.filter((item) => item.key === selectedNodeIndex).map((item) => (item.value = formatData))

    if (existOrderNode.length > 0) {
      const newValue = cloneDeep(existOrderNode[0].value)
      newValue.order = (newValue.order ?? 0) + 1
      return this.updateNodeOrder(newValue, existOrderNode[0].key)
    } else {
      return true
    }
  }

  updateOriginData() {
    sortTrackingPoints(this.trackingPointDatasArr)
    this.trackingPointDatas = arrayToObject<ITrackpoint>(this.trackingPointDatasArr)
    this.getStartEndNodeList()
  }

  updateDestinationData() {
    const arrTrackPoints = objectToArray<ITrackpoint>(this.trackingPointDatas) || []
    sortTrackingPoints(arrTrackPoints)
    this.trackingPointDatasArr = arrTrackPoints
  }

  getNextNodeKey(): string {
    return `${Object.keys(this.trackingPointDatas).reduce((result, nodeIdx) => Math.max(result, Number(nodeIdx)), -1) +
      1}`
  }

  getNextOrderNumber(): number {
    return (
      Object.values(this.trackingPointDatas).reduce((result, node) => Math.max(result, Number(node.order || 0)), -1) + 1
    )
  }

  getNextNodeName(): string {
    let max = 0
    Object.values(this.trackingPointDatas).forEach((node) => {
      const numberNodeName = node.name?.replace('Node ', '')
      if (!isNaN(Number(numberNodeName))) max = Math.max(max, Number(numberNodeName)) + 1
    })
    if (max < 1) max = 1

    return `Node ${max}`
  }

  getStartEndNodeList() {
    this.endNodeList = this.trackingPointDatasArr.filter((item) => {
      if (item.value.trackPointForms && !isEmpty(item.value.trackPointForms)) return false
      for (const index in this.trackingPointDatas) {
        if (this.trackingPointDatas[index].trackPointForms?.[item.key]) return true
      }
      return false
    })

    if (isEmpty(this.endNodeList.filter((item) => item.key === this.endNodeIndex))) {
      this.endNodeIndex = ''
      this.setIsEndNode(this.endNodeIndex)
    }
  }

  keyGroups = {
    tpString: [
      'overridableStartTrackPoints',
      'bulkUpdatableTrackPoints',
      'barcodeLinkableTrackPoints',
      'barcodeRecyclableTrackPoints',
    ],
    tpObject: ['linking', 'pairing', 'unpairing', 'trackPointTracingVisibilities'],
    route: [
      'trackPointRouteRestrictedPaths',
      'trackPointRouteNotifications',
      'trackPointRouteRemarks',
      'customValidators',
      'customNotificationEvent',
    ],
  }

  get barcodeTypes() {
    return this.projectDetail.barcodeTypes
  }

  reindexTrackPointData(removedNode: string) {
    const removedNodeIdx = Number(removedNode)
    const NONE_INDEX = -1

    for (const bcKey in this.barcodeTypes) {
      const bcType = this.barcodeTypes[bcKey]
      this.keyGroups.tpString.forEach((key: string) => {
        let removeIdx = NONE_INDEX
        const arrIndex = bcType[key as keyof IBarcodeDefinitionType] as string[]
        if (!isEmpty(arrIndex)) {
          arrIndex.map((node: string | number, idx: number) => {
            const nodeIdx = Number(node)
            if (nodeIdx === removedNodeIdx) removeIdx = idx
            else if (nodeIdx > removedNodeIdx) arrIndex[idx] = String(nodeIdx - 1)
          })
          if (!isEqual(removeIdx, NONE_INDEX)) arrIndex.splice(removeIdx, 1)
        }
      })

      this.keyGroups.tpObject.forEach((key: string) => {
        const records = bcType[key as keyof IBarcodeDefinitionType] as { [index: string]: unknown }
        if (isEmpty(records)) return
        delete records[removedNode]
        for (const key in records) {
          const keyNum = Number(key)
          if (keyNum > removedNodeIdx) {
            const temp = records[key]
            delete records[key]
            records[`${keyNum - 1}`] = temp
          }
        }
      })

      this.keyGroups.route.forEach((key) => {
        const subKey = ['customValidators', 'customNotificationEvent'].includes(key) ? 'trackPointRoute' : null
        const routes = bcType[key as keyof IBarcodeDefinitionType] as []
        if (isEmpty(routes)) return
        const removeIndex = [] as number[]
        routes.map((i, index) => {
          const item = (subKey ? i[subKey] : i) as IRoutePath
          const fromIdx = Number(item?.from || NONE_INDEX)
          const toIdx = Number(item?.to || NONE_INDEX)
          if (isEqual(fromIdx, removedNodeIdx) || isEqual(toIdx, removedNodeIdx)) removeIndex.push(index)
          if (fromIdx > removedNodeIdx) item.from = `${fromIdx - 1}`
          if (toIdx > removedNodeIdx) item.to = `${toIdx - 1}`
        })

        removeIdxList(routes, removeIndex)
      })

      const barcodeRecycleStartTrackPointNum = Number(bcType.barcodeRecycleStartTrackPoint)
      if (barcodeRecycleStartTrackPointNum === removedNodeIdx) {
        bcType.barcodeRecycleStartTrackPoint = '0'
      } else if (barcodeRecycleStartTrackPointNum > removedNodeIdx) {
        bcType.barcodeRecycleStartTrackPoint = `${barcodeRecycleStartTrackPointNum - 1}`
      }
    }

    this.updateProjectInfo()
  }

  updateTrackingPath(deletedPath: ITrackingPointDeletePath) {
    for (const bcKey in this.barcodeTypes) {
      const bcType = this.barcodeTypes[bcKey]
      this.keyGroups.route.forEach((key) => {
        const subKey = ['customValidators', 'customNotificationEvent'].includes(key) ? 'trackPointRoute' : null
        const routes = bcType[key as keyof IBarcodeDefinitionType] as []
        if (isEmpty(routes)) return
        const removeIndex = [] as number[]
        routes.forEach((i, idx) => {
          const item = (subKey ? i[subKey] : i) as IRoutePath
          const isSameFromIdx = Number(item?.from || -1) === Number(deletedPath.fromKey)
          const isSameToIdx = Number(item?.to || -1) === Number(deletedPath.toKey)
          if (isSameFromIdx || isSameToIdx) removeIndex.push(idx)
        })

        removeIdxList(routes, removeIndex)
      })
    }

    this.updateProjectInfo()
  }

  // update project details in store
  updateProjectInfo() {
    this.$store.commit(SAVE_PROJECT, {
      ...this.projectDetail,
      barcodeTypes: this.barcodeTypes,
      trackPoints: this.trackingPointDatas,
    })
  }
}
