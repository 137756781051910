
import isEmpty from 'lodash/isEmpty'
import { parseExternalId, TParsableField, TPhoneProps } from 'smartbarcode-web-core/src/bo/externalId'
import { maskPhoneNum } from 'smartbarcode-web-core/src/utils/barcodeHelper'
import { OTHER_COUNTRY_LIST } from 'smartbarcode-web-core/src/utils/constants'
import { ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums'
import { ICountryPhoneInfo, ICustomFieldFormData, TParserSettingItem } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

type TPhoneField = TParsableField & TPhoneProps

@Options({
  components: {},
  emits: ['update:modelValue', 'update:parserSetting'],
  name: 'TestParserDialog',
  methods: { isEmpty, maskPhoneNum },
})
export default class TestParserDialog extends Vue {
  @InjectReactive() customFields!: ICustomFieldFormData[]
  @PropSync('modelValue', { type: Boolean }) modelValue_!: boolean
  @Prop({ type: Array, default: [] }) readonly settings: TParserSettingItem[] = []
  @Prop({ type: Array, default: [] }) readonly parsableFields: TParsableField[] = []

  ECustomFieldType = ECustomFieldType
  externalId = ''
  models = {} as Record<string, string | string[]>

  test() {
    const parsablePhoneFields = this.parsableSettingFields.filter(
      (f): f is TPhoneField => f.type === ECustomFieldType.PHONE_NUMBER
    )
    Object.entries(parseExternalId(this.externalId, this.parsableSettingFields, this.settings)).forEach(([k, v]) => {
      const phoneField = parsablePhoneFields.find((f) => f.key === k)
      if (phoneField) {
        this.formatPhoneField(v as string, phoneField)
      } else {
        this.models[k] = v
      }
    })
  }

  get parsableSettingFields(): TParsableField[] {
    const fieldPaths = this.settings.map((s) => s.targetFieldPath)
    return this.parsableFields.filter((f) => fieldPaths.includes(f.key))
  }

  get cfsModels() {
    this.parsableSettingFields.forEach(
      (f) => (this.models[f.key!] = f.type === ECustomFieldType.MULTI_SELECT ? [] : '')
    )
    return this.models
  }

  getCountryPhoneInfo(countryCode: string): ICountryPhoneInfo {
    return OTHER_COUNTRY_LIST[countryCode as keyof typeof OTHER_COUNTRY_LIST]
  }

  formatPhoneField(val: string, cf: TPhoneField) {
    this.cfsModels[cf.key] = maskPhoneNum(val, cf.countryCode)
  }

  created() {
    this.externalId = ''
    this.models = {} as Record<string, string | string[]>
  }
}
