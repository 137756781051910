<template>
  <template v-if="isShowDialog">
    <Dialog
      :customFieldData="editingCustomFieldData"
      :allowToSave="!isCustomFieldError"
      :isShowHiddenField="false"
      @code:update="onCustomFieldCodeChange($event)"
      @dialog:close="closeDialog"
      @field:update="updateCustomFieldDataFromModal"
      :imageFileFields="imageFileFields"
      :hasAllowOcrInput="true"
      :currentTrackingPointCustomFields="currentTrackingPointCustomFields"
    />
  </template>

  <el-collapse-item :name="`${index}`" class="bg-gray-200 p-4">
    <template v-slot:title>
      <div :class="`flex items-center gap-4 w-full ${isDraggable ? 'grab-cursor' : ''}`">
        <div>
          <h4>{{ `${$t('projects.nextTrackingPoint')} ${index + 1}` }}</h4>
        </div>
        <div class="flex-1">
          <el-select v-model="trackPointFormData.key" :placeholder="$t('selectOne')" class="w-full">
            <el-option
              v-for="item in trackingPointDatasArr"
              :key="item.key"
              :label="item.value.name"
              :value="item.key"
              :disabled="isDisableNextPointOption(item.key)"
            >
              {{ item.value.name }}
            </el-option>
          </el-select>
        </div>
        <div>
          <el-button
            type="default"
            class="bg-red-min text-white focus:text-white"
            size="medium"
            @click.stop="deleteRelationship"
          >
            {{ $t('delete') }}
          </el-button>
        </div>

        <div class="ml-10 mr-1 flex items-center">
          <IconDraggable class="p-2" @mouseenter="_isDraggable = true" @mouseleave="_isDraggable = false" />
        </div>
      </div>
    </template>
    <div class="mt-5 border border-solid border-gray-400 rounded-lg">
      <div class="grid grid-cols-5 gap-x-4 p-5 border-b border-solid border-gray-400">
        <div class="border-r border-solid border-gray-600 flex justify-start pr-3">
          {{ $t('projects.locationInformation') }}
        </div>
        <div class="col-span-4">
          <el-radio
            :disabled="isDisabledForm"
            v-for="(value, key) in fieldTypes"
            v-model="tpCommonFieldsModel.location"
            :key="key"
            :label="value"
            @change="onRadioChanged"
            class="w-28"
          >
            {{ $t('projects.' + value) }}
          </el-radio>
        </div>
      </div>
      <div class="grid grid-cols-5 gap-x-4 p-5 border-b border-solid border-gray-400">
        <div class="border-r border-solid border-gray-600 flex justify-start pr-3">
          {{ $t('skip_tracking') }}
        </div>
        <div class="col-span-4">
          <el-radio-group v-model="trackPointFormData.value.allowSkipTracking">
            <el-radio :label="true" class="w-28">{{ $t('available') }}</el-radio>
            <el-radio :label="false" class="w-28">{{ $t('unavailable') }}</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="p-5">
        <!-- Add custom field header -->
        <div class="flex items-center">
          <div class="flex-1">
            <h4 class="font-bold">
              {{ $t('record_item_setting') }}
            </h4>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-plus" :disabled="isDisabledForm" @click="onAddNewCustomField">
              {{ $t('projects.addField') }}
            </el-button>
          </div>
        </div>

        <!-- Custom fields list -->
        <div :class="isCFDraggable ? 'grab-cursor mt-4' : 'mt-4'">
          <Draggable
            v-model="customFields"
            tag="transition-group"
            :component-data="draggableComponentData"
            v-bind="draggableBindingData"
            @start="drag = true"
            @end="updateOrderCustomFields"
            item-key="customFieldKey"
          >
            <template #item="item">
              <div>
                <TrackingPointRelationshipField
                  :key="item.element.customFieldKey"
                  :customField="item.element"
                  :index="index"
                  v-model:isCFDraggable="isCFDraggable"
                  @field:load="onEditCustomField"
                  @field:delete="deleteCustomField"
                />
              </div>
            </template>
          </Draggable>
        </div>
      </div>
    </div>
  </el-collapse-item>
</template>

<script lang="ts">
import Dialog from '@/components/customFields/Dialog.vue'
import TrackingPointRelationshipField from '@/components/project/trackingPoint/TrackingPointRelationshipField.vue'
import { PROJECT_CUSTOM_FIELDS_DEFAULT } from '@/utils/constants'
import { displayRestrictionRule, fieldDisplayRestriction2String, isImageFileField } from '@/utils/helpers'
import { openMessage, sortTrackingPoints } from '@/utils/utils'
import {
  IActivationCustomField,
  ICustomFieldFormData,
  IFieldOption,
  IFieldRestriction,
  ITrackpoint,
  ITrackPointForms,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop, PropSync, Watch } from 'vue-property-decorator'

import InputOnlyNumber from '@/components/common/InputOnlyNumber.vue'
import IconDraggable from '@/components/svg/IconDraggable.vue'
import { EDisplayRestrictionOption } from 'smartbarcode-web-core/src/utils/enums/index'
import { objectToArray } from 'smartbarcode-web-core/src/utils/helpers'
import Draggable from 'vuedraggable'

interface ITrackPointFormsKeyVal {
  key: string | number
  value: ITrackPointForms
}

@Options({
  components: { TrackingPointRelationshipField, Dialog, InputOnlyNumber, IconDraggable, Draggable },
  emits: [
    'trackPointForm:update',
    'dialog:show',
    'update:isDraggable',
    'trackPointForm:updateKey',
    'trackPointForm:delete',
  ],
  name: 'TrackingPointRelationship',
})
export default class TrackingPointRelationship extends Vue {
  @PropSync('isDraggable', { type: Boolean }) _isDraggable!: boolean
  @Prop({ type: String }) readonly selectedNodeIndex!: string
  @Prop({ type: Object }) readonly trackPoints!: Record<string, ITrackpoint>
  @Prop() index!: number
  @Prop() trackPointId?: number
  @Prop() trackPointFormData?: ITrackPointFormsKeyVal
  @Prop() trackPointFormsArr?: ITrackPointFormsKeyVal[]

  numberCustomFieldBeginning = 0
  isShowDialog = false
  editingCustomFieldData = {} as ICustomFieldFormData

  customFields = [] as ICustomFieldFormData[]
  isCFDraggable = false

  drag = false

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 100,
      disabled: !this.isCFDraggable || this.isReadOnlyMode,
      ghostClass: 'ghost',
    }
  }

  get currentTPFormData() {
    return this.trackPointFormData?.value
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get imageFileFields(): IFieldOption[] {
    return Object.entries(this.trackPointFormData?.value.customFields ?? {})
      .filter((item) => isImageFileField(item[1]))
      .map(([k, val]) => ({ value: k, label: val.label }))
  }

  get fieldTypes(): Record<string, string> {
    return Object.keys(EDisplayRestrictionOption).reduce(
      (obj, key) => ({ ...obj, [key]: EDisplayRestrictionOption[key as keyof typeof EDisplayRestrictionOption] }),
      {}
    )
  }

  get isDisabledForm() {
    return this.trackPointFormData?.key === ''
  }

  get trackingPointDatasArr() {
    const arrTrackPoints = objectToArray<ITrackpoint>(this.trackPoints || {}) || []
    sortTrackingPoints(arrTrackPoints)
    return arrTrackPoints
  }

  get currentTrackingPointCustomFields() {
    const trackPoint = this.trackPointFormData?.value
    if (!trackPoint?.customFields) {
      return []
    }
    return trackPoint.customFields
  }

  @Watch('index')
  onIndexChanged() {
    if (!this.trackPointFormData) return
    this.trackPointFormData.value.order = this.index + 1
    this.$emit('trackPointForm:update', this.trackPointFormData)
  }

  created() {
    // Add default value to allowSkipTracking if this key doesnt exist
    if (this.trackPointFormData && !this.trackPointFormData?.value?.allowSkipTracking) {
      this.trackPointFormData.value.allowSkipTracking = false
    }

    this.formatFields()
    this.numberCustomFieldBeginning = this.customFields.length
  }

  tpCommonFieldsModel = {
    location: EDisplayRestrictionOption.MANDATORY,
  }

  isDisableNextPointOption(key: string) {
    return !!this.trackPointFormsArr?.filter((item) => item.key === key).length || key === this.selectedNodeIndex
  }

  onEditCustomField(
    data: ICustomFieldFormData & {
      indexCustomField: number
    }
  ) {
    const { indexCustomField, ...originalData } = data
    originalData.isNew = this.numberCustomFieldBeginning < indexCustomField + 1

    this.editingCustomFieldData = originalData
    this.showDialog()
  }

  onAddNewCustomField() {
    // Default is customTextBoxFields
    this.editingCustomFieldData = {
      ...PROJECT_CUSTOM_FIELDS_DEFAULT.text,
      customFieldKey: '',
      order: this.customFields.length + 1,
      isNew: true,
    }

    this.showDialog()
  }

  isCustomFieldError = false
  onCustomFieldCodeChange(newCode: string) {
    const editingCode = this.editingCustomFieldData.customFieldKey
    // skip
    if (editingCode === newCode) {
      this.isCustomFieldError = false
      return
    }

    // validate new code
    const isDuplicated = !!this.customFields.find((field) => field.customFieldKey === newCode)
    if (isDuplicated) {
      this.isCustomFieldError = true
      openMessage(this.$t('duplicated_custom_field_code'), 'error')
      return
    }

    this.isCustomFieldError = false
  }

  @Watch('trackPointFormData', { deep: true })
  formatFields() {
    this.tpCommonFieldsModel.location = fieldDisplayRestriction2String(
      this.currentTPFormData?.location as IFieldRestriction
    )

    const customFields = Object.entries(
      this.trackPointFormData?.value.customFields ?? {}
    ).map(([customFieldKey, field]) => ({ ...field, customFieldKey }))
    customFields.sort((a, b) => (a.order && b.order && a.order > b.order ? 1 : -1))
    this.customFields = customFields
  }

  @Emit('trackPointForm:update')
  updateCustomFieldDataFromModal(customFieldData: IActivationCustomField) {
    if (!this.trackPointFormData) return
    if (!this.trackPointFormData.value.customFields) {
      this.trackPointFormData.value.customFields = {}
    }

    delete this.trackPointFormData?.value.customFields[this.editingCustomFieldData.customFieldKey ?? '']
    const { customFieldKey, ...newFields } = customFieldData
    this.trackPointFormData.value.customFields[customFieldKey as string] = newFields

    this.isShowDialog = false

    return this.trackPointFormData
  }

  @Emit('trackPointForm:update')
  deleteCustomField(customFieldKey: string) {
    delete this.trackPointFormData?.value.customFields[customFieldKey]
    Object.values(this.trackPointFormData?.value?.customFields || {}).forEach((item, idx) => (item.order = idx + 1))
    return this.trackPointFormData
  }

  @Emit('trackPointForm:update')
  onRadioChanged() {
    if (this.trackPointFormData?.value.location) {
      this.trackPointFormData.value.location = {
        ...this.trackPointFormData.value.location,
        order: 1,
        ...displayRestrictionRule(this.tpCommonFieldsModel.location),
      }
    }

    return this.trackPointFormData
  }

  updateOrderCustomFields() {
    this.drag = false
    this.customFields.map((item, index) => {
      item.order = index + 1
      if (this.trackPointFormData) {
        this.trackPointFormData.value.customFields[item?.customFieldKey || ''].order = index + 1
      }
      return item
    })
  }

  showDialog() {
    this.isShowDialog = true
  }

  closeDialog() {
    this.isShowDialog = false
  }

  deleteRelationship() {
    this.$confirm(this.$t('projects.confirm_remove_next_trackpoint'), this.$t('info'), {
      confirmButtonText: this.$t('delete'),
      confirmButtonClass: 'danger',
      cancelButtonText: this.$t('cancel'),
    })
      .then(() => {
        return this.$emit('trackPointForm:delete', this.trackPointFormData?.key || '')
      })
      .catch(() => true)
  }

  @Watch('trackPointFormData.key')
  @Emit('trackPointForm:updateKey')
  changeTrackingPointId(newKey: number, oldKey: number) {
    return {
      oldKey,
      newKey,
      trackingPointForm: this.trackPointFormData,
    }
  }
}
</script>
