<template>
  <SvgBase iconName="IconBarcodeIsParentChildren" :height="24" :width="24">
    <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="2.25" stroke="black" stroke-width="1.5" />
    <rect
      x="5.75"
      y="5.75"
      width="12.5"
      height="12.5"
      rx="1.25"
      stroke="black"
      stroke-width="1.5"
      stroke-linejoin="round"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconBarcodeIsParentChildren extends Vue {}
</script>
