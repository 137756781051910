
<template>
  <div class="icon-picker-container">
    <el-dialog
      v-model="isDialogShow"
      custom-class="el-dialog--no-heading el-dialog--custom"
      width="540px"
      top="0"
      :destroy-on-close="false"
      @close="$emit('close')"
    >
      <div class="grid grid-cols-6 gap-4">
        <div
          v-for="(icon, key) in barcodeIconComponents"
          :key="key"
          class="icon-picker__icon-area flex items-center justify-center text-center bg-white rounded-lg cursor-pointer"
          :class="{
            'icon-picker__icon-area--selected': selectedIcon === key,
          }"
          @click="selectedIcon = key"
        >
          <component :is="icon" />
        </div>
      </div>
      <template #footer>
        <div class="flex">
          <div class="flex-1">
            <el-button type="default" class="btn-default-custom" @click="$emit('close')">
              {{ $t('cancel') }}
            </el-button>
          </div>
          <div class="flex-1">
            <el-button type="primary" @click="applyIcon()">
              {{ $t('ok') }}
            </el-button>
          </div>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeTypesMixin from './mixins/BarcodeTypesMixin.vue'

@Options({
  components: {
    name: 'IconPicker',
  },
  emits: ['close'],
})
export default class IconPicker extends mixins(BarcodeTypesMixin) {
  @Prop({ type: Object }) readonly barcodeType!: IBarcodeDefinitionType
  @Prop({ type: Boolean }) readonly isShow = false

  isDialogShow = false
  selectedIcon = ''

  updated() {
    this.isDialogShow = this.isShow
    this.selectedIcon = this.barcodeType.icon
  }

  applyIcon() {
    this.barcodeType.icon = this.selectedIcon
    this.$emit('close')
  }
}
</script>
<style lang="scss" scoped>
@import '~@/assets/css/theme.scss';
.icon-picker {
  &__icon-area {
    width: 60px;
    height: 60px;
    border: 2px solid $mid-grey;

    &--selected {
      border: 2px solid $primary;
    }
  }
}
</style>
