
import { Options, mixins } from 'vue-class-component'

import TextboxMixin from '@/components/mixins/TextboxMixin.vue'
import { Prop } from 'vue-property-decorator'

@Options({
  name: 'TextboxUpdateDirectly',
})
export default class TextboxUpdateDirectly extends mixins(TextboxMixin) {
  @Prop({ type: Function }) readonly validateInput?: Function
}
