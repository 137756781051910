<template>
  <div class="py-8 border-b border-gray-400 border-solid">
    <div class="grid grid-cols-4 border-b gap-4 mt-8">
      <div class="col-span-1 p-1">
        <div class="font-bold whitespace-nowrap">{{ $t('barcode_type.link_tracking_point') }}</div>
      </div>
      <div :class="`col-span-3 pb-8`">
        <!-- Items -->
        <div class="grid grid-cols-5 items-start gap-4 pb-1" v-for="item in trackingPointDataShown" :key="item.key">
          <div class="text-right">{{ item.value.name }} {{ `(${item.key})` }}</div>
          <div class="col-span-4">
            <el-radio-group v-model="linkTPs[item.key]" @change="onLinkTPsChanged(item.key, $event)">
              <el-radio label="mandatory">
                {{ $t('barcode_type.mandatory') }}
              </el-radio>
              <el-radio label="available">
                {{ $t('barcode_type.available') }}
              </el-radio>
              <el-radio label="unavailable">
                {{ $t('barcode_type.unavailable') }}
              </el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-5 gap-4 py-4">
      <div class="col-span-2 font-bold py-4">
        {{ $t('barcode_type.info_to_be_displayed_by_linked_barcode') }}
      </div>
      <div class="col-span-3 border-b border-t border-gray-400 border-solid py-4">
        <el-radio-group v-model="modelValue_">
          <div class="mb-3">
            <el-radio label="snapshot">
              {{ $t('barcode_type.info_when_linked_barcode_is_made') }}
            </el-radio>
          </div>
          <div>
            <el-radio label="latest">
              {{ $t('barcode_type.latest_info') }}
            </el-radio>
          </div>
        </el-radio-group>
      </div>
    </div>
    <div class="grid grid-cols-5 gap-4 py-4">
      <div class="col-span-2 font-bold">
        {{ $t('barcode_type.allow_barcode_linking') }}
      </div>
      <div class="grid grid-cols-5 items-center gap-4">
        <el-checkbox v-model="_allowToBeLinked">
          {{ $t('Allow') }}
        </el-checkbox>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { fieldRestriction2String, restrictionRule } from '@/utils/helpers'
import { IFieldRestriction, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'

@Options({
  emits: ['update:linking', 'update:allowToBeLinked', 'update:modelValue'],
  name: 'BarcodeLinksAvailableTPBlock',
})
export default class BarcodeLinksAvailTPBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]

  @Prop({ type: Boolean, default: false }) readonly isReadOnly!: boolean
  @Prop({ type: String, default: '' }) readonly bcTypeCode!: string

  @PropSync('linking', { type: Object })
  _linking!: { [index: string]: IFieldRestriction }

  @PropSync('allowToBeLinked', { type: Boolean, default: false })
  _allowToBeLinked!: boolean

  @PropSync('modelValue', { type: String, default: 'latest' })
  modelValue_!: string

  linkTPs: Record<string, string> = {} as Record<string, string>

  @Watch('linking', { deep: true })
  onLinkingChanged() {
    this.linkTPs = Object.fromEntries(
      this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String(this._linking[v.key])])
    )
  }

  onLinkTPsChanged(trackPointKey: string, value: string) {
    this._linking = { ...(this._linking && this._linking), [trackPointKey]: restrictionRule(value) }
  }
}
</script>
