
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IBarcodeDefinitionType } from 'smartbarcode-web-core/src/utils/types/index'
import BarcodeTypesMixin from './mixins/BarcodeTypesMixin.vue'

@Options({
  components: {
    name: 'IconPicker',
  },
  emits: ['close'],
})
export default class IconPicker extends mixins(BarcodeTypesMixin) {
  @Prop({ type: Object }) readonly barcodeType!: IBarcodeDefinitionType
  @Prop({ type: Boolean }) readonly isShow = false

  isDialogShow = false
  selectedIcon = ''

  updated() {
    this.isDialogShow = this.isShow
    this.selectedIcon = this.barcodeType.icon
  }

  applyIcon() {
    this.barcodeType.icon = this.selectedIcon
    this.$emit('close')
  }
}
