
import { ICustomFieldFormData } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { Emit, Prop, PropSync } from 'vue-property-decorator'
import TextboxUpdateDirectly from '@/components/validateFields/TextboxUpdateDirectly.vue'
import IconDraggable from '@/components/svg/IconDraggable.vue'
import { ECustomFieldExtraType, ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'

@Options({
  components: { TextboxUpdateDirectly, IconDraggable },
  emits: ['field:delete', 'field:load', 'update:isCFDraggable'],
  name: 'TrackingPointRelationshipField',
})
export default class TrackingPointRelationshipField extends Vue {
  @PropSync('isCFDraggable', { type: Boolean }) _isCFDraggable!: boolean
  @Prop({ type: Object }) readonly customField!: ICustomFieldFormData
  @Prop({ type: Number }) readonly index!: number

  get displayCustomFieldType(): string {
    if (!this.customField) return ''
    return this.customField.fieldType === ECustomFieldType.TEXT && this.customField.multiLine
      ? ECustomFieldExtraType.TEXT_AREA
      : this.customField.fieldType
  }

  @Emit('field:load')
  loadField() {
    return { ...this.customField, indexCustomField: this.index }
  }

  deleteField() {
    this.$emit('field:delete', this.customField.customFieldKey)
  }
}
