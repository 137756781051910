
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import { ICarrierUserGroup } from 'smartbarcode-web-core/src/utils/types/index'
import UserGroupProjectPermission from '@/components/carrier/UserGroupProjectPermission.vue'
import UserGroupUserList from '@/components/carrier/UserGroupUserList.vue'
import { FETCH_USER_GROUP, FETCH_USER_GROUPS, FETCH_USER_GROUP_ADD, FETCH_USER_GROUP_PROJECTS } from '@/store/actions'
import { openMessage } from '@/utils/utils'
import { addUserGroup, updateUserGroup } from '@/utils/api'
import cloneDeep from 'lodash/cloneDeep'

@Options({
  components: { ItemNotFound, PageHeader, UserGroupProjectPermission, UserGroupUserList },
  directives: { maska },
  name: 'UserGroupForm',
})
export default class UserGroupForm extends mixins(ValidateForm) {
  loading = false
  activeTabName = this.tabs.baseSetting
  prevTabIndex = 0
  userGroupModel = {} as ICarrierUserGroup
  isSaveDisabled = false

  rules = {
    name: [{ required: true, validator: this.requiredValidate, trigger: 'blur' }],
  }

  get id() {
    return this.$route.params?.id || ''
  }

  get tabs() {
    return {
      baseSetting: 'baseSetting',
      projectPermission: 'projectPermission',
      userList: 'userList',
    }
  }

  async created() {
    await this.loadData()
  }

  async loadData() {
    this.loading = true
    const promises = []
    if (this.id) {
      promises.push(this.$store.dispatch(FETCH_USER_GROUP, this.id))
    } else {
      promises.push(await this.$store.dispatch(FETCH_USER_GROUP_ADD))
    }
    promises.push(this.$store.dispatch(FETCH_USER_GROUP_PROJECTS))
    await Promise.all(promises)
    this.loading = false
    this.userGroupModel = cloneDeep(this.$store.state.userGroup.userGroup)
  }

  changeTab(tab: Record<string, unknown>) {
    this.prevTabIndex = Number(tab.index)
  }

  onSave() {
    this.$refs.userGroupForm.validate(async (valid: string) => {
      if (valid) {
        await this.saveUserGroup()
      } else {
        openMessage(this.$t('validate_occur'), 'error')
        return false
      }
    })
  }

  async saveUserGroup() {
    this.isSaveDisabled = true
    this.loading = true
    const userGroup = cloneDeep(this.$store.state.userGroup.userGroup) as ICarrierUserGroup
    try {
      userGroup.name = this.userGroupModel.name
      userGroup.isActive = this.userGroupModel.isActive

      if (this.id) {
        await updateUserGroup(userGroup)
      } else {
        await addUserGroup(userGroup)
      }

      openMessage(this.$t('save_successful'), 'success')

      await this.$store.dispatch(FETCH_USER_GROUPS)
      this.$router.push({
        name: 'userGroup',
      })
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.isSaveDisabled = false
      this.loading = false
    }
  }

  goToPermissionGroupList() {
    this.$router.push({
      name: 'userGroup',
    })
  }
}
