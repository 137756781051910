
import { Options, Vue } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import TrackPointCheckboxesBlock from './TrackPointCheckboxesBlock.vue'
import { maska } from 'maska'

export interface ILimit {
  minValue: number
  maxValue: number
}

@Options({
  directives: { maska },
  components: { TrackPointCheckboxesBlock },
  emits: ['update:bulkUpdatableTrackPoints', 'update:bulkUpdateOperationLimitCount'],
  name: 'BarcodeType',
})
export default class BarcodeType extends Vue {
  @PropSync('bulkUpdatableTrackPoints', { type: Array, default: [] }) _bulkUpdatableTrackPoints!: number[]
  @PropSync('bulkUpdateOperationLimitCount', { type: Object }) _bulkUpdateOperationLimitCount!: ILimit | undefined

  error = false

  min: number | undefined = 0
  max: number | undefined = 0

  isHasBulkUpdateOperationLimitCount = false

  get errorMessage() {
    return this.$t('barcode_type.bulk_update_operation_limit_count_max_err_msg', { max: 100 })
  }

  mounted() {
    this.onModelValueChanged()
  }

  @Watch('min')
  @Watch('max')
  onMinMaxChanged() {
    if (!this._bulkUpdateOperationLimitCount || !this.min || !this.max) return

    this._bulkUpdateOperationLimitCount.minValue = this.min
    this._bulkUpdateOperationLimitCount.maxValue = this.max
  }

  @Watch('_bulkUpdateOperationLimitCount', { deep: true })
  onModelValueChanged() {
    this.isHasBulkUpdateOperationLimitCount = !!this._bulkUpdateOperationLimitCount
    this.min = this._bulkUpdateOperationLimitCount?.minValue
    this.max = this._bulkUpdateOperationLimitCount?.maxValue
  }

  onIsHasBulkUpdateOperationLimitCountChanged(checked: boolean) {
    this._bulkUpdateOperationLimitCount = checked ? { minValue: 1, maxValue: 10 } : undefined
  }
}
