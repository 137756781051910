
import { ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:modelValue'],
  name: 'TrackPointCheckboxesBlock',
})
export default class TrackPointCheckboxesBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('modelValue', { type: Array, default: [] }) modelValue_?: string[]
  @Prop({ type: String }) readonly label?: string
  @Prop({ default: false, type: Boolean }) readonly isShowAll?: boolean
  @Prop({ default: false, type: Boolean }) readonly isLabelRTL?: boolean

  get nodesShown() {
    return this.isShowAll ? this.trackingPointDatasArr : this.trackingPointDataShown
  }
}
