<template>
  <div class="py-8 relative border-b border-gray-400 border-solid">
    <div class="absolute font-bold">
      {{ $t('barcode_type.input_items_at_activation_time') }}
    </div>

    <div class="2xl:w-1/2 lg:w-3/4 pb-5 m-auto">
      <table class="w-full rounded text-center">
        <thead class="word-break">
          <tr>
            <th></th>
            <th
              v-for="(title, idx) in stepStatusWithRecycle"
              :key="idx"
              class="border-solid border border-gray-400 px-5 py-4 text-center align-middle"
            >
              {{ title }}
            </th>
            <th class="border-solid border border-gray-400 px-5 py-4 text-center">
              {{ $t('barcode_type.countries_that_can_be_selected_when_enter_address') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(step, idx) in activationSteps" :key="idx">
            <td class="text-right p-1 border-solid border border-gray-400 px-5 py-4 leading-5">
              {{ step }}
            </td>
            <td
              class="border-solid border border-gray-400 px-5 py-4 text-center align-middle"
              v-for="(title, key) in stepStatus"
              :key="key"
            >
              <el-radio-group
                class="pl-2"
                :name="step"
                :disabled="idx === 'product'"
                v-model="stepSelected[idx].restriction"
                @change="onChangeActivationSteps(idx, $event)"
              >
                <el-radio :label="`${key}`">{{ '' }}</el-radio>
              </el-radio-group>
            </td>
            <td class="border-solid border border-gray-400 px-5 py-4 text-center align-middle">
              <el-checkbox
                :disabled="stepSelected[idx].restriction === 'unavailable'"
                v-model="stepSelected[idx].copyOnRecycle"
                @change="onChangeCopyOnRecycle(idx, $event)"
              />
            </td>
            <td v-if="idx === 'origin'" :rowspan="2" class="border-solid border border-gray-400 px-5 py-4 align-middle">
              <el-select
                :placeholder="_countries.length ? '' : $t('barcode_type.select')"
                multiple
                filterable
                default-first-option
                size="large"
                v-model="_countries"
                class="w-full"
              >
                <el-option-group
                  v-for="(group, groupKey) in countriesList"
                  :key="groupKey"
                  :label="$t(`countryGroup.${groupKey}`)"
                >
                  <el-option
                    v-for="country in group"
                    :key="country"
                    :value="country"
                    :label="$t(`countryList.${country}`)"
                  >
                    {{ $t(`countryList.${country}`) }}
                  </el-option>
                </el-option-group>
              </el-select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="border border-solid border-gray-400 2xl:w-1/2 lg:w-3/4 m-auto rounded-lg">
      <div class="m-5">
        <!-- Custom fields list -->
        <div class="flex items-center gap-4">
          <div class="flex-grow font-bold">
            {{ $t('record_item_setting') }}
          </div>
          <div>
            <el-button type="primary" icon="el-icon-plus" size="medium" @click="onAddCustomField">
              {{ $t('barcode_type.add_field') }}
            </el-button>
          </div>
        </div>
        <Draggable
          v-model="customFieldSettings"
          tag="transition-group"
          :component-data="draggableComponentData"
          v-bind="draggableBindingData"
          @start="drag = true"
          @end="updateOrderCustomFields"
          item-key="customFieldKey"
          :class="isDraggable ? 'grab-cursor' : ''"
        >
          <template #item="customField">
            <div class="my-2 px-4 custom-field-bg-color rounded-lg">
              <div class="flex items-center gap-4 py-5">
                <div class="flex-1 font-medium">
                  {{ $t(`customFields.${displayCustomFieldType(customField.element)}`) }}
                </div>
                <div class="flex-1">
                  <div>
                    <div class="px-4 py-3 border border-solid custom-field-texbox-border-color rounded-lg font-normal">
                      {{ customField.element.label }}
                    </div>
                  </div>
                </div>
                <div>
                  <el-button type="primary" @click="onEditCustomField(customField.index)" size="medium">
                    {{ $t('barcode_type.edit') }}
                  </el-button>
                  <el-button
                    type="default"
                    class="bg-red-min text-white focus:text-white"
                    @click="onDeleteCustomField(customField.element.customFieldKey)"
                    size="medium"
                  >
                    {{ $t('barcode_type.delete') }}
                  </el-button>
                </div>
                <div>
                  <div class="flex items-center">
                    <IconDraggable @mouseenter="isDraggable = true" @mouseleave="isDraggable = false" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Draggable>
      </div>
    </div>
  </div>
  <Dialog
    ref="customFields"
    v-if="isShowCustomFieldDialog"
    :customFieldData="editingCustomFieldData"
    :allowToSave="!isCustomFieldError"
    :hasCopyOnRecycle="true"
    :hasAllowOcrInput="true"
    :imageFileFields="imageFileFields"
    :isShowHiddenField="true"
    :isAllowedGhgEmission="false"
    @dialog:close="isShowCustomFieldDialog = false"
    @code:update="onCustomFieldCodeChange($event)"
    @field:update="onCustomFieldsUpdate"
  />
</template>
<script lang="ts">
import { fieldRestriction2String, restrictionRule, isImageFileField } from '@/utils/helpers'
import { Vue, Options } from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import {
  IActivationCustomField,
  IActivationFields,
  IFieldRestriction,
  IRecycleFields,
  IFieldOption,
} from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import Dialog from '@/components/customFields/Dialog.vue'
import { PROJECT_CUSTOM_FIELDS_DEFAULT, ACTIVATION_CUSTOM_FIELD_EXTRA_DATA, POPULAR_COUNTRIES } from '@/utils/constants'
import Draggable from 'vuedraggable'
import IconDraggable from '@/components/svg/IconDraggable.vue'
import { ECustomFieldExtraType, ECustomFieldType } from 'smartbarcode-web-core/src/utils/enums/index'
import { FETCH_COUNTRIES, SET_PAGE_NOT_FOUND } from '@/store/actions'

@Options({
  components: { Dialog, Draggable, IconDraggable },
  emits: ['update:customFields', 'update:activationFields', 'update:countries'],
  name: 'CustomFieldBlock',
})
export default class CustomFieldBlock extends Vue {
  @PropSync('activationFields', { type: Object }) fields!: IActivationFields
  @PropSync('countries', { type: Array, default: [] }) _countries!: string[]

  editingCustomFieldData = {} as IActivationCustomField
  customFieldAdded = [] as Array<string>
  customFieldSettings = [] as IActivationCustomField[]
  countriesList = {}
  isDraggable = false
  isShowCustomFieldDialog = false
  isCustomFieldError = false
  drag = false

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 100,
      disabled: !this.isDraggable || this.isReadOnlyMode,
      ghostClass: 'ghost',
    }
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  get imageFileFields(): IFieldOption[] {
    return this.customFieldSettings
      .filter((f) => isImageFileField(f) && !!f.customFieldKey)
      .map((field) => ({ value: field.customFieldKey ?? '', label: field.label }))
  }

  displayCustomFieldType(field: IActivationCustomField): string {
    return field.fieldType === ECustomFieldType.TEXT && field.multiLine
      ? ECustomFieldExtraType.TEXT_AREA
      : field.fieldType
  }

  @Watch('fields.customFields', { deep: true })
  formatCustomFieldSettings() {
    const customFieldSettings = Object.entries(this.fields.customFields ?? {}).map(([customFieldKey, v]) => ({
      ...v,
      customFieldKey,
    }))

    customFieldSettings.sort((a, b) => (a.order && b.order && a.order > b.order ? 1 : -1))

    this.$emit('update:customFields', customFieldSettings)
    this.customFieldSettings = customFieldSettings
  }

  get activationSteps() {
    return {
      origin: this.$t('barcode_type.shipment_source'),
      destination: this.$t('barcode_type.shipping_address'),
      trackingNumber: this.$t('tracking_number'),
      externalId: this.$t('barcode_type.external_id'),
      dimension: this.$t('barcode.dimension'),
      product: this.$t('barcode.product'),
    }
  }

  get stepStatus() {
    return {
      mandatory: this.$t('barcode_type.mandatory'),
      available: this.$t('barcode_type.available'),
      unavailable: this.$t('barcode_type.unavailable'),
    }
  }

  get stepStatusWithRecycle() {
    return {
      ...this.stepStatus,
      copyOnRecycle: this.$t('projects.reuse'),
    }
  }

  get stepSelected() {
    const activationFields = this.fields
    return {
      origin: {
        restriction: fieldRestriction2String(activationFields.origin),
        copyOnRecycle: activationFields.origin?.copyOnRecycle ?? false,
      },
      destination: {
        restriction: fieldRestriction2String(activationFields.destination),
        copyOnRecycle: activationFields.destination?.copyOnRecycle ?? false,
      },
      trackingNumber: {
        restriction: fieldRestriction2String(activationFields.trackingNumber),
        copyOnRecycle: activationFields.trackingNumber?.copyOnRecycle ?? false,
      },
      externalId: {
        restriction: fieldRestriction2String(activationFields.externalId),
        copyOnRecycle: activationFields.externalId?.copyOnRecycle ?? false,
      },
      dimension: {
        restriction: fieldRestriction2String(activationFields.dimension),
        copyOnRecycle: activationFields.dimension?.copyOnRecycle ?? false,
      },
      product: {
        restriction: 'none',
        copyOnRecycle: activationFields.product?.copyOnRecycle ?? false,
      },
    }
  }

  onCustomFieldCodeChange(newCode: string) {
    const editingCode = this.editingCustomFieldData.customFieldKey
    // skip
    if (editingCode === newCode) {
      this.isCustomFieldError = false
      return
    }

    // validate new code
    const isDuplicated = !!this.customFieldSettings.find((field) => field.customFieldKey === newCode)
    if (isDuplicated) {
      this.isCustomFieldError = true

      openMessage(this.$t('duplicated_custom_field_code'), 'error')
      return
    }

    this.isCustomFieldError = false
  }

  updateOrderCustomFields() {
    this.drag = false
    this.customFieldSettings.forEach((item, index) => {
      if (this.fields) {
        this.fields.customFields[item?.customFieldKey || ''].order = index + 1
      }
    })
  }

  onAddCustomField() {
    this.isShowCustomFieldDialog = true

    this.editingCustomFieldData = {
      ...PROJECT_CUSTOM_FIELDS_DEFAULT.text,
      ...ACTIVATION_CUSTOM_FIELD_EXTRA_DATA,
      customFieldKey: '',
      order: this.customFieldSettings.length + 1,
      isNew: true,
    }
  }

  onEditCustomField(idx: number) {
    const field = this.customFieldSettings[idx]
    field.isNew = this.customFieldAdded.includes(field.customFieldKey || '')
    this.editingCustomFieldData = { ...field }
    this.isShowCustomFieldDialog = true
  }

  onDeleteCustomField(customFieldKey: string) {
    delete this.fields.customFields[customFieldKey ?? '']
    Object.values(this.fields.customFields).forEach((item, idx) => (item.order = idx + 1))
    this.formatCustomFieldSettings()
  }

  onCustomFieldsUpdate(customFieldData: IActivationCustomField) {
    if (!this.fields.customFields) this.fields.customFields = {}

    delete this.fields.customFields[this.editingCustomFieldData.customFieldKey ?? '']
    const { customFieldKey, ...newFields } = customFieldData
    this.fields.customFields[customFieldKey as string] = newFields
    this.formatCustomFieldSettings()
    this.isShowCustomFieldDialog = false
  }

  onChangeActivationSteps(stepKey: string, value: string) {
    ;(this.fields[stepKey as keyof IActivationFields] as IFieldRestriction & IRecycleFields) = {
      ...this.fields[stepKey as keyof IActivationFields],
      ...restrictionRule(value),
      copyOnRecycle: value === 'unavailable' ? false : !!this.fields[stepKey as keyof IActivationFields]?.copyOnRecycle,
    }
  }

  onChangeCopyOnRecycle(stepKey: string, value: boolean) {
    ;(this.fields[stepKey as keyof IActivationFields] as IFieldRestriction & IRecycleFields) = {
      ...(this.fields[stepKey as keyof IActivationFields] as IFieldRestriction),
      copyOnRecycle: value,
    }
  }

  async created() {
    if (!this.$store.state.profile?.isLoadCountry) {
      try {
        await this.$store.dispatch(FETCH_COUNTRIES)
      } catch (err) {
        this.$store.dispatch(SET_PAGE_NOT_FOUND, { item: 'country' })
      }
    }

    this.countriesList = {
      popularCountries: { ...POPULAR_COUNTRIES },
      allCountries: this.$store.state.profile?.countries,
    } as {
      popularCountries: string[]
      allCountries: string[]
    }
  }
}
</script>
