<template>
  <SvgBase iconName="IconBarcodeIsParentChildren" :height="10" :width="13">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16659 1.25C4.16659 1.13949 4.21048 1.03351 4.28862 0.955372C4.36676 0.877232 4.47275 0.833333 4.58325 0.833333H11.2499C11.3604 0.833333 11.4664 0.877232 11.5445 0.955372C11.6227 1.03351 11.6666 1.13949 11.6666 1.25V8.75C11.6666 8.86051 11.6227 8.96649 11.5445 9.04463C11.4664 9.12277 11.3604 9.16667 11.2499 9.16667H4.58325C4.47275 9.16667 4.36676 9.12277 4.28862 9.04463C4.21048 8.96649 4.16659 8.86051 4.16659 8.75V7.08333C4.16659 6.97283 4.12269 6.86685 4.04455 6.7887C3.96641 6.71057 3.86043 6.66667 3.74992 6.66667C3.63941 6.66667 3.53343 6.71057 3.45529 6.7887C3.37715 6.86685 3.33325 6.97283 3.33325 7.08333V8.75C3.33325 9.08152 3.46495 9.39946 3.69937 9.63388C3.93379 9.8683 4.25173 10 4.58325 10H11.2499C11.5814 10 11.8994 9.8683 12.1338 9.63388C12.3682 9.39946 12.4999 9.08152 12.4999 8.75V1.25C12.4999 0.918479 12.3682 0.600537 12.1338 0.366117C11.8994 0.131696 11.5814 0 11.2499 0H4.58325C4.25173 0 3.93379 0.131696 3.69937 0.366117C3.46495 0.600537 3.33325 0.918479 3.33325 1.25V2.91667C3.33325 3.02717 3.37715 3.13315 3.45529 3.21129C3.53343 3.28943 3.63941 3.33333 3.74992 3.33333C3.86043 3.33333 3.96641 3.28943 4.04455 3.21129C4.12269 3.13315 4.16659 3.02717 4.16659 2.91667V1.25Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.045 5.29422C9.0838 5.25552 9.11459 5.20954 9.13559 5.15892C9.1566 5.1083 9.16741 5.05403 9.16741 4.99922C9.16741 4.94442 9.1566 4.89015 9.13559 4.83953C9.11459 4.78891 9.0838 4.74293 9.045 4.70422L6.545 2.20422C6.50626 2.16548 6.46027 2.13475 6.40965 2.11379C6.35904 2.09282 6.30479 2.08203 6.25 2.08203C6.19521 2.08203 6.14096 2.09282 6.09035 2.11379C6.03973 2.13475 5.99374 2.16548 5.955 2.20422C5.91626 2.24296 5.88553 2.28896 5.86456 2.33957C5.8436 2.39019 5.83281 2.44444 5.83281 2.49922C5.83281 2.55401 5.8436 2.60826 5.86456 2.65888C5.88553 2.70949 5.91626 2.75548 5.955 2.79422L7.74417 4.58256H0.416667C0.30616 4.58256 0.200179 4.62646 0.122039 4.7046C0.0438987 4.78274 0 4.88872 0 4.99922C0 5.10973 0.0438987 5.21571 0.122039 5.29385C0.200179 5.37199 0.30616 5.41589 0.416667 5.41589H7.74417L5.955 7.20422C5.91626 7.24296 5.88553 7.28896 5.86456 7.33957C5.8436 7.39019 5.83281 7.44444 5.83281 7.49922C5.83281 7.55401 5.8436 7.60826 5.86456 7.65888C5.88553 7.70949 5.91626 7.75548 5.955 7.79422C5.99374 7.83296 6.03973 7.8637 6.09035 7.88466C6.14096 7.90563 6.19521 7.91642 6.25 7.91642C6.30479 7.91642 6.35904 7.90563 6.40965 7.88466C6.46027 7.8637 6.50626 7.83296 6.545 7.79422L9.045 5.29422Z"
      fill="black"
    />
  </SvgBase>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import SvgBase from './SvgBase.vue'

@Options({
  components: {
    SvgBase,
  },
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
})
export default class IconLocationDistination extends Vue {}
</script>
