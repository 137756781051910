<template>
  <div class="h-full w-full" v-loading.fullscreen="loading">
    <div class="flex items-center pb-8 border-solid border-b-1 border-gray-300 header-container">
      <div class="flex-grow items-center flex header-container__left">
        <PageHeader class="pr-6" :title="$t('Staff')" @load:refreshList="fetchList" />
      </div>
      <div class="header-container__right">
        <el-button icon="el-icon-plus" type="primary" @click="onCreateUserGroup">{{
          $t('permissions.create')
        }}</el-button>
        <el-button type="primary" @click="goToStaffList">{{ $t('carrier.list_staff') }}</el-button>
        <el-input
          class="w-60 ml-8"
          prefix-icon="el-icon-search"
          v-model="dataSearch.text"
          @keyup="refreshItemList"
          @blur="updateTextSearch"
        />
      </div>
    </div>
    <div>
      <div v-if="displayItems.length > 0" class="flex-grow flex flex-col">
        <div class="mt-8 flex justify-between items-center table-actions">
          <div class="flex justify-start">
            <label v-if="isMergeMode">{{ $t('carrier.select_multiple_permission_groups_you_want_to_merge') }}</label>
            <span v-else class="font-bold text-xl">{{ $t('carrier.permission_group_list') }}</span>
          </div>
          <div class="flex justify-end items-center user-group-buttons">
            <div class="flex mr-8" v-if="isMergeMode">
              <el-button class="py-3 px-10 bg-gray-400 text-white mr-4" @click="cancelMerge">{{
                $t('cancel')
              }}</el-button>
              <el-button
                type="default"
                class="bg-red-min text-white focus:text-white"
                @click="mergeUserGroup"
                :disabled="selectedUserGroupIds.length < 2"
              >
                {{ $t('carrier.confirm_merge') }}
              </el-button>
            </div>
            <div class="flex" v-else>
              <el-button type="primary" @click="goToMerge" class="mr-8">{{ $t('carrier.merge_setting') }}</el-button>
            </div>
            <el-checkbox v-model="isShowOnlyActive">{{ $t('carrier.show_only_active') }}</el-checkbox>
          </div>
        </div>
        <div class="table-container">
          <div class="mt-6 mb-16 flex-grow table-selection" v-if="isMergeMode">
            <el-table
              ref="userGroupTable"
              :data="displayItems"
              class="rounded-xl w-full"
              header-cell-class-name="custom-table__header-cell"
              cell-class-name="custom-table__cell cursor-pointer"
              border
              :empty-text="$t('empty')"
              highlight-current-row
              @cell-click="selecteRow"
              @select="handleSelectionChange"
            >
              <el-table-column type="selection" width="80" :min-width="8" />
              <el-table-column prop="name" :label="$t('carrier.group_name')" :min-width="32" />
              <el-table-column
                prop="projectPermissions"
                :label="$t('carrier.number_project_permissions')"
                :min-width="20"
              >
                <template v-slot="scope">
                  {{ scope.row.projectPermissions ? Object.keys(scope.row.projectPermissions).length : 0 }}
                </template>
              </el-table-column>
              <el-table-column prop="userIds" :label="$t('carrier.number_users')" :min-width="20">
                <template v-slot="scope">
                  {{ scope.row.userIds?.length }}
                </template>
              </el-table-column>
              <el-table-column prop="isActive" :label="$t('status')" :min-width="20">
                <template v-slot="scope">
                  {{ scope.row.isActive ? $t('active') : $t('inactive') }}
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="mt-6 mb-16 flex-grow" v-else>
            <el-table
              :data="displayItems"
              class="rounded-xl w-full"
              header-cell-class-name="custom-table__header-cell"
              cell-class-name="custom-table__cell cursor-pointer"
              border
              :empty-text="$t('empty')"
              highlight-current-row
              @cell-click="onEditUserGroup"
            >
              <el-table-column prop="name" :label="$t('carrier.group_name')" :min-width="40" />
              <el-table-column
                prop="projectPermissions"
                :label="$t('carrier.number_project_permissions')"
                :min-width="20"
              >
                <template v-slot="scope">
                  {{ scope.row.projectPermissions ? Object.keys(scope.row.projectPermissions).length : 0 }}
                </template>
              </el-table-column>
              <el-table-column prop="userIds" :label="$t('carrier.number_users')" :min-width="20">
                <template v-slot="scope">
                  {{ scope.row.userIds?.length }}
                </template>
              </el-table-column>
              <el-table-column prop="isActive" :label="$t('status')" :min-width="20">
                <template v-slot="scope">
                  {{ scope.row.isActive ? $t('active') : $t('inactive') }}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="justify-center flex flex-row mb-16 paging-container">
          <el-pagination
            layout="prev, pager, next"
            :total="totalPaging"
            :page-size="itemsPerPage"
            :current-page="currentPageNum"
            @current-change="currentPageChange($event)"
            background
            class="custom-pagination"
          />
        </div>
      </div>
      <template v-else>
        <ItemNotFound class="mt-56" :content="$t('empty')" />
      </template>
    </div>
  </div>
  <el-dialog
    v-model="isShowMergeDialog"
    custom-class="el-dialog--custom"
    top="0"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :show-close="true"
    :title="$t('carrier.merge_user_group')"
    @close="closeMergeDialog"
  >
    <div class="flex items-center">
      <div class="text-left w-3/12">{{ $t('permissions.group_name') }}</div>
      <div class="w-9/12">
        <BaseInput
          v-bind="mergeData.name"
          :error="mergeData.errorMessage"
          v-model="mergeData.name"
          @input="clearError()"
          @change="clearError()"
          @blur="validate()"
        />
      </div>
    </div>
    <div class="my-8 font-noto-sans leading-5">
      <p>
        {{ $t('carrier.description_merge_user_group_1') }} <span class="text-base">{{ mergeUserGroupNames }}</span>
      </p>
      <p class="mt-4 whitespace-pre-line">{{ $t('carrier.description_merge_user_group_2') }}</p>
      <p class="mt-4">{{ $t('carrier.description_merge_user_group_3') }}</p>
    </div>
    <template #footer>
      <div class="flex">
        <div class="flex-1">
          <el-button type="default" class="btn-default-cancel" @click="closeMergeDialog()">
            {{ $t('cancel') }}
          </el-button>
        </div>
        <div class="flex-1">
          <el-button type="primary" @click="onMergeUserGroup()">{{ $t('timetable.process') }}</el-button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script lang="ts">
import BaseInput from '@/components/common/BaseInput.vue'
import ItemNotFound from '@/components/common/ItemNotFound.vue'
import PageHeader from '@/components/common/PageHeader.vue'
import DataTableMixin from '@/components/mixins/DataTableMixin.vue'
import { FETCH_USER_GROUPS, IS_ACTIVE_USER_GROUP } from '@/store/actions'
import { mergeUserGroups } from '@/utils/api'
import { FILTER_DEBOUNCE_TIME } from '@/utils/constants'
import { ICarrierUserGroup, ICommonSearch, IUserGroupMergeData } from 'smartbarcode-web-core/src/utils/types/index'
import { openMessage } from '@/utils/utils'
import type { ElTable } from 'element-plus'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import { maska } from 'maska'
import { Options } from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'

@Options({
  components: { ItemNotFound, PageHeader, BaseInput },
  directives: { maska },
  name: 'UserGroup',
})
export default class UserGroup extends Mixins(DataTableMixin) {
  dataSearch = {} as ICommonSearch
  isShowOnlyActive = true
  loading = false
  isMergeMode = false
  isShowMergeDialog = false
  selectedUserGroupIds = [] as string[]
  selectedUserGroupIdsInPage = [] as string[]
  refUserGroupTable = {} as typeof ElTable
  mergeData = {
    name: '',
    errorMessage: '',
  } as {
    name: string
    errorMessage: string
  }

  get getItems() {
    const userGroupsStore: ICarrierUserGroup[] = this.$store.state.userGroup.userGroups
    return userGroupsStore || []
  }

  get mergeUserGroupNames() {
    const listItems = this.getItems
    const userGroupNames = [] as string[]
    this.selectedUserGroupIds.forEach((userGroupId) => {
      const items = listItems.filter((item) => item.id === userGroupId)
      if (items && items?.[0]) {
        userGroupNames.push(items[0].name)
      }
    })

    return userGroupNames.join(', ')
  }

  @Watch('isShowOnlyActive')
  watchIsShownOnlyActive() {
    this.currentPageNum = 1
    this.$store.commit(IS_ACTIVE_USER_GROUP, this.isShowOnlyActive)
    this.filterItems()
  }

  filterItems() {
    const cleanSearchText = this.cleanString(this.dataSearch.text)
    let displayItems = this.getItems.filter((userGroup: ICarrierUserGroup) =>
      this.cleanString(userGroup.name || '').includes(cleanSearchText)
    )

    if (this.isShowOnlyActive) {
      displayItems = displayItems.filter((item: ICarrierUserGroup) => item.isActive === true)
    }

    this.displayItems = cloneDeep(displayItems)

    this.sortDisplayItems()
  }

  refreshItemList() {
    this.debounceFilter()
  }

  async created() {
    this.routeName = 'userGroup'
    this.getQueryData()
    this.isShowOnlyActive = this.$store.state.userGroup.isShowOnlyActive
    if (!this.$store.state.userGroup.isLoaded) {
      await this.fetchList()
    } else {
      this.filterItems()
    }

    this.debounceFilter = debounce(() => {
      this.filterItems()
    }, FILTER_DEBOUNCE_TIME)
  }

  async fetchList() {
    this.loading = true
    await this.$store.dispatch(FETCH_USER_GROUPS).finally(() => {
      this.loading = false
    })
    this.filterItems()
  }

  goToMerge() {
    this.isMergeMode = true
  }

  cancelMerge() {
    this.selectedUserGroupIds = [] as string[]
    this.isMergeMode = false
  }

  onEditUserGroup(item: ICarrierUserGroup) {
    if (!this.isMergeMode) {
      this.$router.push({
        name: 'userGroupEdit',
        params: {
          id: item.id,
        },
      })
    }
  }

  onCreateUserGroup() {
    this.$router.push({
      name: 'userGroupCreate',
    })
  }

  goToStaffList() {
    this.$router.push({
      name: 'carriers',
    })
  }

  mergeUserGroup() {
    this.isShowMergeDialog = true
  }

  closeMergeDialog() {
    this.isShowMergeDialog = false
    this.mergeData.name = ''
    this.mergeData.errorMessage = ''
  }

  clearError() {
    this.mergeData.errorMessage = ''
  }

  validate() {
    if (this.mergeData.name === '') {
      this.mergeData.errorMessage = this.$t('mandatory_field')
      return false
    }
    return true
  }

  async onMergeUserGroup() {
    if (!this.validate()) return
    this.loading = true
    try {
      await mergeUserGroups({
        userGroupIds: this.selectedUserGroupIds,
        name: this.mergeData.name,
      } as IUserGroupMergeData)

      openMessage(this.$t('save_successful'), 'success')
      await this.fetchList()
      this.selectedUserGroupIds = [] as string[]
      this.closeMergeDialog()
    } catch (e) {
      openMessage(e as string, 'error')
    } finally {
      this.loading = false
    }
  }

  handleSelectionChange = (val: ICarrierUserGroup[]) => {
    const selectedInPage = [] as string[]
    val.forEach((item) => {
      if (item.id) {
        if (!this.selectedUserGroupIds.includes(item.id)) {
          this.selectedUserGroupIds.push(item.id)
        }
        if (!this.selectedUserGroupIdsInPage.includes(item.id)) {
          this.selectedUserGroupIdsInPage.push(item.id)
        }
        if (!selectedInPage.includes(item.id)) {
          selectedInPage.push(item.id)
        }
      }
    })

    this.selectedUserGroupIdsInPage.forEach((item, index) => {
      if (!selectedInPage.includes(item)) {
        const indexRemove = this.selectedUserGroupIds.indexOf(item)
        if (indexRemove > -1) {
          this.selectedUserGroupIds.splice(indexRemove, 1)
        }

        this.selectedUserGroupIdsInPage.splice(index, 1)
      }
    })
  }

  selecteRow = (val: ICarrierUserGroup) => {
    if (val.id) {
      const index = this.selectedUserGroupIds.indexOf(val.id)
      if (index > -1) {
        this.selectedUserGroupIds.splice(index, 1)
      } else {
        this.selectedUserGroupIds.push(val.id)
      }

      const indexCurrentPage = this.selectedUserGroupIdsInPage.indexOf(val.id)
      if (index > -1) {
        this.selectedUserGroupIdsInPage.splice(indexCurrentPage, 1)
      } else {
        this.selectedUserGroupIdsInPage.push(val.id)
      }
    }

    this.refUserGroupTable.toggleRowSelection(val, undefined)
  }

  updated() {
    this.refUserGroupTable = this.$refs.userGroupTable
  }

  selectOldTableData() {
    this.selectedUserGroupIdsInPage = []
    if (this.$refs.userGroupTable) {
      this.selectedUserGroupIds.forEach((id) => {
        const existItems = this.displayItems.filter((item: ICarrierUserGroup) => item.id === id)
        if (existItems && existItems?.[0]) {
          this.$refs.userGroupTable.toggleRowSelection(existItems[0], undefined)
          this.selectedUserGroupIdsInPage.push(id)
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped></style>
